import { Height } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";

const drawerWidth = 260;

export default makeStyles(theme => ({
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    backgroundImage: "linear-gradient(to right, #dae8f5 , #ffffff)",
    
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 40,
    [theme.breakpoints.down("sm")]: {
      width: drawerWidth,
    },
  },
  paperClass:
  {
    backgroundColor:"#fff0",    
    backgroundImage:"url('static/images/sidebar.png')",
    '&::-webkit-scrollbar': {
      width: '5px'
    },
  },
  toolbar: {
   // marginTop:"110px"
    // ...theme.mixins.toolbar,
    // [theme.breakpoints.down("sm")]: {
    //   display: "none",
    // },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  /* sidebarList: {
    marginTop: theme.spacing(6),
  }, */
  mobileBackButton: {
    marginTop: theme.spacing(0.5),
    marginLeft: 18,
    [theme.breakpoints.only("sm")]: {
      marginTop: theme.spacing(0.625),
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  userProfileSection:
  {
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    textAlign:"end",
    
    },
  userProfile:{
    textTransform:"uppercase",
    fontSize:16,
    color:'#315078',
    fontWeight:600,
    marginBottom: 10,
    cursor:"pointer"
   
  },
  supRTM:
  {
    fontSize: 15,
    lineHeight: 1,
    letterSpacing: -0.25,
    verticalAlign: 'baseline',
    fontWeight:'bold'
  }
}));
