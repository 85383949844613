import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { Grid, Button, Box } from "@material-ui/core";
import { PageDataContext } from "../../context/PageDataContext";
import { PrintDataContext } from "../../context/PrintDataContext";
import { useUserState } from "../../context/UserContext";

import QuestionHeading from "./../QuestionHeading";
import PersonAddIcon from '@material-ui/icons/PersonAdd';


import useStyles from "./styles";
import PrintPage from "../Print";

const linkTypeList = { print: "print", link: "link" };

export default function TabBar(props) {
  const classes = useStyles();
  const location = useLocation();
  const { submenuContainer } = useContext(PageDataContext);
  const PrintData = useContext(PrintDataContext);

  const [additionalLinks, setAdditionalLinks] = useState([]);
  const [hasprint, setHasPrint] = useState(false);
  const [linkName, setLinksName] = useState("");

  const { clientData: userData } = useUserState();

  const basePath = props.basePath;
  const selectedPath = location.pathname.split("/")[3];
  const { pages } = props;

  const lineDescription = (submenuContainer?.submenus || []).find((v) => {
    return location.pathname === v.data.targetUri && v.lineDescription;
  })?.lineDescription;

  useEffect(() => {
    if (submenuContainer?.submenus) {
      const linkData = submenuContainer.submenus.reduce(
        (additionalLink, currentRow) => {
          if (currentRow.additionalLinks) {
            currentRow.additionalLinks.map((v) => {
              const targetUri = v.targetUri;
              const linkType = targetUri
                ? linkTypeList.link
                : linkTypeList.print;
              
              const linkName = currentRow.additionalLinks[0].name;
              const currentUri = currentRow.data.targetUri.split("/");
              const path = currentUri[3];
              const pageName = currentUri[2];
              additionalLink.push({
                linkName,
                path,
                pageName,
                linkType,
                targetUri  
                
              });
            });
          }
          return additionalLink;
        },
        [],
      );
      console.log("linkData", linkData);
      setAdditionalLinks(linkData);
    }
  }, [submenuContainer?.submenus]);

  // useEffect(()=>{
  //   setHasPrint(false);
  //   const findcurrentPage = (additionalLinks || []).find(
  //     ({ path }) => path === selectedPath,
  //   );
  //  if(findcurrentPage)
  //   {
  //      setTimeout(()=>{
  //       setLinksName(findcurrentPage.linkName)
  //       setHasPrint(true);

  //   },500)

  //   }

  // },[location,additionalLinks])

  if (!props.showNav) {
    return <></>;
  }

  return (
    <>
      <Box className={classes.tabBar}>
        <Grid container>
          <Grid item xs={9}>
            <div className={classes.buttonList}>
              {submenuContainer?.submenus ? (
                <>
                  {submenuContainer.submenus.map((v) => {
                    return (
                      <Button
                        className={
                          location.pathname == v.data.targetUri
                            ? classes.activeLink
                            : ""
                        }
                        component={Link}
                        to={v.data.targetUri}
                      >
                        {v.data.name}{" "}
                      </Button>
                    );
                  })}
                </>
              ) : pages ? (
                <>
                  {pages.map((v) => {
                    return (
                      <Button
                        className={
                          selectedPath == v.path ? classes.activeLink : ""
                        }
                        component={Link}
                        to={`${basePath}/${v.path}`}
                      >
                        {v.name}{" "}
                      </Button>
                    );
                  })}
                </>
              ) : (
                <>
                  {/* <Button
                    className={
                      selectedPath == "question" ? classes.activeLink : ""
                    }
                    component={Link}
                    to={`${basePath}/question`}
                  >
                    Current{" "}
                  </Button>
                  <Button
                    className={
                      selectedPath == "result" ? classes.activeLink : ""
                    }
                    component={Link}
                    to={`${basePath}/result`}
                  >
                    Result
                  </Button>
                  <Button
                    className={
                      selectedPath == "history" ? classes.activeLink : ""
                    }
                    component={Link}
                    to={`${basePath}/result`}
                  >
                    Archives
                  </Button> */}
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={3}>
            <Box
              className="div"
              style={{
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              display="flex"
              alignItems="center"
              justifyContent="center"
              // onClick={handlePrint}
            >
              {" "}
              {(additionalLinks || []).map(({ linkName, path, linkType ,targetUri}) => {
                if (selectedPath === path) {
                  if (linkType === linkTypeList.print) {
                    return (
                      <PrintPage
                        selectedPath={selectedPath}
                        linkName={linkName}
                        userData={userData}
                      />
                    );
                  } else if (linkType === linkTypeList.link) {
                    return (
                      <Button
                        variant="text"
                        startIcon={<PersonAddIcon />}
                        component={Link}
                        to={targetUri}
                      >
                        {linkName}
                      </Button>
                    );
                  }
                }
                return <></>;
              })}
            </Box>
          </Grid>
        </Grid>
      </Box>
      {lineDescription && <QuestionHeading text={lineDescription} />}
    </>
  );
}
