import React, { useContext, useState } from "react";
import { Box, IconButton } from "@material-ui/core";

import { Cancel as CancelIcon } from "@material-ui/icons";

import { useDrag, DragPreviewImage } from "react-dnd";

import { BackdropDispatchContext } from "../../../../context/BackdropContext";
import DeleteModal from "../Modal/deleteModal";

// components
export default function TransitionItem(props) {
  const [open, setOpen] = useState(false);
  const setBackdrop = useContext(BackdropDispatchContext);

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "select",
      item: {
        transitionCode: props.transitionCode,
        type: props.type,
        categoryCode: props.categoryCode,
        imageIcon: props.imageIcon,
        transitionName: props.transitionName,
      },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();

        if (item && dropResult) {
          props.onDrop(item, dropResult);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [],
  );

  return (
    <>
      <div ref={drag}>
        <DragPreviewImage
          connect={preview}
          src={`static/images/life-transition/${props.type}.png`}
          width="80%"
        />
        <Box
          borderRadius="50%"
          className="circle"
          bgcolor="background.paper"
          borderColor="#858585"
          m={0.5}
          border={1}
        >
          <div className="circle-text">
            {props.userAddedTransition && (
              <IconButton size="small" onClick={() => setOpen(true)}>
                <CancelIcon />
              </IconButton>
            )}

            {props.transitionName.toUpperCase()}
          </div>
        </Box>
      </div>
      <DeleteModal
        open={open}
        setOpen={setOpen}
        setBackdrop={setBackdrop}
        deleteType="transition"
        transitionCode={props.transitionCode}
        transitionName={props.transitionName}
        updateTransition={() => {
          props.getQusList();
        }}
      />
    </>
  );
}
