import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  InputAdornment,
  InputLabel,
  IconButton,
  Link,
} from "@material-ui/core";
import {
  PersonOutline as PersonOutlineIcon,
  LockOutlined as LockOutlinedIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// logo

import Footer from "../../components/Footer";
import PublicHeader from '../../components/PublicHeader'

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

function Login(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  var [loginValue, setLoginValue] = useState("");
  var [passwordValue, setPasswordValue] = useState("");
  var [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    console.log("userDispatch", error);
  }, [error]);

  return (
    <Grid container className={classes.container}>
      <PublicHeader/>
      <Grid item className={classes.mainLayout} xs={12}>
        <Grid container justify="flex-end">
          <Grid item xs={4} className={classes.formContainer}>
            <Typography variant="h2" className={classes.greeting}>
              SIGN IN
            </Typography>
            <Typography variant="h6" className={classes.subGreeting}>
              Login in your account to start the service
            </Typography>
            {error ? (
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your login or password
                </Typography>
              </Fade>
            ) : (
              ""
            )}

            <div>
              <InputLabel
                htmlFor="component-helper"
                className={classes.inputFieldLable}
              >
                Username
              </InputLabel>
              <TextField
                id="email"
                className={classes.inputFieldStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                  style: { borderRadius: "50px" },
                }}
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Id"
                variant="outlined"
                type="email"
                fullWidth
                style={{
                  borderRadius: "50px",
                  marginTop: "4px",
                }}
              />
            </div>

            <div>
              <InputLabel
                htmlFor="component-helper"
                className={classes.inputFieldLable}
              >
                Password
              </InputLabel>

              <TextField
                id="password"
                className={classes.inputFieldStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                  style: { borderRadius: "50px" },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                fullWidth
              />
              <Link
                href="#/forget-password"
                color="primary"
                size="large"
                className={classes.forgetButton}
              >
                Forget Password ?
              </Link>
            </div>

            <div className={classes.formButtonSection}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  className={classes.formButton}
                  onClick={() =>
                    loginUser(
                      userDispatch,
                      loginValue,
                      passwordValue,
                      props.history,
                      setIsLoading,
                      setError,
                    )
                  }
                  variant="contained"
                  size="large"
                  fullWidth
                  color="warning"
                >
                  Login
                </Button>
              )}
            </div>

            <div className={classes.signupSection}>
            <Typography variant="pre" >
               New User ?   <Link href="#/signup"color="primary"
                size="large"
                className={classes.signupButton}
                > SIGN UP </Link>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Footer hideLinks={true} />
    </Grid>
  );
}

export default withRouter(Login);
