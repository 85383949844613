import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, Link } from "react-router-dom";
import { Box, Button, Grid } from "@material-ui/core";

import QuestionList from "../components/QuestionList";
import useStyles from "./../styles";
import ApiUri from "../../../constants/apiUri";
import { getRequest, postRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import { DataNotFoundDispatchContext } from "../../../context/DataNotFoundContext";
import { useSnackbar } from "notistack";

export default function Question(props) {
  const setBackdrop = useContext(BackdropDispatchContext);
  const setDataNotFound = useContext(DataNotFoundDispatchContext);

  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState({});
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const [qustionList, setQustionList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getQusList = async () => {
    setBackdrop(true);
    let reqData = await getRequest(
      `${ApiUri.finView.qustionList}/${props.userData.code}`,
    );
    if (reqData.status == 200) {
      setBackdrop(false);
      setQustionList(reqData.data.data.categories);
      const newItem = { ...selectedItem };
      reqData.data.data.categories.forEach((v) => {
        newItem[v.code] =
          v.questions[0].answers.find((ans) => {
            return ans.result === 1;
          })?.code || '';
      });
      setSelectedItem(newItem)
    } else {
      setBackdrop(false);
      setDataNotFound(true);
    }
  };

  function checkProperties(obj) {
    for (var key in obj) {
        if (obj[key] == null || obj[key] == "")
            return false;
    }
    return true;
}

  const onChange = (e) => {
    const { name, value } = e.target;
    const newItem = { ...selectedItem };
    newItem[name] = value;
    setSelectedItem(newItem);
  };

  const onSubmit = async () => {

    if(checkProperties(selectedItem))
    {
      let data = {
        answerCodes: Object.values(selectedItem),
        userMasterId: props.userData.id,
        userName: props.userData.userName,
        createdBy: props.userData.userName,
        modifiedBy: props.userData.userName,
      };
      setBackdrop(true);
      setDisabledSubmit(true);
      let reqData = await postRequest(ApiUri.finView.submitQustion, data);
  
      if (reqData.status == 200) {
        enqueueSnackbar("Data Save Successfully");
      } else {
        console.log("reqData", reqData);
        enqueueSnackbar("please try again", {
          variant: "error",
          preventDuplicate: true,
        });
      }
      setBackdrop(false);
      setDisabledSubmit(false);
    }
    else
    {
      enqueueSnackbar("please select all value", {
        variant: "error",
        preventDuplicate: true,
      });
    }
  }
     
   

  useEffect(() => {
    getQusList();
  }, []);

  return (
    <>
      <Box style={{ position: "relative" }} p={3}>
        {qustionList.map((v) => {
          let defaultValue = v.questions[0].answers.find(
            (v1) => v1.result == 1,
          );
          // console.log("defaultValue",defaultValue)
          return (
            <QuestionList
              qustionId={v.code}
              defaultValue={defaultValue ? defaultValue.code : ""}
              type={v.displayName}
              icon={v.iconBlob}
              options={v.questions[0].answers}
              subHeading={v.questions[0].question}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              onChange={onChange}
            />
          );
        })}
      </Box>
      <Box margin="auto" textAlign="center">
        <Grid container justify="space-evenly" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              component={Link}
              to={"/app/finview-meter/result"}
              className={classes.resultButton}
            >
              View Result
            </Button>
          </Grid>
          <Grid item>
            {" "}
            <Button
              variant="contained"
              disabled={disabledSubmit}
              onClick={onSubmit}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
