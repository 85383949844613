import React, { useEffect, useState } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";
import useStyles from "./styles";

export default function ResponsiveDialog(props) {
  const classes = useStyles();
  const [infoData, setInfoData] = useState(
    JSON.parse(JSON.stringify(props.infoData)),
  );
  const [userInfoData, setUserInfoData] = useState([]);

  const findEntryIndex = (userEntry, displayName) => {
    return userEntry.findIndex((entry) => {
      return entry.includes(displayName);
    });
  };

  const onChange = (index) => {
    const newInfoData = { ...infoData };
    const newUserInfoData = [...userInfoData];

    newInfoData.suggetionList[index].checked = !newInfoData.suggetionList[index]
      .checked;
    const { checked, displayName } = newInfoData.suggetionList[index];
    if (checked) {
      console.log("checked", newInfoData.suggetionList[index]);
      newUserInfoData.push(displayName);
    } else {
      const findIndex = findEntryIndex(newUserInfoData, displayName);
      if (findIndex !== -1) newUserInfoData.splice(findIndex, 1);
    }
    setUserInfoData(newUserInfoData);
    setInfoData(newInfoData);
  };
  const onSubmit = () => {
    let newData = userInfoData.reduce((acc, displayName) => {
      return (acc += displayName + "\n");
    }, "");
    props.onSubmit(newData);
    props.setSelectedInfo(null);
  };

  useEffect(() => {
    const userEntry = (infoData.userEntry?.entry || "")
      .split("\n")
      .filter((v) => v);

    const suggetionList = infoData.suggetionList.map((v) => {
      const checked =
        findEntryIndex(userEntry, v.displayName) !== -1 ? true : false;
      return { ...v, checked };
    });
    setInfoData({ ...infoData, suggetionList });
    console.log("Eee", { ...infoData, suggetionList });
    setUserInfoData(userEntry);
  }, []);

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={true}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent className={classes.dialogContentSection}>
          <DialogTitle className={classes.dialogTitleSection}>
            {infoData.displayName.toUpperCase()}
          </DialogTitle>

          <DialogContentText>
            <FormGroup>
              {infoData.suggetionList.map((v, key) => {
                return (
                  <FormControlLabel
                    style={{
                      fontSize: "15px",
                      margin: "0px",
                      marginTop: "7px",
                    }}
                    control={
                      <Checkbox
                        checked={v.checked}
                        onChange={() => {
                          onChange(key);
                        }}
                        color="primary"
                      />
                    }
                    label={v.displayName}
                    key={key}
                  />
                );
              })}
            </FormGroup>
          </DialogContentText>
          <DialogActions className={classes.dialogActionSection}>
            <Button onClick={onSubmit} color="primary" autoFocus>
              Submit
            </Button>
            <Button
              onClick={() => {
                props.setSelectedInfo(null);
              }}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>{" "}
      </Dialog>
    </div>
  );
}
