import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import Pagination from "material-ui-flat-pagination";
import {
  GetApp as GetAppIcon,
  Publish as PublishIcon,
  // ArrowBack,
} from "@material-ui/icons";
import useStyles from "./styles";
import Tables from "../Table";
import { getRequest } from "../../../utils/API/";
import ApiUri from "../../../constants/apiUri";
import { useUserState } from "../../../context/UserContext";
import ImportClient from "./importClient";
import { useSnackbar } from "notistack";
import { pickBy, falsey } from "lodash";
import { BackdropDispatchContext } from "../../../context/BackdropContext";

export default function Client(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [clientList, setClientList] = useState([]);
  const defaultSearchData = { name: "", status: "" };
  const [searchData, setSearchData] = useState(defaultSearchData);
  const { userData } = useUserState();
  const { enqueueSnackbar } = useSnackbar();
  const [disableButton, setDisableButton] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(50);
  const setBackdrop = useContext(BackdropDispatchContext);

  const getClientList = async (search = false) => {
    console.log("fgghh", userData);
    let listType = userData.userType === "ADMIN" ? "advisor" : "client";
    try {
      setBackdrop(true);
      let reqUrl = `${ApiUri[listType].list}/${userData.code}?offset=${offset}&limit=${limit}`;
      if (search == true) {
        const searchDataObject = pickBy(searchData, falsey);
        const searchParams = Object.keys(searchDataObject)
          .map((key) => key + "=" + searchDataObject[key])
          .join("&");
        reqUrl = `${ApiUri[listType].search}/${userData.code}?${searchParams}&offset=${offset}&limit=${limit}`;
      }
      let resData = await getRequest(reqUrl);
      setBackdrop(false);

      if (resData.status == 200) {
        const {
          totalNoOfRecords,
          clientByAdvisors,
          advisorByParents,
        } = resData.data.data;
        setTotal(totalNoOfRecords);
        const listData =
          userData.userType === "ADMIN" ? advisorByParents : clientByAdvisors;
        setClientList(listData);
        // console.log("data4556", data);
        //setUserProfile(data);
      } else {
      }
    } catch (error) {
      setBackdrop(false);
      console.log("error", error);
    }
  };

  const onDownload = async () => {
    try {
      const reqUrl = ApiUri.client.download;
      const resData = await getRequest(reqUrl, {
        headers: {
          "user-code": userData.code,
        },
        responseType: "blob",
      });
      setDisableButton(true);
      if (resData.status == 200) {
        const { headers } = resData;
        const url = window.URL.createObjectURL(
          new Blob([resData.data], { type: headers["content-type"] }),
        );
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", `client_list_of_${userData.code}.xlsx`);

        document.body.appendChild(link);
        link.click();

        link.remove();
        enqueueSnackbar("Client List Download Successfully");
        // setClientList(data);
        //setUserProfile(data);
      } else {
        enqueueSnackbar("Please try again", {
          variant: "error",
          preventDuplicate: true,
        });
      }
      setDisableButton(false);
    } catch (error) {
      setDisableButton(false);
      enqueueSnackbar("Please try again", {
        variant: "error",
        preventDuplicate: true,
      });
      console.log("error", error);
    }
  };

  // useEffect(() => {
  //   getClientList();
  // }, []);

  useEffect(() => {
    changeClientList();
  }, [offset]);

  const changeClientList = () => {
    const search = searchData.name || searchData.status ? true : false;
    getClientList(search);
  };

  const handleClickOpen = () => {
    // setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const chnageSearchData = (e) => {
    const { name, value } = e.target;
    const newSearchData = { ...searchData };
    newSearchData[name] = value;
    setSearchData(newSearchData);
  };

  return (
    <>
      <form className={classes.signUpForm}>
        <div className={classes.formSectionDoubleFields}>
          <TextField
            name="name"
            className={classes.formField}
            label="Name"
            variant="standard"
            value={searchData.name}
            onChange={chnageSearchData}

            // required
          />
          <FormControl className={classes.formField} variant="standard">
            <InputLabel id="status-select-label">Status</InputLabel>
            <Select
              name="status"
              labelId="status-select-label"
              value={searchData.status}
              onChange={chnageSearchData}
            >
              <MenuItem value="ACTIVE">Active</MenuItem>
              <MenuItem value="INACTIVE">Inactive</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className={classes.formButtonSection}>
          <Button
            className={classes.formButton}
            onClick={() => {
              setSearchData(defaultSearchData);
              setOffset(0);
              getClientList(false);
            }}
            variant="contained"
            size="large"
            fullWidth
          >
            RESET
          </Button>
          <Button
            className={classes.formButton}
            onClick={() => {
              setOffset(0);
              getClientList(true);
            }}
            variant="contained"
            size="large"
            fullWidth
          >
            SEARCH
          </Button>
        </div>
      </form>
      {open && (
        <ImportClient
          handleClickOpen={handleClickOpen}
          getClientList={getClientList}
          open={open}
        />
      )}

      <div style={{ float: "right" }}>
        <Button onClick={handleClickOpen} disabled={disableButton}>
          <PublishIcon /> import
        </Button>
        /
        <Button onClick={onDownload} disabled={disableButton}>
          <GetAppIcon /> export
        </Button>
      </div>

      <Tables clientList={clientList} changeClientList={changeClientList} />
      <div>
        <Pagination
          limit={limit}
          offset={offset}
          total={total}
          currentPageColor="primary"
          size="large"
          onClick={(e, offset) => {
            setOffset(offset);
          }}
          otherPageColor="red"
          classes={classes.pagination}
          style={{ float: "right", margin: 10 }}
        />
      </div>
    </>
  );
}
