import React from "react";
import constant from "./const";
import section from "./section";
const SvgICon = (props) => (
  <svg viewBox="0 0 1070 979" xmlns="http://www.w3.org/2000/svg" width={800} className="print_svg">
    <g
      id="chart"
      transform="matrix(1, 0, 0, 1, 27.782002, 68.000001)"
      origin="0.49962 0.5"
    >
      <image
        width="431.7"
        height={517}
        x="-19.102"
        y="162.775"
        href={`data:image/png;base64,${constant.myIdealogImage}`}
      />
      <text
        id="My Idealogy"
        style={{
          transform: "matrix(0.92, 0, 0, 0.92, 20.978, 434.912)",
          whiteSpace: "pre",
        }}
      >
        <tspan
          x={74}
          y={-30}
          dx="0 0 0 0 0 0 0 0 0 0 0"
          style={{
            fill: "rgb(254, 254, 254)",
            fontFamily: '"Myriad Pro"',
            fontSize: 30,
            fontWeight: 600,
            lineHeight: 48,
          }}
        >
          MY
        </tspan>
        <tspan
          x={16}
          y={10}
          style={{
            fill: "rgb(254, 254, 254)",
            fontFamily: '"Myriad Pro"',
            fontSize: 30,
            fontWeight: 600,
            lineHeight: 48,
          }}
        >
          IDEALOGY
        </tspan>
      </text>
      {props.ansList.map((v, key) => {
        return section[v.code.toLowerCase()]({
          image: v.image,
          entry: v.userEntry
            ? v.userEntry?.entry: "",
        });
      })}
    </g>
  </svg>
);

export default SvgICon;
