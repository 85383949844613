import React, { useEffect, useState, useContext } from "react";
import FirScore from "./icon/firScore";
import { Grid, Button } from "@material-ui/core";
import ApiUri from "./../../../constants/apiUri";
import useStyles from "./../styles";
import { getRequest, postRequest } from "./../../../utils/API";
import { BackdropDispatchContext } from "./../../../context/BackdropContext";
import { Link } from "react-router-dom";
import {DataNotFoundDispatchContext}  from '../../../context/DataNotFoundContext'
// import PrintDataComponent from '../../../PrintData/weeklyScheduler'
// import { PrintDataDispatchContext } from "../../../context/PrintDataContext";
import { useSnackbar } from 'notistack';

export default function Fir(props) {
  const classes = useStyles();
  const setBackdrop = useContext(BackdropDispatchContext);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [dayMaster, setDayMaster] = useState([]);
  const [timeMaster, setTimeMaster] = useState([]);
  const [weeklyRetirement, setWeeklyRetirement] = useState([]);
  const setDataNotFound = useContext(DataNotFoundDispatchContext);
  // const setPrintData = useContext(PrintDataDispatchContext);
  const { enqueueSnackbar } = useSnackbar();




  const getQusList = async () => {
    setBackdrop(true);
    try {
      let resData = await getRequest(ApiUri.rm.weeklyScheduler);
      if (resData.status == 200) {
        const data = resData.data.data;
        setWeeklyRetirement(
          data.weeklyRetirementSchedulerContainer?.weeklyRetirementSchedulers ||
            [],
        );
        // setPrintData({data : data.weeklyRetirementSchedulerContainer?.weeklyRetirementSchedulers ,component: PrintDataComponent , fileName:"weekly-scheduler" ,hideSvgImage:true })

        setDayMaster(data.dayTimeMasterContainer.dayMaster.days);
        setTimeMaster(data.dayTimeMasterContainer.timeMaster.times);

        setBackdrop(false);
      } else {
        setBackdrop(false);
        setDataNotFound(true);
      }
    } catch (error) {
      setBackdrop(false);
      setDataNotFound(true);
      console.log("error", error);
    }
  };

  const onSubmit = async () => {
    setBackdrop(true);
    setDisabledSubmit(true);
    let reqData = await postRequest(
      ApiUri.rm.submitweeklyScheduler,
      weeklyRetirement,
    );
    if (reqData.status == 200) {
        enqueueSnackbar("Data Save Successfully");
    } else {
      enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
    }
    setBackdrop(false);
      setDisabledSubmit(false);
  };
  // console.log(weeklyRetirement)
  useEffect(() => {
    // console.log("DemoData",DemoData)
    getQusList();
  }, []);
  // console.log(timeMaster[0])
  return (
    <>
      {dayMaster.length > 0 && timeMaster.length > 0 ? (
        <>
          <div style={{ textAlign: "center" }}>
            <FirScore
              dayMaster={dayMaster}
              timeMaster={timeMaster}
              weeklyRetirement={weeklyRetirement}
              setWeeklyRetirement={setWeeklyRetirement}
            />
          </div>
          <Grid
            container
            justify="space-evenly"
            alignItems="center"
            style={{ marginTop: "30px" }}
          >
            <Grid item>
              <Link
                to={`${props.path}/vision`}
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" className={classes.submitButton}>
                  Previous
                </Button>
              </Link>{" "}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                diabled={disabledSubmit}
                onClick={onSubmit}
                className={classes.submitButton}
              >
                Submit
              </Button>{" "}
            </Grid>
          </Grid>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
