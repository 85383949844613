import React from "react";
import { Box } from "@material-ui/core";

export default function Welcome(props) {
  return (
  	<Box margin="auto" textAlign="center" p={2}>
      <img src="static/images/dashboard/Process_large_menu.png" />
    </Box> 
  )
}
