import React from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import tableCellClasses from "@material-ui/core/TableCell";
import Date from "../Date";
import Step from "../Step";
import RowMenu from "../RowMenu";
import { putRequest } from "../../../utils/API/";
import ApiUri from "../../../constants/apiUri";
import { useUserState } from "../../../context/UserContext";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Tables({ clientList, changeClientList }) {
  const { userData } = useUserState();
  const { userType } = userData;
  console.log("userType", userType);
  const toggleStatus = async (clientCode) => {
    try {
      let resData = await putRequest(
        `${ApiUri.client.toggleStatus}?client-code=${clientCode}`,
      );
      if (resData.status == 200) {
        const data = resData.data.data;
        changeClientList();
        console.log("data4556", data);
        //setUserProfile(data);
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>
              {userType === "ADMIN" ? "Advisors" : "Clients"}
            </StyledTableCell>
            <StyledTableCell align="">Onboarding Date</StyledTableCell>
            <StyledTableCell align="left">
              {userType === "ADMIN" ? "Subscription Expiry" : "LUM Journey"}
            </StyledTableCell>
            <StyledTableCell align="">Last Login Date</StyledTableCell>
            <StyledTableCell align="">
              {userType === "ADMIN" ? "Subscription Amount" : "Status"}
            </StyledTableCell>
            {userType !== "ADMIN" && (
              <StyledTableCell align="">Actions</StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {(clientList || []).map(
            ({
              userMaster,
              onboardingDate,
              status,
              clientLumJourneys,
              lastLogin,
              subscriptionEndDate,
              subscriptionStartDate,
              subscriptionAmount,
            }) => {
              return (
                <StyledTableRow key={userMaster.userCode}>
                  <StyledTableCell scope="row">
                    {userMaster.firstName} {userMaster.lastName}
                    <div style={{ fontSize: 10 }}>({userMaster.userName})</div>
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <Date
                      onboardingDate={
                        userType === "ADMIN"
                          ? subscriptionStartDate
                          : onboardingDate
                      }
                    />
                  </StyledTableCell>
                  <StyledTableCell align="">
                    {userType === "ADMIN" ? (
                      <>
                        {subscriptionEndDate && (
                          <Date onboardingDate={subscriptionEndDate} />
                        )}
                      </>
                    ) : (
                      <Step value={clientLumJourneys || []} sm={12} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="">
                    {lastLogin && <Date onboardingDate={lastLogin} />}
                  </StyledTableCell>
                  <StyledTableCell align="">
                    <span>
                      {userType === "ADMIN"
                        ? `Rs. ${subscriptionAmount}/-`
                        : status}
                    </span>
                  </StyledTableCell>

                  {userType !== "ADMIN" && (
                    <StyledTableCell align="">
                      <RowMenu
                        clientId={userMaster.userCode}
                        toggleStatus={toggleStatus}
                        status={status}
                      />
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              );
            },
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
