import React, { useState, useEffect } from "react";
import classNames from "classnames";




export default function SidebarMenuList(classes,clientData,userData ,userType)
{

 const hasPermission = (clientData )?true:false



 return [
    { id: 0, label: "Dashboard", link: "/app/dashboard", icon: "dashboard",hasPermission:true },
    {
      id: 1,
      label: (
        <span>
          Life Idealogy Profiler
          <sup className={classNames(classes.supRTM)}>
            {String.fromCharCode("8482")}
          </sup>
        </span>
      ),
      link: "/app/life-idealogy-profiler",
      icon: "life_idealogy_profiler",
      hasPermission
    },
    {
      id: 2,
      label: (
        <span>
          FinView Meter
          <sup className={classNames(classes.supRTM)}>
            {String.fromCharCode("174")}
          </sup>
        </span>
      ),
      link: "/app/finview-meter",
      icon: "finviewmeter",
      hasPermission
    },
    {
      id: 3,
      label: (
        <span>
          The Attainment Wheel -
          <br />
          N.A.V
          <sup className={classNames(classes.supRTM)}>
            {String.fromCharCode("8482")}
          </sup>
        </span>
      ),
      link: "/app/attainment-wheel",
      icon: "the_attainment_wheel",
      hasPermission
    },
    {
      id: 4,
      label: (
        <span>
          Life Transition Planner
          <sup className={classNames(classes.supRTM)}>
            {String.fromCharCode("8482")}
          </sup>
        </span>
      ),
      link: "/app/life-transition-planner",
      icon: "life_transition_planner",
      hasPermission
    },
    {
      id: 5,
      label: (
        <span>
          Retire-Meant
          <sup className={classNames(classes.supRTM)}>
            {String.fromCharCode("174")}
          </sup>
        </span>
      ),
      link: "/app/retire-meant",
      icon: "retirement",
      hasPermission
    },
    {
      id: 6,
      label: (
        <span>
          Money Purpose Wheel -
          <br />
          C.A.R.E
          <sup className={classNames(classes.supRTM)}>
            {String.fromCharCode("8482")}
          </sup>
        </span>
      ),
      link: "/app/money-purpose-wheel",
      icon: "money_purpose_wheel",
      hasPermission
    },
  ];
}