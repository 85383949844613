import React from "react";
// import Person from '@mui/icons-material/Person';
import { Button, Menu, MenuItem } from "@material-ui/core";

import {
  Person,
  ArrowDropDown,
  // ArrowBack,
} from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import useStyles from "./styles";
import { getRequest } from "../../../utils/API/";
import ApiUri from "../../../constants/apiUri";
import { useUserState, setClietData } from "../../../context/UserContext";

export default function RowMenu({ clientId, status,toggleStatus }) {
  const classes = useStyles();
  const { userData } = useUserState();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getClientData = async () => {
    try {
      let reqUrl = `${ApiUri.client.get}`;

      let resData = await getRequest(reqUrl, {
        headers: {
          "advisor-code": userData.code,
          "client-code": clientId,
        },
      });
      if (resData.status == 200) {
        const data = resData.data.data;
        setClietData(data, history);
        console.log("data4556", data);
        //setUserProfile(data);
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <Button className={classes.selecButton} onClick={getClientData}>
        Select
      </Button>
      <Button
        id="demo-positioned-button"
        aria-controls="demo-positioned-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={()=>{
          toggleStatus(clientId)
        }}
        style={{color: status == "ACTIVE"?"green":"red"}}
      >
        <Person></Person>
        {/* <ArrowDropDown></ArrowDropDown> */}
      </Button>
      {/* <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem onClick={handleClose}>Download</MenuItem>
        <MenuItem onClick={handleClose}>Stages</MenuItem>
      </Menu> */}
    </div>
  );
}
