import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
	date: {
		display: "flex",
		width: "100px",
		height: "50px",
		alignItems: "center",
		justifyContent: "center",
		fontFamily: "Arial",
		
	},
	dateDay: {
		fontSize: "30px",
	},
	dateMonthTime: {
		fontSize: "10px",
		marginLeft: "2px",
		fontWeight: "bold",
	}
}));