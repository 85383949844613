import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, Link } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  InputAdornment,
} from "@material-ui/core";
import { BackdropDispatchContext } from "../../../../context/BackdropContext";
import ApiUri from "../../../../constants/apiUri";
import { getRequest, postRequest } from "../../../../utils/API";
import { useSnackbar } from 'notistack';


export default function AddNewTransitionDialog(props) {
  const {
    status,
    categoryCode,
    setOpenNewTransitionDilog,
    categoryName,
    getQusList,
  } = props;
  const setBackdrop = useContext(BackdropDispatchContext);
  const { enqueueSnackbar } = useSnackbar();
  const [transitionName, setTransitionName] = useState();

  const onClose = () => {
    setOpenNewTransitionDilog({
      status: false,
      categoryCode: "",
      categoryName: "",
    });
  };
  const onSubmit = async () => {
    setBackdrop(true);
    let reqData = await postRequest(ApiUri.lifeline.createNewTransition, {
      categoryCode,
      transitionName,
    });

    if (reqData.status === 201) {
      enqueueSnackbar("Data Save Successfully");
      
      setTransitionName("");
      getQusList();
      onClose();
    } else {
      enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
    }
    setBackdrop(false);
  };

  return (
    <>
      <Dialog
        open={status}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        maxWidth={"xs"}
      >
        <DialogTitle id="form-dialog-title">Add New</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add new Transition in {categoryName}
          </DialogContentText>
          <div>
            <TextField
              autoFocus
              id="name"
              label="Transition"
              value={transitionName}
              onChange={(e) => {
                setTransitionName(e.target.value);
              }}
              type="text"
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <br />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
