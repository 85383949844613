import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    backgroundColor:"#fff",
  },
  header:{
    backgroundImage: "url('static/images/header.png')",
    backgroundSize:'cover',
    backgroundPosition:"bottom",
    height:"110px",
    lineHeight:"110px",
    backgroundRepeat:"no-repeat",
    padding: "0px 40px", 
    backgroundPositionY: "bottom",
  },
  headerImg:{
    width:"120px",height: "auto",verticalAlign:"middle"
  },
  
}));