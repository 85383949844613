import React from 'react';
import useStyles from "./styles"
import dayjs from 'dayjs';

export default function Date ({onboardingDate})  {
  const classes = useStyles();

   const date =  dayjs(onboardingDate)
   const month = dayjs(onboardingDate).format('MMM')
   const time = dayjs(onboardingDate).format('HH:mm:ss')

  // const month = props.date.toLocaleString('en-US', { month: 'long' });
  // const day = props.date.toLocaleString('en-US', { day: '2-digit' });
  // const year = props.date.getFullYear();

  return (
    <div className={classes.date}>
      <div className={classes.dateDay}>{date.get('date')}</div>
      <div className={classes.dateMonthTime}>
        <div>{month} {date.get('year')}</div>
        <div style={{marginLeft: "3px"}}>{time}</div>
      </div>
    </div>
  );
};
