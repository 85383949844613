import React from "react";
import useStyles from "./../styles";
import "./style.css";
import ClassNames from "classnames";
import { backgroundPath, getScoreClass, circleTransform } from "./config";
import { Link } from "react-scroll";
export default function PieChart({ selectedResult }) {
  const classes = useStyles();

  return (
    <svg
      version="1.2"
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1017 828"
      width={800}
      height={600}
      className="print_svg"
    >
      <defs>
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          x="0px"
          y="0px"
          width={800}
          height={761}
          viewBox="0 0 480 490"
          xmlSpace="preserve"
        >
          {selectedResult.map(({ displayName, questions }, i) => {
            let selectedItemScore = questions[0].answers.find((item) => {
              return item.result == 1;
            });
            return (
              <path
                className={classes[getScoreClass(selectedItemScore.score)]}
                d={backgroundPath[i]}
                name={displayName}
                index={i}
              />
            );
          })}
        </svg>
        <linearGradient
          id="grd1"
          gradientUnits="userSpaceOnUse"
          x1={318}
          y1="410.498"
          x2={685}
          y2="410.498"
        >
          <stop offset={0} stopColor="#000000" />
          <stop offset={1} stopColor="#ffffff" />
        </linearGradient>
        <linearGradient
          id="grd2"
          gradientUnits="userSpaceOnUse"
          x1="429.774"
          y1="531.998"
          x2="571.225"
          y2="286.998"
        >
          <stop offset={0} stopColor="#c4c4c4" />
          <stop offset={1} stopColor="#ffffff" />
        </linearGradient>
        <radialGradient
          id="grd3"
          gradientUnits="userSpaceOnUse"
          cx="500.5"
          cy="409.499"
          r="115.8"
        >
          <stop offset={0} stopColor="#f5f5f5" />
          <stop offset={1} stopColor="#b9b9b9" />
        </radialGradient>
      </defs>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n\t\ttspan {\n\t\t\twhite-space: pre\n\t\t}\n\n\t\t.shp0 {\n\t\t\topacity: 0.2;\n\t\t\tfill: #ffffff;\n\t\t\tstroke: url(#grd1);\n\t\t\tpaint-order: stroke fill markers;\n\t\t\tstroke-width: 6\n\t\t}\n\n\t\t.shp1 {\n\t\t\tfill: url(#grd2);\n\t\t\tstroke: #ffffff;\n\t\t\tpaint-order: stroke fill markers;\n\t\t\tstroke-width: 6\n\t\t}\n\n\t\t.shp2 {\n\t\t\tfill: url(#grd3);\n\t\t\tstroke: #c0bebe;\n\t\t\tpaint-order: stroke fill markers;\n\t\t\tstroke-width: 6\n\t\t}\n\n\t\t.txt3 {\n\t\t\tfont-size: 15px;\n\t\t\tfill: #ffffff;\n\t\t\tfont-weight: bold;\n\t\t\tfont-family: "Helvetica LT Std"\n\t\t}\n\n\t\t.shp4 {\n\t\t\tfill: #f5f5f5\n\t\t}\n\n\t\t.shp5 {\n\t\t\tfill: #ff5351\n\t\t}\n\t',
        }}
      />
      <use id="Ellipse 3" href="#Layer_1" x={90} y={30} />
      <path
        id="Ellipse 4"
        className="shp0"
        d="M501.5 227C602.84 227 685 309.15 685 410.5C685 511.84 602.84 594 501.5 594C400.16 594 318 511.84 318 410.5C318 309.15 400.16 227 501.5 227Z"
      />
      <path
        id="Ellipse 4 copy"
        className="shp1"
        d="M500.5 287C568.15 287 623 341.84 623 409.5C623 477.15 568.15 532 500.5 532C432.84 532 378 477.15 378 409.5C378 341.84 432.84 287 500.5 287Z"
      />
      <path
        id="Ellipse 4 copy 2"
        className="shp2"
        d="M500.5 313C553.8 313 597 356.2 597 409.5C597 462.79 553.8 506 500.5 506C447.2 506 404 462.79 404 409.5C404 356.2 447.2 313 500.5 313Z"
      />
      <image
        width={444}
        height={327}
        href="static/images/finview_icon/graph3.png"
        transform="matrix(0.23,0,0,0.229,449,400)"
      />
      <image
        width={1116}
        height={612}
        id="img13"
        href="static/images/finview_icon/graph2.png"
        transform="matrix(0.099,0,0,0.098,442,342)"
      />
      <image
        width={279}
        height={279}
        id="img14"
        href="static/images/finview_icon/graph1.png"
        transform="matrix(0.168,0,0,0.168,477,409)"
      />
      <image
        width={158}
        height={225}
        id="img15"
        href="static/images/finview_icon/graph4.png"
        transform="matrix(0.127,0,0,0.129,491,419)"
      />
      {selectedResult.map(({ displayName }, i) => {
        const { name, width, height, image } = circleTransform[i];
        return (
          <>
          <Link to={displayName} smooth style={{ cursor: "pointer" }}>
            <foreignObject x={name.x} y={name.y} width="150" height="50">
              <div className={classes.categoriesName}>{displayName}</div>
            </foreignObject>
            <image
              width={width || "250px"}
              height={height || "250px"}
              transform={image}
              href={`static/images/finview_icon/${displayName
                .replace(/ /g, "_")
                .toLowerCase()}_white.png`}
              
            />
            </Link>
          </>
        );
      })}
    </svg>
  );
}
