import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    backgroundColor:"#fff",
    height:'100vh'
  },
  header:{
    backgroundImage: "url('static/images/header.png')",
    backgroundSize:'cover',
    backgroundPosition:"bottom",
    height:"110px",
    lineHeight:"110px",
    backgroundRepeat:"no-repeat",
    padding: "0px 40px", 
    backgroundPositionY: "bottom",
  },
  headerImg:{
    width:"120px",height: "auto",verticalAlign:"middle"
  },
  mainLayout:{
    backgroundImage: "url('static/images/loginBackground.png')",
    backgroundSize:'cover',
    backgroundPosition:"revert",
    backgroundRepeat:"no-repeat",
    padding:"48px 120px",
    // height:"calc(100vh - 220px)",
    
     
  },
  
  formContainer: {
    backgroundColor:"#fff",
    display: "flex",
    flexDirection: "column",
    padding:"30px 40px",
    boxShadow: "0px 0px 18px 18px #00000075",
    borderRadius: "3px"
  },
  inputFieldStyle:{
    marginTop: "4px"
  },
  inputFieldLable:{
   color:"#1b3752",
   marginTop: "5px",
   marginBottom: "5px",
  },
  greeting: {
    fontWeight: 600,
    textAlign: "center",
    color:"#1b3752",
   // marginTop: theme.spacing(3),
  },
  subGreeting: {
    fontWeight: 500,
    color:"#1b3752",
    textAlign: "center",
    marginBottom: theme.spacing(2),
    marginTop:10
  }, 
  creatingButtonContainer: {
    marginTop: theme.spacing(2.5),
    height: 46,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  createAccountButton: {
    height: 46,
    textTransform: "none",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light
  },
  formButtonSection: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft:"25%",
    paddingRight:"25%",
    color:"#fff"
   
  },
  formButton:{
    backgroundColor:theme.palette.warning.main,
    color:"#000" ,
    borderRadius:"50px"
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400,
    float:"right"  ,
    color: theme.palette.warning.dark
  },
  signupButton:{
    textTransform: "none",
    fontWeight: 400,
    color: theme.palette.warning.dark
  },
  signupSection:{
   margin:"auto",
   marginTop: theme.spacing(4)
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },
}));
