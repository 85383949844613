import React, { useState } from "react";
import {
    Grid,
    Box,
    Typography,
    Divider,
    RadioGroup,
    FormControlLabel,
    Radio
} from "@material-ui/core";

import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "../../styles";
import classNames from "classnames";
import CardHeader from '../../../../components/CardHeader'
// components


export default function Dashboard(props) {
    const  classes = useStyles();
 
    


    return (
      
            <Grid container >
                    <CardHeader  img={`data:image/png;base64,${props.icon}`} {...props} />
                 
                 <Grid item xs={12}>
                     <Box pt={3} pb={3}>
                    <RadioGroup aria-label={props.selectedItem[props.qustionId]}
                     name={props.qustionId} 
                     value={props.selectedItem[props.qustionId] || props.defaultValue}
                      onChange={props.onChange}>
                        
                        {
                            props.options.map((v)=>{
                               return  <><FormControlLabel className={classNames(classes.radioCSS,{[classes.checkedRadio]:v.code ==props.selectedItem[props.qustionId] })}  
                                          value={v.code} control={<Radio color="default"     />} 
                                          label={v.answer}
                        />
                        </>
                            })
                        }                      
                    </RadioGroup>
                     </Box>
                 
                 </Grid>


            </Grid>
       

    );
}

