import React from "react";
import {
  Box,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import classNames from "classnames";
import useStyles from "./styles";

var step = [
  { name: "", imgName: "my-idealogy.png" ,journeyName: "LIP-Response"},
  { name: "", imgName: "finviewmeter.png",journeyName: "FVM-Response" },
  { name: "", imgName: "wheel-of-attainment.png",journeyName:"TAW-Response" },
  { name: "", imgName: "lifeline.png" ,journeyName: "LTP-Response"},
  { name: "", imgName: "retirement-planning.png",journeyName: "Retirement-Response" },
  { name: "", imgName: "money-purpose-wheel.png" ,journeyName: "MPV-Response"},
];

export default function Step({value:stepValue,sm=8}) {
  var classes = useStyles();
  // const location = useLocation();
  // const pathType = location.pathname.split("/")[3];
  const getStepProcess = () => {
    return 20 * 0;
  };
  return (
    <>
      <Box sx ={{width: "100%"}}>
        <Grid container justify="left">
          <Grid item sm={sm}>
            <LinearProgress
              variant="determinate"
              value={getStepProcess()}
              className={classes.hexagonHr}
            />

            <Grid container justify="space-between">
              {step.map((v, i) => {
                const selectedJourney = stepValue.find((data)=>data.journeyName == v.journeyName);
                const additionalData = selectedJourney?.additionalData || {}
                const score = additionalData?.score 
                const hasScore = Number.isInteger(score)
                console.log("score234",score)
                // let hexagonActive = stepValue.find((v)=>v.journeyName) && classes.hexagonActive || '';
                return (
                  <Grid item xs className={classes.textAlignCenter}>
                    <div className={classes.hexagonWrapper}>
                      <div
                        className={classNames(classes.hexagon , selectedJourney && classes.hexagonActive  )}
                      >
                        <img
                          className={classNames(classes.hexagonImg,hasScore && classes.opacity)}
                          src={`static/images/tab/${v.imgName}`}
                        />
                        {
                           hasScore && <>
                           <div className={classes.text}>{score}</div>
                           <div className={classes.nav}>NAV</div>
                           </>
                        }
                        
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
