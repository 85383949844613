import React, { useEffect, useState } from "react";
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";
import useStyles from "./../../styles";
import { getRequest } from "./../../../../utils/API";
import ApiUri from "./../../../../constants/apiUri";

export default function Suggestion(props) {
  const classes = useStyles();
  const [disabledButton, setDisabledButton] = useState(false);
  const [suggestionDetail, setSuggestionDetail] = useState("");
  const [open, setOpen] = useState(false);

  const { transitionCode, transitionName, setBackdrop } = props;

  let getSuggestion = async () => {
    setBackdrop(true);
    setDisabledButton(true);
    let transitionCodes = { tc: transitionCode };

    let resData = await getRequest(ApiUri.lifeline.getSuggestion, {
      params: transitionCodes,
    });
    if (resData.status === 200) {
      const data = resData.data?.data?.transitionSuggestions?.suggestion || "";
      setSuggestionDetail(data);
    } else {
    }
    setOpen(true);
    setBackdrop(false);
  };
  useEffect(() => {
    getSuggestion();
  }, []);

  if (!open) {
    return null;
  }
  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={true}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogContent className={classes.suggestiondialogSection}>
        <DialogTitle className={classes.suggestiondialogSectionHeading}>
          <p>Suggestion for Transition {transitionName}</p>
        </DialogTitle>

        <DialogContentText>
          <p>{suggestionDetail}</p>
        </DialogContentText>
        <DialogActions className={classes.dialogActionSection}>
          <Button
            onClick={() => {
              props.setSelectedInfo(null);
            }}
            color="primary"
            disabled={disabledButton}
          >
            Close
          </Button>
        </DialogActions>
      </DialogContent>{" "}
    </Dialog>
  );
}
