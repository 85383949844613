import React from "react";

import useStyles from "./styles";
import { Box } from "@material-ui/core";

export default function QuestionHeading({ text }) {
  const classes = useStyles();

  return <Box className={classes.questionHeading}>{text}</Box>;
}
