import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({

  dialogContentSection: {
    margin: 0,
    padding: 0,
  },
  dialogTitleSection: {
    margin: 0,
    padding: 0,
    textAlign: "center",
  },
  underLine: {
    float: "left",
    width: "calc( 50% - 6.625px )",
    borderBottom: "3px solid #E84750",
    // marginTop: 10,
  },
  dot: {
    float: "left",
    padding: "0 1px",
    color: "#154854",
    marginTop: "-40px",
    fontSize: 39,
  },
  underLineSection: {
    display: "inline-block",
    width: "20%",
    marginTop: "-40px",
  },
  closeButton:{
    position: "absolute",
    top: 0,
    right: 10
  }

}));
