import React, { useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// components
import Client from "./Client";
import Welcome from "./Welcome";
import PageTitle from "../../components/PageTitle";
import TabBar from "../../components/TabBar";
import { useUserState } from "./../../context/UserContext";

export default function Dashboard(props) {
  const componentRef = useRef();
  const userStateData = useUserState();


  return (
    <>
      <Switch>
        <Route
          exact
          path={`${props.path}`}
          render={() => <Redirect to={`${props.path}/welcome`} />}
        />
        <Route
          exact
          path={`${props.path}/clients`}
          render={() => (
            <Client userData={userStateData.userData || {}} path={props.path}/>
          )}
        />
        <Route
          exact
          path={`${props.path}/welcome`}
          render={() => (
            <Welcome userData={userStateData.userData || {}} path={props.path}/>
          )}
        />
      </Switch>
      
    </>
  );
}