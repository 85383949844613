import React, { useContext, useEffect } from "react";
import ApiUri from "../../constants/apiUri";
import { getRequest, postRequest } from "../../utils/API";
import { PageDataDispatchContext } from "../../context/PageDataContext";
import { BackdropDispatchContext } from "../../context/BackdropContext";

function PageData({ pageCode }) {
  const setPageData = useContext(PageDataDispatchContext);
  const setBackdrop = useContext(BackdropDispatchContext);

  const getPageData = async (pageCode) => {
    try {
      let resData = await getRequest(
        `${ApiUri.sds.pageData}?pageCode=${pageCode}`,
      );
      if (resData.status === 200) {
        const { headerData, submenuContainer, footerData} =
          resData.data?.data?.pages?.pages?.[0] || {};
        setPageData({
          headerData,
          submenuContainer,
          footerData
        });
      } else {
        console.log(resData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setBackdrop(false);
    getPageData(pageCode);
  }, [pageCode]);
  useEffect(()=>{
    setPageData({
      headerData:{},
      submenuContainer:{}
    })
  },[])
  return <></>;
}

export default PageData;
