import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({

    dialogContentSection: {
        border:"4px solid black"
    },
    dialogTitleSection:
    {
        padding:"0px 0px 10px",
        "& h2":{            
            textAlign:"center",
            fontWeight:700,
            color:`${theme.palette.primary.main}`
        }
       
    },
    dialogActionSection:{
        justifyContent : "center"
    }



}));
