import React from "react";
import { Grid, Box, Typography, Paper, Avatar } from "@material-ui/core";
// styles
import useStyles from "./styles";

import TransitionsPlans from "./transitionsPlans";
// components

export default function CategoryRow(props) {
  const classes = useStyles();
  const { name, year, transitionsPlans, transitions } = props;
 
  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid
            spacing={2}
            container
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <Avatar className={classes.avatar}>
                <img src={props.icon} alt={props.icon} />
              </Avatar>
            </Grid>
            <Grid item xs>
              <Typography variant="h6" component="h6">
                {name}{" "}
              </Typography>
            </Grid>
            {year && (
              <Grid item xs>
                <Typography
                  variant="h6"
                  component="h6"
                  className={classes.year}
                >
                  {year}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Paper>
        {transitionsPlans && (
          <Box mt={3} mb={5}>
            {transitions.map((transition) => {
              // console.log(transition)
              return <TransitionsPlans {...transition} user = {props.user}/>;
            })}
          </Box>
        )}
      </Grid>
    </>
  );
}
