import React, { createContext, useState } from "react";
import { useHistory } from 'react-router-dom'



const DataNotFoundContext = createContext(false);
const DataNotFoundDispatchContext = createContext(false);





function DataNotFoundProvider({ children,title,path }) {

const history = useHistory() 
  const [dataNotFound, setDataNotFound] = useState(false);

  history.listen((location, action) => {
setDataNotFound(false)
})


  return (
    <DataNotFoundContext.Provider value={dataNotFound}>
      <DataNotFoundDispatchContext.Provider value={setDataNotFound}>

          {
              dataNotFound?  
              <div  style={{width:'50%', textAlign:"center", margin:'auto'}} ><img src="static/images/no-data.svg"/></div>:children
          }

      </DataNotFoundDispatchContext.Provider>
    </DataNotFoundContext.Provider> 
  );
}

export { DataNotFoundProvider, DataNotFoundContext, DataNotFoundDispatchContext };