import React from "react";
import { Typography, CardHeader, Divider, Grid } from "@material-ui/core";

export default function CardHeaderData(props) {
  // var classes = useStyles();
  return (
    <>
      <Grid item xs={12}>
        <CardHeader
          style={{ padding: "0px" }}
          avatar={<img src={props.img} width={40} />}
          title={
            <div style={{ display: "flex" }}>
              <Typography
                variant="h4"
                style={{
                  color: "#124452",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginRight: "20px",
                }}
              >
                {props.type.toUpperCase()}
              </Typography>
              {props.subHeading && (
                <Typography
                  variant="p"
                  style={{
                    color: "#959595",
                    fontWeight: "500",
                    fontSize: "14px",
                    fontStyle: "italic",
                  }}
                >
                  ({props.subHeading})
                </Typography>
              )}
            </div>
          }
        />
        <Divider style={{ background: "#000", height: "2px" }} />
      </Grid>
    </>
  );
}
