// validator regex follows rfc 822, source - https://github.com/wprl/rfc822-validate/blob/master/index.js
export const emailValidator = (val) => {
  if(!val)
  {
     return true;
  }
  var sQtext = "[^\\x0d\\x22\\x5c\\x80-\\xff]";
  var sDtext = "[^\\x0d\\x5b-\\x5d\\x80-\\xff]";
  var sAtom =
    "[^\\x00-\\x20\\x22\\x28\\x29\\x2c\\x2e\\x3a-\\x3c\\x3e\\x40\\x5b-\\x5d\\x7f-\\xff]+";
  var sQuotedPair = "\\x5c[\\x00-\\x7f]";
  var sDomainLiteral = "\\x5b(" + sDtext + "|" + sQuotedPair + ")*\\x5d";
  var sQuotedString = "\\x22(" + sQtext + "|" + sQuotedPair + ")*\\x22";
  var sDomain_ref = sAtom;
  var sSubDomain = "(" + sDomain_ref + "|" + sDomainLiteral + ")";
  var sWord = "(" + sAtom + "|" + sQuotedString + ")";
  var sDomain = sSubDomain + "(\\x2e" + sSubDomain + ")*";
  var sLocalPart = sWord + "(\\x2e" + sWord + ")*";
  var sAddrSpec = sLocalPart + "\\x40" + sDomain; // complete RFC822 email address spec
  var sValidEmail = "^" + sAddrSpec + "$"; // as whole string

  var reValidEmail = new RegExp(sValidEmail);

  return reValidEmail.test(String(val));
};

const passwordValidator = (val) => {
  val = String(val);
  const lowerCaseRegex = new RegExp("[a-z]");
  const upperCaseRegex = new RegExp("[A-Z]");
  const numberRegex = new RegExp("[0-9]");
  const specialCharacterRegex = new RegExp("[!@#$%^&*)(+=._-]");
  const passwordRegex = new RegExp("^[a-zA-Z0-9!@#$%^&*)(+=._-]{8,20}$");
  return (
    lowerCaseRegex.test(val) &&
    upperCaseRegex.test(val) &&
    numberRegex.test(val) &&
    specialCharacterRegex.test(val) &&
    passwordRegex.test(val)
  );
};

const zipValidator = (val) => {
  if(val !== '')
  {
    const zipRegex = new RegExp("^[0-9]{5,10}$")
    return zipRegex.test(val)
  }
  return true
 
}
const mobileValidator = (val)=>{
  if(val !== '')
  {
    const mobileRegex = new RegExp(/(\b91|\b0|\b)([6-9]\d{9})\b/gi)
    return mobileRegex.test(val)
  }
  return true;
}

const isSameValidator = (val1, val2) => {
  return val1 === val2;
};

const isRequiredValidator = (val) => {
  return val !== null && val !== undefined && val !== "";
};

const minMaxLengthValidator = (
  val,
  minLength = 1,
  maxLength = Number.MAX_SAFE_INTEGER - 1,
) => {
  return String(val).length >= minLength && String(val).length <= maxLength;
};

const formValidators = {
  emailId: [
    {
      fn: isRequiredValidator,
      helperText: "Email required",
    },
    {
      fn: emailValidator,
      helperText: "Invalid Email",
    },
  ],
  contactEmailAddress: [
    {
      fn: emailValidator,
      helperText: "Invalid Email",
    },
  ],
  password: [
    {
      fn: isRequiredValidator,
      helperText: "Password required",
      showPassword:true
    },
    {
      fn: passwordValidator,
      helperText: "Invalid password, click on info icon to view password rules",
      showPassword:true
    },
  ],
  confirmPassword: [
    {
      fn: isRequiredValidator,
      helperText: "Password confirmation required",
      showPassword:true
    },
    {
      fn: isSameValidator,
      fnArgs: ["password", "confirmPassword"],
      helperText: "Passwords do not match",
      showPassword:true
    },
  ],
  firstName: [
    {
      fn: isRequiredValidator,
      helperText: "First name required",
    },
    {
      fn: (val) => minMaxLengthValidator(val, 1, 100),
      helperText: "First name should not exceed 100 characters",
    },
  ],
  lastName: [
    {
      fn: isRequiredValidator,
      helperText: "Last name required",
    },
    {
      fn: (val) => minMaxLengthValidator(val, 1, 100),
      helperText: "Last name should not exceed 100 characters",
    },
  ],
  address: [
    {
      fn: isRequiredValidator,
      helperText: "Address required",
      onlyforAdvisor: true
    },
    {
      fn: (val) => minMaxLengthValidator(val, 1, 100),
      helperText: "Address should not exceed 100 characters",
      onlyforAdvisor: true
    },
  ],
  country: [
    {
      fn: isRequiredValidator,
      helperText: "Country required",
      onlyforAdvisor: true
    },
  ],
  state: [
    {
      fn: isRequiredValidator,
      helperText: "State/Province required",
      onlyforAdvisor: true
    },
  ],
  city: [
    {
      fn: isRequiredValidator,
      helperText: "City required",
      onlyforAdvisor: true
    },
  ],
  zip: [
    {
      fn: isRequiredValidator,
      helperText: "Zip code required",
      onlyforAdvisor: true
    },
    {
      fn: zipValidator,
      helperText: "Invalid Zip Code",
      onlyforAdvisor: true
    },
  ],
  mobile: [
    {
      fn: mobileValidator,
      helperText: "Invalid Mobile Number",
    },
  ],
  billingAddress: [
    {
      fn: isRequiredValidator,
      helperText: "Billing Address required",
      onlyforAdvisor: true
    },
  ],
  billingCountry: [
    {
      fn: isRequiredValidator,
      helperText: "Billing Country required",
      onlyforAdvisor: true
    },
  ],
  billingState: [
    {
      fn: isRequiredValidator,
      helperText: "Billing State required",
      onlyforAdvisor: true
    },
  ],
  billingCity: [
    {
      fn: isRequiredValidator,
      helperText: "Billing City required",
      onlyforAdvisor: true
    },
  ],
  billingZip: [
    {
      fn: isRequiredValidator,
      helperText: "Billing Zip code required",
      onlyforAdvisor: true
    },
    {
      fn: zipValidator,
      helperText: "Invalid Zip Code",
      onlyforAdvisor: true
    },
  ],
  dateOfBirth:[
    {
      fn: isRequiredValidator,
      helperText: "Date of Birth is required",
    },
  ],
  companyName:[],
  arnNumber:[],
  gstn:[]
};

export default formValidators;
