import React, { useEffect, useState, useContext } from "react";
import { Route, Switch, Link } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  InputAdornment,
} from "@material-ui/core";
import {
  LibraryAdd as LibraryAddIcon,
  SettingsBackupRestore as SettingsBackupRestoreIcon,
} from "@material-ui/icons";
import useStyles from "./styles";
import { BackdropDispatchContext } from "../../../../context/BackdropContext";
import ApiUri from "../../../../constants/apiUri";
import { getRequest, postRequest } from "../../../../utils/API";
import { cloneDeep } from "lodash";
import { useSnackbar } from 'notistack';


export default function Heading(props) {
  const {
    qustionList,
    copyQustionList,
    setQustionList,
    code,
    getQusList,
  } = props;
  const classes = useStyles();
  const setBackdrop = useContext(BackdropDispatchContext);
  const { heading } = props;
  const [newQus, setNewQus] = useState({
    question: "",
  });
  
  const { enqueueSnackbar } = useSnackbar();
  const [addNewStatus, setAddNewStatus] = useState(false);
  useEffect(() => {
    //getQusList();
  }, []);

  const updateNewQus = (e) => {
    const qus = { ...newQus };

    const { value, name } = e.target;
    qus[name] = value;
    setNewQus(qus);
  };

  const onSubmit = async () => {
    let data = [
      {
        categories: [
          {
            name: "Income",
            code: props.code,
            groups: [
              {
                code: "PER",
                name: "Personal",
                questions: [
                  {
                    question: newQus.question,
                    questionFontHexCode: "#4E4E4E",
                  },
                ],
              },
            ],
          },
        ],
      },
    ];

    setBackdrop(true);
    setAddNewStatus(false);

    let submitQustionList = await props.submitQustionList(false);
    if(submitQustionList)
    {
      let reqData = await postRequest(ApiUri.mpv.createQuestion, data);

      if (reqData.status === 201) {
        // console.log(reqData)
        setBackdrop(false);
        enqueueSnackbar("Data Save Successfully");
        setNewQus({ question: "", monthly: "", yearly: "" });
        getQusList();
      } else {
        setBackdrop(false);
        enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
      }
    }
    
  };
  const findIndex = (array, key, value) => {
    return array.findIndex((index) => index[key] === value);
  };
  const resetBtn = () => {
    const newQustionList = cloneDeep(qustionList);
    const index1 = findIndex(newQustionList, "code", code);
    const index2 = findIndex(copyQustionList, "code", code);
    newQustionList[index1] = cloneDeep(copyQustionList[index2]);
    console.log(
      "copyQustionList",
      newQustionList[index1],
      copyQustionList[index2],
    );

    setQustionList(newQustionList);
  };
  return (
    <>
      <Box p={1} className={classes.questionHeading}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" component="h4">
              {heading.toUpperCase()}{" "}
            </Typography>
            <div className={classes.action}>
              <Button
                size="medium"
                className={classes.actionButton}
                startIcon={<LibraryAddIcon />}
                onClick={() => setAddNewStatus(true)}
              >
                Add New
              </Button>
              {/* <Button
                size="medium"
                className={classes.actionButton}
                startIcon={<SettingsBackupRestoreIcon />}
                onClick={resetBtn}
              >
                Reset
              </Button> */}
            </div>
          </Grid>
        </Grid>
      </Box>

      <Dialog
        open={addNewStatus}
        onClose={() => {
          setAddNewStatus(false);
        }}
        aria-labelledby="form-dialog-title"
        maxWidth={"xs"}
      >
        <DialogTitle id="form-dialog-title">Add New</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add New Question for {heading} section
          </DialogContentText>
          <div>
            <TextField
              autoFocus
              id="name"
              label="Question"
              name="question"
              value={newQus.question}
              onChange={(e) => {
                updateNewQus(e);
              }}
              type="text"
              variant="outlined"
              size="small"
              fullWidth
            />
          </div>
          <br />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setAddNewStatus(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={onSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
