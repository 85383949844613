import React, { useEffect, useRef,useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Question from "./qus";
import Answers from "./ans";
import { useUserState } from "./../../context/UserContext";
// styles
// import useStyles from "./styles";

// import TabBar from "../../components/TabBar";

export default function AttainmentWheel(props) {
  const componentRef = useRef();
  const userStateData = useUserState();

  // const printPageData = [
  //   {
  //     path: "result",
  //     componentRef: componentRef,
  //     pageName: "THE ATTAINMENT WHEEL",
  //   },
  // ];


  return (
    <>
      
      {/* <TabBar basePath={props.path} printPageData={printPageData} /> */}
      <Switch>
        <Route
          exact
          path={`${props.path}`}
          render={() => <Redirect to={`${props.path}/question`} />}
        />
        <Route
          exact
          path={`${props.path}/question`}
          render={() => <Question userData={userStateData.clientData || {}} />}
        />
        <Route
          exact
          path={`${props.path}/result`}
          render={() => (
            <div ref={componentRef}>
              <Answers userData={userStateData.clientData || {}} />
            </div>
          )}
        />
      </Switch>
    </>
  );
}
