const BASE_URL = process.env.REACT_APP_BASE_API_URL;
const API_URIS = {
  auth: {
    login: BASE_URL + "/api/startup/v1/login",
    signUp: BASE_URL + "/api/as/v1/profile/advisor/register",
    forgetPassword: BASE_URL + "/api/as/v1/user/password/forgot",
    passwordReset: BASE_URL + "/api/as/v1/user/password/reset",
  },
  payment: {
    response: BASE_URL + "/api/payment/v1/imjo/response",
    validate: BASE_URL + "/api/payment/v1/profile/username/validate",
  },
  finView: {
    qustionList: BASE_URL + "/api/fvm/v1/ask",
    submitQustion: BASE_URL + "/api/fvm/v1/ques/ans/user/map",
    userResults: BASE_URL + "/api/fvm/v1/ask/user/results",
  },
  attainmentWheel: {
    qustionList: BASE_URL + "/api/taw/v1/ask",
    submitQustion: BASE_URL + "/api/taw/v1/ques/ans/user/map",
    userResults: BASE_URL + "/api/taw/v1/ask/user/results",
  },
  lifeline: {
    qustionList: BASE_URL + "/api/lifeline/v1/ask",
    submitQustion: BASE_URL + "/api/lifeline/v1/ans/user/map",
    userResults: BASE_URL + "/api/lifeline/v1/ask/user/results",
    comment: BASE_URL + "/api/lifeline/v1/transition/comment/fetch",
    commentSubmit: BASE_URL + "/api/lifeline/v1/transition/comment/add",
    commentDelete: BASE_URL + "/api/lifeline/v1/transition/comment/delete",
    createNewTransition: BASE_URL + "/api/lifeline/v1/transition/user/create",
    deleteUserTransition: BASE_URL + "/api/lifeline/v1/transition/user/delete",
    getSuggestion: BASE_URL + "/api/lifeline/v1/transition/suggestion/fetch",
  },
  mpv: {
    qustionList: BASE_URL + "/api/mpv/v1/ask",
    createQuestion: BASE_URL + "/api/mpv/v1/user/question/create",
    submitQustion: BASE_URL + "/api/mpv/v1/ques/user/map",
    deleteQustion: BASE_URL + "/api/mpv/v1/user/question/delete",
  },

  lifeIdealogyProfiler: {
    qustionList: BASE_URL + "/api/lip/v1/fetch",
    submitQustion: BASE_URL + "/api/lip/v1/entry",
  },
  rm: {
    qustionList: BASE_URL + "/api/retirement/v1/impression/fetch",
    submitImpression: BASE_URL + "/api/retirement/v1/impression",
    visioningQus: BASE_URL + "/api/retirement/v1/vision/fetch",
    submitVision: BASE_URL + "/api/retirement/v1/vision",
    getFirData: BASE_URL + "/api/retirement/v1/fir/fetch",
    weeklyScheduler: BASE_URL + "/api/retirement/v1/wrs/fetch",
    submitweeklyScheduler: BASE_URL + "/api/retirement/v1/wrs",
    // createQuestion:BASE_URL+"/api/mpv/v1/user/question/create"
  },
  profile: {
    user: BASE_URL + "/api/as/v1/profile/user",
    client: BASE_URL + "/api/as/v1/profile/client",
    updateProfile: BASE_URL + "/api/as/v1/profile/user/update",
    // visioningQus:BASE_URL+"/api/retirement/v1/vision/fetch"
    // createQuestion:BASE_URL+"/api/mpv/v1/user/question/create"
  },
  moneyPueposeWheel: {
    qustionList: BASE_URL + "/api/mpv/v1/ques/user/result",
    // visioningQus:BASE_URL+"/api/retirement/v1/vision/fetch"
    // createQuestion:BASE_URL+"/api/mpv/v1/user/question/create"
  },
  nav: {
    qustionList: BASE_URL + "/api/mpv/v1/result/nav/fetch",
    // visioningQus:BASE_URL+"/api/retirement/v1/vision/fetch"
    // createQuestion:BASE_URL+"/api/mpv/v1/user/question/create"
  },
  sds: {
    pageData: BASE_URL + "/api/sds/v1/fetch",
    // visioningQus:BASE_URL+"/api/retirement/v1/vision/fetch"
    // createQuestion:BASE_URL+"/api/mpv/v1/user/question/create"
  },
  client: {
    list: BASE_URL + "/api/as/v1/client/list",
    search: BASE_URL + "/api/as/v1/client/search",
    get: BASE_URL + "/api/as/v1/client/impersonate/by/advisor",
    download: BASE_URL + "/api/as/v1/client/download",
    upload: BASE_URL + "/api/as/v1/client/upload",
    register: BASE_URL + "/api/as/v1/profile/client/register",
    toggleStatus: BASE_URL + "/api/as/v1/client/status/toggle",
  },
  advisor: {
    list: BASE_URL + "/api/as/v1/advisor/list",
    search: BASE_URL + "/api/as/v1/advisor/search",
  },
  staticData: {
    footer: BASE_URL + "/api/sds/v1/footer/fetch",
  },
};

export default API_URIS;
