import React, { useEffect, useState, useContext } from "react";
import FirScore from "./icon/firScore";
import ApiUri from "../../../constants/apiUri";
import useStyles from "./../styles";
import { Box, Button, Grid } from "@material-ui/core";
import { getRequest, postRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import { Link } from "react-router-dom";
import {DataNotFoundDispatchContext}  from '../../../context/DataNotFoundContext'
// import PrintDataComponent from '../../../PrintData/Vision'
// import { PrintDataDispatchContext } from "../../../context/PrintDataContext";
import { useSnackbar } from 'notistack';


export default function Fir(props) {
  const setBackdrop = useContext(BackdropDispatchContext);
  const setDataNotFound = useContext(DataNotFoundDispatchContext);
  // const setPrintData = useContext(PrintDataDispatchContext);
  const { enqueueSnackbar } = useSnackbar();

  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const classes = useStyles();
  const [visionList, setVisionList] = useState([]);

  const getQusList = async () => {
    setBackdrop(true);
    try {
      let resData = await getRequest(ApiUri.rm.visioningQus);
      if (resData.status == 200) {
        const visionList = resData.data.data.visionList;
        // setPrintData({data :visionList ,component: PrintDataComponent , fileName:"vision" })

        setVisionList(visionList);
        setBackdrop(false);
      } else {
        setBackdrop(false);
        setDataNotFound(true);
      }
    } catch (error) {
      setBackdrop(false);
      setDataNotFound(true);
      console.log(error);
    }
  };
  const onSubmit = async () => {
    // console.log(visionList)
    let visionData = [];
    visionList.forEach((v) => {
      visionData.push({ code: v.code, selected: v.selected });
    });
    setBackdrop(true);
    setDisabledSubmit(true);
    
    let reqData = await postRequest(ApiUri.rm.submitVision, visionData);
    if (reqData.status == 200) {
     
      enqueueSnackbar("Data Save Successfully");
    } else {
      enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
    }
    setBackdrop(false);
    setDisabledSubmit(false);
  };
  useEffect(() => {
    // console.log("DemoData",DemoData)
    getQusList();
  }, []);

  return (
    <>
      <div style={{ textAlign: "center" }}>
        {visionList.length > 0 && (
          <FirScore visionList={visionList} setVisionList={setVisionList} />
        )}
        <Box margin="auto" textAlign="center">
          <Grid container justify="space-evenly" alignItems="center">
            <Grid item>
              <Link
                to={`${props.path}/impression`}
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" className={classes.submitButton}>
                  Previous
                </Button>
              </Link>{" "}
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                diabled={disabledSubmit}
                onClick={onSubmit}
                className={classes.submitButton}
              >
                Submit
              </Button>
            </Grid>
            <Grid item>
              <Link
                to={`${props.path}/weekly-scheduler`}
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" className={classes.submitButton}>
                  Next
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </div>
    </>
  );
}
