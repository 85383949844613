import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  statusSection: {
    "& div div": {
      fontSize: 12,
      fontWeight: 700,
      color: "#fff",
      width: 150,
      textAlign: "center",
      padding: "5px 0px",
    },
  },
  family: {
    backgroundColor: `${theme.palette.red.main}`,
  },
  health: {
    backgroundColor: `${theme.palette.orange.main}`,
  },
  work: {
    backgroundColor: `${theme.palette.yellow.main}`,
  },
  retirement: {
    backgroundColor: `${theme.palette.green.main}`,
  },
  financial: {
    backgroundColor: `${theme.palette.blue.main}`,
  },
}));
