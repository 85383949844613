import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { Grid, Divider } from "@material-ui/core";
// styles
import useStyles from "./styles";
import { PageDataContext } from "../../context/PageDataContext";
import { isEmpty } from "lodash";
import ApiUri from "../../constants/apiUri";
import { getRequest } from "../../utils/API";

export default function Footer(props) {
  var classes = useStyles();
  const [footerData, setFooterData] = useState({});
  // const { footerData = {} } = useContext(PageDataContext);

  const getFooterData = async () => {
    let reqData = await getRequest(ApiUri.staticData.footer);

    if (reqData.status === 200) {
      setFooterData(reqData.data?.data?.pages?.pages[0]?.footerData || {});
    }
  };

  useEffect(() => {
    console.log("ggggg","aniket")
    getFooterData();
  }, []);

  return (
    <>
      <Grid container>
        <Grid item className={classes.footer} xs={12}>
          <h2 className={classes.footerHeading}>Lives Under Management</h2>
          {!props.hideLinks ? (
            <Grid
              container
              alignItems="center"
              style={{ marginBottom: "10px" }}
            >
              About Us
              <Divider
                orientation="vertical"
                flexItem
                className={classes.footerDivider}
              />
              Terms & Conditions
              <Divider
                orientation="vertical"
                flexItem
                className={classes.footerDivider}
              />
              Privacy Policy
              <Divider
                orientation="vertical"
                flexItem
                className={classes.footerDivider}
              />
            </Grid>
          ) : (
            ""
          )}
          {!isEmpty(footerData) && (
            <Grid container alignItems="center" style={{ marginBottom: "5px" }}>
              <Grid item xs={12}>
                {" "}
                <Divider
                  style={{ backgroundColor: "#fff", marginBottom: "5px" }}
                />
              </Grid>
              {footerData?.copyrightInformation} - {footerData?.companyName}
            </Grid>
          )}
        </Grid>
      </Grid>
    </>
  );
}
