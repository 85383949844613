import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline,IconButton } from "@material-ui/core";
import {
  DoneAllSharp as DoneAllSharpIcon,
  ErrorOutlineSharp as ErrorOutlineSharpIcon,
  WarningSharp as WarningSharpIcon,
  Clear as ClearIcon
} from '@material-ui/icons';

import Themes from "./themes";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { LayoutProvider } from "./context/LayoutContext";
import { UserProvider } from "./context/UserContext";
import { PageDataProvider } from "./context/PageDataContext";
import ErrorBoundary from "./components/Error/ErrorBoundary";
import { SnackbarProvider } from "notistack";


const notistackRef = React.createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

ReactDOM.render(
  <LayoutProvider>
    <PageDataProvider>
      <UserProvider>
        <ThemeProvider theme={Themes.default}>
          <CssBaseline />
          <ErrorBoundary>
            <SnackbarProvider
              maxSnack={1}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              ref={notistackRef}
              iconVariant={{
                success: <DoneAllSharpIcon style={{ marginRight: 10 }} />,
                error: <ErrorOutlineSharpIcon style={{ marginRight: 10 }} />,
                warning: <WarningSharpIcon style={{ marginRight: 10 }} />,
                info: <ErrorOutlineSharpIcon style={{ marginRight: 10 }} />,
              }}
              action={(key) => (
                <IconButton
                  aria-label="close"
                  onClick={onClickDismiss(key)}
                  size="small"
                  style={{ color: "#fff" }}
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              )}
            >
              <App />
            </SnackbarProvider>
          </ErrorBoundary>
        </ThemeProvider>
      </UserProvider>
    </PageDataProvider>
  </LayoutProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
