import React, { useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Question from "./qus";
import Answers from "./ans";
import { useUserState } from "./../../context/UserContext";

// import PageTitle from "../../components/PageTitle";
// import TabBar from "../../components/TabBar";

export default function FinView(props) {
  const componentRef = useRef();
  const userStateData = useUserState();
  // const printPageData = [
  //   {
  //     path: "result",
  //     componentRef: componentRef,
  //     pageName: "finview",
  //   },
  // ];

  return (
    <>
      {/* <PageTitle title={"FINVIEW METER"} />
      <TabBar basePath={props.path} printPageData={printPageData} /> */}

      <Switch>
        <Route
          exact
          path={`${props.path}`}
          render={() => <Redirect to={`${props.path}/question`} />}
        />
        <Route
          exact
          path={`${props.path}/question`}
          render={() => <Question userData={userStateData.clientData || {}} />}
        />
        <Route
          exact
          path={`${props.path}/result`}
          render={() => (
            <Answers
              userData={userStateData.clientData || {}}
              ref={componentRef}
            />
          )}
        />
      </Switch>
    </>
  );
}
