import React, { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Question from "./qus";
import Answers from "./ans";
import classNames from "classnames";
import { useUserState } from "./../../context/UserContext";
// styles
// import useStyles from "./styles";

// import PageTitle from "../../components/PageTitle";
// import TabBar from "../../components/TabBar";

export default function AttainmentWheel(props) {
  const userStateData = useUserState();
  return (
    <>
      {/* <PageTitle title={"Money Purpose Wheel"} />
      <TabBar basePath={props.path} /> */}
      <Switch>
        <Route
          exact
          path={`${props.path}`}
          render={() => <Redirect to={`${props.path}/question`} />}
        />
        <Route
          exact
          path={`${props.path}/question`}
          render={() => <Question userData={userStateData.clientData || {}} />}
        />
        <Route
          exact
          path={`${props.path}/result`}
          render={() => <Answers userData={userStateData.clientData || {}} />}
        />
      </Switch>
    </>
  );
}
