import shape from "@material-ui/core/styles/shape";
import React, { useState } from "react";
import useStyles from "./../../styles";
export default function PositiveImpression(props) {
  let { positive, setPositive } = props;
  const classes = useStyles();

  let select = (id, status) => {
    let newPositive = [...positive];
    newPositive[id].selected = !status;
    setPositive(newPositive);
  };
  return (
    <svg
      version="1.2"
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1011 535"
      width={1011}
      height={535}
      className="print_svg"
    >
      <title>Impression</title>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n\t\ttspan { white-space:pre }\n\t\t.positiveImpression0 { fill: #767373 } \n\t\t.positiveImpression1 { fill: #ffffff } \n\t\t.txt2 { font-size: 75px;fill: #ff5351;font-weight: bold;font-family: "Myriad Pro";text-anchor: middle } \n\t\t.positiveImpression3 { fill: none;stroke: #ff5351;stroke-width: 4 } \n\t\t.positiveImpression4 { fill: #e44c4a } \n\t\t.txt5 { font-size: 24px;line-height: 33px;fill: #ffffff;font-weight: 700;font-family: "Montserrat-Bold", "Montserrat";text-anchor: middle } \n\t\t.positiveImpression6 { fill: #d7d7d7 } \n\t\t.txt7 { font-size: 24px;line-height: 33px;fill: #000000;font-weight: 700;font-family: "Montserrat-Bold", "Montserrat";text-anchor: middle } \n\t\t.txt8 { font-size: 24px;line-height: 34px;fill: #000000;font-weight: 700;font-family: "Montserrat-Bold", "Montserrat";text-anchor: middle } \n\t\t.txt9 { font-size: 24px;line-height: 33px;fill: #fefefe;font-weight: 700;font-family: "Montserrat-Bold", "Montserrat";text-anchor: middle } \n\t\t.txt10 { font-size: 75px;fill: #31a7c2;font-weight: bold;font-family: "Myriad Pro";text-anchor: middle } \n\t\t.positiveImpression11 { fill: none;stroke: #31a7c2;stroke-width: 4 } \n\t\t.positiveImpression12 { fill: #188ea9 } \n\t\t.txt13 { font-size: 24px;line-height: 28px;fill: #ffffff;font-weight: 700;font-family: "Montserrat-Bold", "Montserrat";text-anchor: middle } \n\t\t.txt14 { font-size: 24px;line-height: 28px;fill: #000000;font-weight: 700;font-family: "Montserrat-Bold", "Montserrat";text-anchor: middle } \n\t\t.txt15 { font-size: 24px;line-height: 33px;fill: #fffefe;font-weight: 700;font-family: "Montserrat-Bold", "Montserrat";text-anchor: middle } \n\t',
        }}
      />
      <g id="Negative">
        <g id="line">
          <path
            id="Rectangle 11 copy"
            className="positiveImpression0"
            d="M164.8 1039.69L632.79 942.35L633.2 944.31L165.21 1041.65L164.8 1039.69Z"
          />
          <path
            id="Rectangle 11 copy 2"
            className="positiveImpression0"
            d="M206.49 1133.63L590.32 848.76L591.51 850.37L207.68 1135.24L206.49 1133.63Z"
          />
          <path
            id="Rectangle 11 copy 3"
            className="positiveImpression0"
            d="M289.03 1204.2L507.19 778.89L508.97 779.8L290.81 1205.11L289.03 1204.2Z"
          />
          <path
            id="Rectangle 11 copy 4"
            className="positiveImpression0"
            d="M507.57 1204.92L288.65 780L290.43 779.08L509.35 1204L507.57 1204.92Z"
          />
          <path
            id="Rectangle 11 copy 5"
            className="positiveImpression0"
            d="M590.16 1135.46L206.65 850.15L207.84 848.54L591.35 1133.85L590.16 1135.46Z"
          />
          <path
            id="Rectangle 11 copy 6"
            className="positiveImpression0"
            d="M632.74 1041.86L164.85 944.1L165.26 942.14L633.15 1039.9L632.74 1041.86Z"
          />
        </g>
        <path
          id="Ellipse 1"
          className="positiveImpression0"
          d="M397.26 837.83C480.87 837.83 548.64 905.71 548.64 989.45C548.64 1073.19 480.87 1141.08 397.26 1141.08C313.66 1141.08 245.88 1073.19 245.88 989.45C245.88 905.71 313.66 837.83 397.26 837.83Z"
        />
        <path
          id="Ellipse 1 copy"
          className="positiveImpression1"
          d="M397.5 843C477.86 843 543 908.14 543 988.5C543 1068.86 477.86 1134 397.5 1134C317.14 1134 252 1068.86 252 988.5C252 908.14 317.14 843 397.5 843Z"
        />
        <text
          id="Negative ImpressionS"
          style={{ transform: "matrix(0.546,0,0,0.546,396.921,978.069)" }}
        >
          <tspan x={0} y={0} className="txt2">
            Negative
          </tspan>
          <tspan x={0} y={84} className="txt2">
            Impressions
          </tspan>
        </text>
        <g id="small circle">
          <path
            id="Ellipse 2"
            className="positiveImpression3"
            d="M397.44 801.86C500.63 801.86 584.28 885.64 584.28 989C584.28 1092.36 500.63 1176.14 397.44 1176.14C294.25 1176.14 210.6 1092.36 210.6 989C210.6 885.64 294.25 801.86 397.44 801.86Z"
          />
          <path
            id="Ellipse 3 copy 12"
            className="positiveImpression0"
            d="M484.63 1146.11C490.65 1146.11 495.53 1151 495.53 1157.03C495.53 1163.06 490.65 1167.95 484.63 1167.95C478.61 1167.95 473.74 1163.06 473.74 1157.03C473.74 1151 478.61 1146.11 484.63 1146.11Z"
          />
          <path
            id="Ellipse 3 copy 12"
            className="positiveImpression0"
            d="M312.98 1146.11C319 1146.11 323.88 1151 323.88 1157.03C323.88 1163.06 319 1167.95 312.98 1167.95C306.97 1167.95 302.09 1163.06 302.09 1157.03C302.09 1151 306.97 1146.11 312.98 1146.11Z"
          />
          <path
            id="Ellipse 3 copy 6"
            className="positiveImpression0"
            d="M548.22 1090.85C554.24 1090.85 559.11 1095.74 559.11 1101.77C559.11 1107.8 554.24 1112.69 548.22 1112.69C542.2 1112.69 537.33 1107.8 537.33 1101.77C537.33 1095.74 542.2 1090.85 548.22 1090.85Z"
          />
          <path
            id="Ellipse 3 copy 6"
            className="positiveImpression0"
            d="M249.39 1090.85C255.41 1090.85 260.29 1095.74 260.29 1101.77C260.29 1107.8 255.41 1112.69 249.39 1112.69C243.38 1112.69 238.5 1107.8 238.5 1101.77C238.5 1095.74 243.38 1090.85 249.39 1090.85Z"
          />
          <path
            id="Ellipse 3 copy 8"
            className="positiveImpression0"
            d="M581.57 1020C587.59 1020 592.47 1024.89 592.47 1030.92C592.47 1036.95 587.59 1041.84 581.57 1041.84C575.55 1041.84 570.68 1036.95 570.68 1030.92C570.68 1024.89 575.55 1020 581.57 1020Z"
          />
          <path
            id="Ellipse 3 copy 8"
            className="positiveImpression0"
            d="M216.04 1020C222.06 1020 226.94 1024.89 226.94 1030.92C226.94 1036.95 222.06 1041.84 216.04 1041.84C210.03 1041.84 205.15 1036.95 205.15 1030.92C205.15 1024.89 210.03 1020 216.04 1020Z"
          />
          <path
            id="Ellipse 3 copy 9"
            className="positiveImpression0"
            d="M581.57 944.24C587.59 944.24 592.47 949.13 592.47 955.16C592.47 961.19 587.59 966.08 581.57 966.08C575.55 966.08 570.68 961.19 570.68 955.16C570.68 949.13 575.55 944.24 581.57 944.24Z"
          />
          <path
            id="Ellipse 3 copy 9"
            className="positiveImpression0"
            d="M216.04 944.24C222.06 944.24 226.94 949.13 226.94 955.16C226.94 961.19 222.06 966.08 216.04 966.08C210.03 966.08 205.15 961.19 205.15 955.16C205.15 949.13 210.03 944.24 216.04 944.24Z"
          />
          <path
            id="Ellipse 3 copy 10"
            className="positiveImpression0"
            d="M548.22 868.93C554.24 868.93 559.11 873.82 559.11 879.85C559.11 885.88 554.24 890.77 548.22 890.77C542.2 890.77 537.33 885.88 537.33 879.85C537.33 873.82 542.2 868.93 548.22 868.93Z"
          />
          <path
            id="Ellipse 3 copy 10"
            className="positiveImpression0"
            d="M249.39 868.93C255.41 868.93 260.29 873.82 260.29 879.85C260.29 885.88 255.41 890.77 249.39 890.77C243.38 890.77 238.5 885.88 238.5 879.85C238.5 873.82 243.38 868.93 249.39 868.93Z"
          />
          <path
            id="Ellipse 3 copy 11"
            className="positiveImpression0"
            d="M484.63 812.78C490.65 812.78 495.53 817.67 495.53 823.7C495.53 829.73 490.65 834.62 484.63 834.62C478.61 834.62 473.74 829.73 473.74 823.7C473.74 817.67 478.61 812.78 484.63 812.78Z"
          />
          <path
            id="Ellipse 3 copy 11"
            className="positiveImpression0"
            d="M312.98 812.78C319 812.78 323.88 817.67 323.88 823.7C323.88 829.73 319 834.62 312.98 834.62C306.97 834.62 302.09 829.73 302.09 823.7C302.09 817.67 306.97 812.78 312.98 812.78Z"
          />
        </g>
        <g id={1}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression4"
            d="M73 731L286 731C302.02 731 315 743.98 315 760L315 761C315 777.02 302.02 790 286 790L73 790C56.98 790 44 777.02 44 761L44 760C44 743.98 56.98 731 73 731Z"
          />
          <text
            id="Boring & Inactive lifestlye"
            style={{ transform: "matrix(0.546,0,0,0.546,179.372,764.795)" }}
          >
            <tspan x={0} y={0} className="txt5">
              Boring &amp; Inactive lifestlye
            </tspan>
          </text>
        </g>
        <g id={2}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression6"
            d="M-23 822L190 822C206.02 822 219 834.98 219 851L219 852C219 868.02 206.02 881 190 881L-23 881C-39.02 881 -52 868.02 -52 852L-52 851C-52 834.98 -39.02 822 -23 822Z"
          />
          <text
            id="Concerns about money"
            style={{ transform: "matrix(0.546,0,0,0.546,83.372,855.795)" }}
          >
            <tspan x={0} y={0} className="txt7">
              Concerns about money
            </tspan>
          </text>
        </g>
        <g id={3}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression4"
            d="M-72 913L141 913C157.02 913 170 925.98 170 942L170 943C170 959.02 157.02 972 141 972L-72 972C-88.02 972 -101 959.02 -101 943L-101 942C-101 925.98 -88.02 913 -72 913Z"
          />
          <text
            id="Feeling isolated"
            style={{ transform: "matrix(0.546,0,0,0.546,34.372,946.795)" }}
          >
            <tspan x={0} y={0} className="txt5">
              Feeling isolated
            </tspan>
          </text>
        </g>
        <g id={4}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression6"
            d="M-72 1004L141 1004C157.02 1004 170 1016.98 170 1033L170 1034C170 1050.02 157.02 1063 141 1063L-72 1063C-88.02 1063 -101 1050.02 -101 1034L-101 1033C-101 1016.98 -88.02 1004 -72 1004Z"
          />
          <text
            id="Health Problems"
            style={{ transform: "matrix(0.546,0,0,0.546,34.372,1039.795)" }}
          >
            <tspan x={0} y={0} className="txt7">
              Health Problems
            </tspan>
          </text>
        </g>
        <g id={5}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression4"
            d="M-23 1095L190 1095C206.02 1095 219 1107.98 219 1124L219 1125C219 1141.02 206.02 1154 190 1154L-23 1154C-39.02 1154 -52 1141.02 -52 1125L-52 1124C-52 1107.98 -39.02 1095 -23 1095Z"
          />
          <text
            id="Concerns about Heavy Medical Bills"
            style={{ transform: "matrix(0.546,0,0,0.546,83.372,1128.795)" }}
          >
            <tspan x={0} y={0} className="txt5">
              Concerns about Heavy Medical Bills
            </tspan>
          </text>
        </g>
        <g id={6}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression6"
            d="M73 1186L286 1186C302.02 1186 315 1198.98 315 1215L315 1216C315 1232.02 302.02 1245 286 1245L73 1245C56.98 1245 44 1232.02 44 1216L44 1215C44 1198.98 56.98 1186 73 1186Z"
          />
          <text
            id="Identity Loss"
            style={{ transform: "matrix(0.546,0,0,0.546,179.372,1219.795)" }}
          >
            <tspan x={0} y={0} className="txt7">
              Identity Loss
            </tspan>
          </text>
        </g>
        <g id={7}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression6"
            d="M504 731L717 731C733.02 731 746 743.98 746 760L746 761C746 777.02 733.02 790 717 790L504 790C487.98 790 475 777.02 475 761L475 760C475 743.98 487.98 731 504 731Z"
          />
          <text
            id="Unhealthy family relationships"
            style={{ transform: "matrix(0.544,0,0,0.534,610.058,764.948)" }}
          >
            <tspan x={0} y={0} className="txt8">
              Unhealthy family relationships
            </tspan>
          </text>
        </g>
        <g id={8}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression4"
            d="M600 822L813 822C829.02 822 842 834.98 842 851L842 852C842 868.02 829.02 881 813 881L600 881C583.98 881 571 868.02 571 852L571 851C571 834.98 583.98 822 600 822Z"
          />
          <text
            id="Not following any Hobby / Activity"
            style={{ transform: "matrix(0.545,0,0,0.54,705.5,856.106)" }}
          >
            <tspan x={0} y={0} className="txt5">
              Not following any Hobby / Activity
            </tspan>
          </text>
        </g>
        <g id={9}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression6"
            d="M649 913L862 913C878.02 913 891 925.98 891 942L891 943C891 959.02 878.02 972 862 972L649 972C632.98 972 620 959.02 620 943L620 942C620 925.98 632.98 913 649 913Z"
          />
          <text
            id="Lack of social engagements"
            style={{ transform: "matrix(0.546,0,0,0.54,754.714,947.106)" }}
          >
            <tspan x={0} y={0} className="txt7">
              Lack of social engagements
            </tspan>
          </text>
        </g>
        <g id={10}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression4"
            d="M649 1004L862 1004C878.02 1004 891 1016.98 891 1033L891 1034C891 1050.02 878.02 1063 862 1063L649 1063C632.98 1063 620 1050.02 620 1034L620 1033C620 1016.98 632.98 1004 649 1004Z"
          />
          <text
            id="Trouble adjusting to retirement phase"
            style={{ transform: "matrix(0.547,0,0,0.54,755.714,1029.106)" }}
          >
            <tspan x={0} y={0} className="txt9">
              Trouble adjusting to retirement
            </tspan>
            <tspan x={0} y="26.647" className="txt9">
              phase
            </tspan>
          </text>
        </g>
        <g id={11}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression6"
            d="M600 1095L813 1095C829.02 1095 842 1107.98 842 1124L842 1125C842 1141.02 829.02 1154 813 1154L600 1154C583.98 1154 571 1141.02 571 1125L571 1124C571 1107.98 583.98 1095 600 1095Z"
          />
          <text
            id="Living a Vision less life"
            style={{ transform: "matrix(0.544,0,0,0.54,706.215,1129.106)" }}
          >
            <tspan x={0} y={0} className="txt7">
              Living a Vision less life
            </tspan>
          </text>
        </g>
        <g id={12}>
          <path
            id="Rounded Rectangle 14 copy"
            className="positiveImpression4"
            d="M504 1186L717 1186C733.02 1186 746 1198.98 746 1215L746 1216C746 1232.02 733.02 1245 717 1245L504 1245C487.98 1245 475 1232.02 475 1216L475 1215C475 1198.98 487.98 1186 504 1186Z"
          />
          <text
            id="Financial Dependence on others"
            style={{ transform: "matrix(0.543,0,0,0.54,610,1220.106)" }}
          >
            <tspan x={0} y={0} className="txt5">
              Financial Dependence on others
            </tspan>
          </text>
        </g>
      </g>
      <g id="Positive copy">
        <g id="line">
          <path
            id="Rectangle 11 copy"
            className="positiveImpression0"
            d="M276.8 317.69L744.79 220.35L745.2 222.31L277.21 319.65L276.8 317.69Z"
          />
          <path
            id="Rectangle 11 copy 2"
            className="positiveImpression0"
            d="M318.49 411.63L702.32 126.76L703.51 128.37L319.68 413.24L318.49 411.63Z"
          />
          <path
            id="Rectangle 11 copy 3"
            className="positiveImpression0"
            d="M401.03 482.2L619.19 56.89L620.97 57.8L402.81 483.11L401.03 482.2Z"
          />
          <path
            id="Rectangle 11 copy 4"
            className="positiveImpression0"
            d="M619.57 482.92L400.65 58L402.43 57.08L621.35 482L619.57 482.92Z"
          />
          <path
            id="Rectangle 11 copy 5"
            className="positiveImpression0"
            d="M702.16 413.46L318.65 128.15L319.84 126.54L703.35 411.85L702.16 413.46Z"
          />
          <path
            id="Rectangle 11 copy 6"
            className="positiveImpression0"
            d="M744.74 319.86L276.85 222.1L277.26 220.14L745.15 317.9L744.74 319.86Z"
          />
        </g>
        <path
          id="Ellipse 1"
          className="positiveImpression0"
          d="M509.26 115.83C592.87 115.83 660.64 183.71 660.64 267.45C660.64 351.19 592.87 419.08 509.26 419.08C425.66 419.08 357.88 351.19 357.88 267.45C357.88 183.71 425.66 115.83 509.26 115.83Z"
        />
        <path
          id="Ellipse 1 copy"
          className="positiveImpression1"
          d="M509.5 121C589.86 121 655 186.14 655 266.5C655 346.86 589.86 412 509.5 412C429.14 412 364 346.86 364 266.5C364 186.14 429.14 121 509.5 121Z"
        />
        <text
          id="PositIve ImpressionS"
          style={{ transform: "matrix(0.546,0,0,0.546,508.921,256.069)" }}
        >
          <tspan x={0} y={0} className="txt10">
            POSITIVE
          </tspan>
          <tspan x={0} y={84} className="txt10">
            IMPRESSIONS
          </tspan>
        </text>
        <g id="small circle">
          <path
            id="Ellipse 2"
            className="positiveImpression11"
            d="M509.44 79.86C612.63 79.86 696.28 163.64 696.28 267C696.28 370.36 612.63 454.14 509.44 454.14C406.25 454.14 322.6 370.36 322.6 267C322.6 163.64 406.25 79.86 509.44 79.86Z"
          />
          <path
            id="Ellipse 3 copy 12"
            className="positiveImpression0"
            d="M596.63 424.11C602.65 424.11 607.53 429 607.53 435.03C607.53 441.06 602.65 445.95 596.63 445.95C590.61 445.95 585.74 441.06 585.74 435.03C585.74 429 590.61 424.11 596.63 424.11Z"
          />
          <path
            id="Ellipse 3 copy 12"
            className="positiveImpression0"
            d="M424.98 424.11C431 424.11 435.88 429 435.88 435.03C435.88 441.06 431 445.95 424.98 445.95C418.97 445.95 414.09 441.06 414.09 435.03C414.09 429 418.97 424.11 424.98 424.11Z"
          />
          <path
            id="Ellipse 3 copy 6"
            className="positiveImpression0"
            d="M660.22 368.85C666.24 368.85 671.11 373.74 671.11 379.77C671.11 385.8 666.24 390.69 660.22 390.69C654.2 390.69 649.33 385.8 649.33 379.77C649.33 373.74 654.2 368.85 660.22 368.85Z"
          />
          <path
            id="Ellipse 3 copy 6"
            className="positiveImpression0"
            d="M361.39 368.85C367.41 368.85 372.29 373.74 372.29 379.77C372.29 385.8 367.41 390.69 361.39 390.69C355.38 390.69 350.5 385.8 350.5 379.77C350.5 373.74 355.38 368.85 361.39 368.85Z"
          />
          <path
            id="Ellipse 3 copy 8"
            className="positiveImpression0"
            d="M693.57 298C699.59 298 704.47 302.89 704.47 308.92C704.47 314.95 699.59 319.84 693.57 319.84C687.55 319.84 682.68 314.95 682.68 308.92C682.68 302.89 687.55 298 693.57 298Z"
          />
          <path
            id="Ellipse 3 copy 8"
            className="positiveImpression0"
            d="M328.04 298C334.06 298 338.94 302.89 338.94 308.92C338.94 314.95 334.06 319.84 328.04 319.84C322.03 319.84 317.15 314.95 317.15 308.92C317.15 302.89 322.03 298 328.04 298Z"
          />
          <path
            id="Ellipse 3 copy 9"
            className="positiveImpression0"
            d="M693.57 222.24C699.59 222.24 704.47 227.13 704.47 233.16C704.47 239.19 699.59 244.08 693.57 244.08C687.55 244.08 682.68 239.19 682.68 233.16C682.68 227.13 687.55 222.24 693.57 222.24Z"
          />
          <path
            id="Ellipse 3 copy 9"
            className="positiveImpression0"
            d="M328.04 222.24C334.06 222.24 338.94 227.13 338.94 233.16C338.94 239.19 334.06 244.08 328.04 244.08C322.03 244.08 317.15 239.19 317.15 233.16C317.15 227.13 322.03 222.24 328.04 222.24Z"
          />
          <path
            id="Ellipse 3 copy 10"
            className="positiveImpression0"
            d="M660.22 146.93C666.24 146.93 671.11 151.82 671.11 157.85C671.11 163.88 666.24 168.77 660.22 168.77C654.2 168.77 649.33 163.88 649.33 157.85C649.33 151.82 654.2 146.93 660.22 146.93Z"
          />
          <path
            id="Ellipse 3 copy 10"
            className="positiveImpression0"
            d="M361.39 146.93C367.41 146.93 372.29 151.82 372.29 157.85C372.29 163.88 367.41 168.77 361.39 168.77C355.38 168.77 350.5 163.88 350.5 157.85C350.5 151.82 355.38 146.93 361.39 146.93Z"
          />
          <path
            id="Ellipse 3 copy 11"
            className="positiveImpression0"
            d="M596.63 90.78C602.65 90.78 607.53 95.67 607.53 101.7C607.53 107.73 602.65 112.62 596.63 112.62C590.61 112.62 585.74 107.73 585.74 101.7C585.74 95.67 590.61 90.78 596.63 90.78Z"
          />
          <path
            id="Ellipse 3 copy 11"
            className="positiveImpression0"
            d="M424.98 90.78C431 90.78 435.88 95.67 435.88 101.7C435.88 107.73 431 112.62 424.98 112.62C418.97 112.62 414.09 107.73 414.09 101.7C414.09 95.67 418.97 90.78 424.98 90.78Z"
          />
        </g>
        <g
          id={1}
          style={{ cursor: "pointer" }}
          onClick={() => select(0, positive[0]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[0]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M185 9L398 9C414.02 9 427 21.98 427 38L427 39C427 55.02 414.02 68 398 68L185 68C168.98 68 156 55.02 156 39L156 38C156 21.98 168.98 9 185 9Z"
          />
          <text
            id="Active bucket list"
            style={{ transform: "matrix(0.425,0,0,0.586,291.372,44.795)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,291.372,44.795)"}}
          >
            <tspan
              x={0}
              y={0}
              className={positive[0]?.selected ? "txt13" : "txt7"}
            >
              {positive[0]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={2}
          style={{ cursor: "pointer" }}
          onClick={() => select(1, positive[1]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[1]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M89 100L302 100C318.02 100 331 112.98 331 129L331 130C331 146.02 318.02 159 302 159L89 159C72.98 159 60 146.02 60 130L60 129C60 112.98 72.98 100 89 100Z"
          />
          <text
            id="Engaging Social & Friend Circle"
            style={{ transform: "matrix(0.425,0,0,0.586,195.372,133.795)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,195.372,133.795)"}}
          >
            <tspan
              x={0}
              y={0}
              className={positive[1]?.selected ? "txt5" : "txt7"}
            >
              {positive[1]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={3}
          style={{ cursor: "pointer" }}
          onClick={() => select(2, positive[2]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[2]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M40 191L253 191C269.02 191 282 203.98 282 220L282 221C282 237.02 269.02 250 253 250L40 250C23.98 250 11 237.02 11 221L11 220C11 203.98 23.98 191 40 191Z"
          />
          <text
            id="Consulting / Mentoring"
            style={{ transform: "matrix(0.525,0,0,0.586,146.372,225.795)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,146.372,225.795)" }}
          >
            <tspan
              x={0}
              y={0}
              className={positive[2]?.selected ? "txt5" : "txt7"}
            >
              {positive[2]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={4}
          style={{ cursor: "pointer" }}
          onClick={() => select(3, positive[3]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[3]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M40 282L253 282C269.02 282 282 294.98 282 311L282 312C282 328.02 269.02 341 253 341L40 341C23.98 341 11 328.02 11 312L11 311C11 294.98 23.98 282 40 282Z"
          />
          <text
            id="Engaged to a Social / Religious Cause"
            style={{ transform: "matrix(0.525,0,0,0.586,146.372,314.800)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,146.372,314.800)"}}
          >
            <tspan
              x={0}
              y={0}
              className={positive[3]?.selected ? "txt5" : "txt7"}
            >
              {positive[3]?.displayName}
            </tspan>
            {/* <tspan x={0} y="26.647" className="txt7">
            
          </tspan> */}
          </text>
        </g>
        <g
          id={5}
          style={{ cursor: "pointer" }}
          onClick={() => select(4, positive[4]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[4]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M89 373L302 373C318.02 373 331 385.98 331 402L331 403C331 419.02 318.02 432 302 432L89 432C72.98 432 60 419.02 60 403L60 402C60 385.98 72.98 373 89 373Z"
          />
          <text
            id="Pursuing Hobby / Activity"
            style={{ transform: "matrix(0.525,0,0,0.586,194.372,407.795)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,194.372,407.795)" }}
          >
            <tspan
              x={0}
              y={0}
              className={positive[4]?.selected ? "txt5" : "txt7"}
            >
              {positive[4]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={6}
          style={{ cursor: "pointer" }}
          onClick={() => select(5, positive[5]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[5]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M185 464L398 464C414.02 464 427 476.98 427 493L427 494C427 510.02 414.02 523 398 523L185 523C168.98 523 156 510.02 156 494L156 493C156 476.98 168.98 464 185 464Z"
          />
          <text
            id="Financially Independent"
            style={{ transform: "matrix(0.525,0,0,0.586,291.372,497.795)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,291.372,497.795)" }}
          >
            <tspan
              x={0}
              y={0}
              className={positive[5]?.selected ? "txt5" : "txt7"}
            >
              {positive[5]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={7}
          style={{ cursor: "pointer" }}
          onClick={() => select(6, positive[6]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[6]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M616 9L829 9C845.02 9 858 21.98 858 38L858 39C858 55.02 845.02 68 829 68L616 68C599.98 68 587 55.02 587 39L587 38C587 21.98 599.98 9 616 9Z"
          />
          <text
            id="A Healthy Lifestyle"
            style={{ transform: "matrix(0.525,0,0,0.576,723.058,42.948)" }}
            // style={{ transform: "matrix(0.544,0,0,0.534,723.058,42.948)" }}
          >
            <tspan
              x={0}
              y={0}
              className={positive[6]?.selected ? "txt5" : "txt14"}
            >
              {positive[6]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={8}
          style={{ cursor: "pointer" }}
          onClick={() => select(7, positive[7]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[7]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M712 100L925 100C941.02 100 954 112.98 954 129L954 130C954 146.02 941.02 159 925 159L712 159C695.98 159 683 146.02 683 130L683 129C683 112.98 695.98 100 712 100Z"
          />
          <text
            id="Cordial Family Relationships"
            style={{ transform: "matrix(0.525,0,0,0.58,818.5,134.106)" }}
            // style={{ transform: "matrix(0.545,0,0,0.54,818.5,134.106)" }}
          >
            <tspan
              x={0}
              y={0}
              className={positive[7]?.selected ? "txt15" : "txt7"}
            >
              {positive[7]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={9}
          style={{ cursor: "pointer" }}
          onClick={() => select(8, positive[8]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[8]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M761 191L974 191C990.02 191 1003 203.98 1003 220L1003 221C1003 237.02 990.02 250 974 250L761 250C744.98 250 732 237.02 732 221L732 220C732 203.98 744.98 191 761 191Z"
          />
          <text
            id="Well thought & Purpose filled Life"
            style={{ transform: "matrix(0.525,0,0,0.58,867.714,225.106)" }}
            // style={{ transform: "matrix(0.546,0,0,0.54,867.714,225.106)" }}
          >
            <tspan
              x={0}
              y={0}
              className={positive[8]?.selected ? "txt5" : "txt7"}
            >
              {positive[8]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={10}
          style={{ cursor: "pointer" }}
          onClick={() => select(9, positive[9]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[9]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M761 282L974 282C990.02 282 1003 294.98 1003 311L1003 312C1003 328.02 990.02 341 974 341L761 341C744.98 341 732 328.02 732 312L732 311C732 294.98 744.98 282 761 282Z"
          />
          <text
            id="More time to family & friends"
            style={{ transform: "matrix(0.525,0,0,0.58,866.714,316.106)" }}
            // style={{ transform: "matrix(0.547,0,0,0.54,866.714,316.106)" }}
          >
            <tspan
              x={0}
              y={0}
              className={positive[9]?.selected ? "txt5" : "txt7"}
            >
              {positive[9]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={11}
          style={{ cursor: "pointer" }}
          onClick={() => select(10, positive[10]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[10]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M712 373L925 373C941.02 373 954 385.98 954 402L954 403C954 419.02 941.02 432 925 432L712 432C695.98 432 683 419.02 683 403L683 402C683 385.98 695.98 373 712 373Z"
          />
          <text
            id="Taking up desired work engagements"
            style={{ transform: "matrix(0.525,0,0,0.58,818.215,406.106)" }}
            // style={{ transform: "matrix(0.544,0,0,0.54,818.215,406.106)" }}
          >
            <tspan
              x={0}
              y={0}
              className={positive[10]?.selected ? "txt5" : "txt7"}
            >
              {positive[10]?.displayName}
            </tspan>
            {/* <tspan x={0} y="26.647" className="txt7">
            
          </tspan> */}
          </text>
        </g>
        <g
          id={12}
          style={{ cursor: "pointer" }}
          onClick={() => select(11, positive[11]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              positive[11]?.selected
                ? "positiveImpression12"
                : "positiveImpression6"
            }
            d="M616 464L829 464C845.02 464 858 476.98 858 493L858 494C858 510.02 845.02 523 829 523L616 523C599.98 523 587 510.02 587 494L587 493C587 476.98 599.98 464 616 464Z"
          />
          <text
            id="Active engagement in Community / NGO work"
            style={{ transform: "matrix(0.525,0,0,0.58,722,499.106)" }}
            // style={{ transform: "matrix(0.543,0,0,0.54,722,489.106)" }}
          >
            <tspan
              x={0}
              y={0}
              className={positive[11]?.selected ? "txt15" : "txt7"}
            >
              {positive[11]?.displayName}
            </tspan>
            {/* <tspan x={0} y="26.647" className="txt15">
            
          </tspan> */}
          </text>
        </g>
      </g>
    </svg>
  );
}
