import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  InputAdornment,
  InputLabel,
  IconButton,
  Link,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import {
  PersonOutline as PersonOutlineIcon,
  LockOutlined as LockOutlinedIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";
import { withRouter } from "react-router-dom";

// styles
import useStyles from "./styles";

// logo

import Footer from "../../components/Footer/Footer";
import PublicHeader from "../../components/PublicHeader/PublicHeader";
import { getRequest, postRequest } from "../../utils/API";
import ApiUri from "../../constants/apiUri";

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";

function ForgetPassword(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ value: "", type: "" });
  var [emailValue, setEmailValue] = useState("");

  const forgetPassword = async () => {
    if (emailValue != "") {
      setIsLoading(true);
      const res = await getRequest(
        `${ApiUri.auth.forgetPassword}?email=${emailValue}`,
      );
      
      setIsLoading(false);
      if (res.status == 200) {
        if (res.data?.data?.emailSentStatus) {
          setEmailValue("");
          setMessage({
            value: "Password Reset Link send Successfully",
            type: "success",
          });
        } else {
          setMessage({
            value: res.data?.data?.message || "Please Try again",
            type: "error",
          });
        }
      } else {
        setMessage({ value: "Please Try again", type: "error" });
      }
    } else {
      setMessage({ value: "Please enter Email Id/ User Name", type: "error" });
    }
  };
  return (
    <Grid container className={classes.container}>
      <PublicHeader />
      <Grid item className={classes.mainLayout} xs={12}>
        <Grid container justify="flex-end">
          <Grid item xs={4} className={classes.formContainer}>
            <Typography variant="h2" className={classes.greeting}>
              Forget Password
            </Typography>
            <Typography variant="p" className={classes.subGreeting}>
              Forgot your account’s password or having trouble logging into
              account? Enter your email address and we’ll send you a recovery
              link.
            </Typography>
            {message.value ? (
              <Fade in={message.value}>
                <Alert severity={message.type}>{message.value}</Alert>
              </Fade>
            ) : (
              ""
            )}

            <div>
              <InputLabel
                htmlFor="component-helper"
                className={classes.inputFieldLable}
              >
                Username
              </InputLabel>
              <TextField
                id="email"
                className={classes.inputFieldStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutlineIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                  style: { borderRadius: "50px" },
                }}
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
                margin="normal"
                placeholder="Email Id"
                variant="outlined"
                type="email"
                fullWidth
                style={{
                  borderRadius: "50px",
                  marginTop: "4px",
                }}
              />
            </div>
            <div className={classes.formButtonSection}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  className={classes.formButton}
                  onClick={forgetPassword}
                  variant="contained"
                  size="large"
                  fullWidth
                  color="warning"
                >
                  Submit
                </Button>
              )}
            </div>

            <div className={classes.signupSection}>
              <Typography variant="pre">
                Go Back ?{" "}
                <Link
                  href="#/login"
                  color="primary"
                  size="large"
                  className={classes.signupButton}
                >
                  {" "}
                  Login{" "}
                </Link>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Footer hideLinks={true} />
    </Grid>
  );
}

export default withRouter(ForgetPassword);
