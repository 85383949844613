import { makeStyles } from "@material-ui/styles";
import { fade } from "@material-ui/core/styles/colorManipulator";

export default makeStyles((theme) => ({
  logotype: {
    color: "white",
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    fontWeight: 500,
    fontSize: 18,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  header: {
    backgroundImage: "url('static/images/fin-view-header.png')",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    height: "110px",
    lineHeight: "110px",
    backgroundRepeat: "no-repeat",
    padding: "0px 40px",
    backgroundPositionY: "bottom",
  },
  headerImg: {
    width: "120px",
    height: "auto",
    verticalAlign: "middle",
  },
  appBar: {
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    right: "unset",
    boxShadow: "none",
    // transition: theme.transitions.create(["margin"], {
    //   easing: theme.transitions.easing.sharp,
    //   duration: theme.transitions.duration.leavingScreen,
    // }),
  },
  toolbar: {
    // paddingLeft: theme.spacing(2),
    // paddingRight: theme.spacing(2),
    position: "absolute",
    right: "0px",
    bottom: "0px",
    minHeight: "auto",
  },
  hide: {
    display: "none",
  },
  grow: {
    flexGrow: 1,
  },
  messageContent: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenu: {
    marginTop: theme.spacing(7),
  },
  headerMenuList: {
    display: "flex",
    flexDirection: "column",
  },
  headerMenuItem: {
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
      // color: "white",
    },
  },
  headerMenuButton: {
    marginLeft: theme.spacing(4),
    padding: theme.spacing(0),
    color: "#000",
  },
  headerMenuButtonSandwich: {
    marginLeft: 9,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
    padding: theme.spacing(0.5),
  },
  headerMenuButtonCollapse: {
    marginRight: theme.spacing(2),
  },
  headerIcon: {
    fontSize: 32,
    color: "#000",
    marginRight: "-10px",
  },
  dropheaderIcon: {
    fontSize: 28,
    color: "#000",
  },
  headerIconCollapse: {
    color: "black",
  },
  profileMenu: {
    minWidth: 238,
    color: "#000",
  },
  profileMenuUser: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    textTransform: "capitalize",
  },
  profileMenuItem: {
    color: theme.palette.text.hint,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  profileMenuLink: {
    fontSize: 16,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
  messageNotification: {
    height: "auto",
    display: "flex",
    alignItems: "center",
    "&:hover, &:focus": {
      backgroundColor: theme.palette.background.light,
    },
  },
  messageNotificationSide: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: theme.spacing(2),
  },
  messageNotificationBodySide: {
    alignItems: "flex-start",
    marginRight: 0,
  },
  sendMessageButton: {
    margin: theme.spacing(4),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: "none",
  },
  sendButtonIcon: {
    marginLeft: theme.spacing(2),
  },
  purchaseBtn: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginRight: theme.spacing(3),
  },
  dialogContentSection: {
    margin: 0,
    padding: 0,
  },
  dialogTitleSection: {
    margin: 0,
    padding: 0,
    textAlign: "center",
  },
  underLine: {
    float: "left",
    width: "calc( 50% - 6.625px )",
    borderBottom: "3px solid #E84750",
    // marginTop: 10,
  },
  dot: {
    float: "left",
    padding: "0 1px",
    color: "#154854",
    marginTop: "-40px",
    fontSize: 39,
  },
  underLineSection: {
    display: "inline-block",
    width: "20%",
    marginTop: "-40px",
  },
  resultButton: {
    background: `${theme.palette.warning.main}`,
    borderRadius: 50,
    padding: "10px 20px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 200,
    "&:hover": {
      background: `${theme.palette.warning.dark}`,
    },
  },
  submitButton: {
    background: `${theme.palette.orange.main}`,
    borderRadius: 50,
    padding: "10px 20px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 200,
    "&:hover": {
      background: `${theme.palette.orange.dark}`,
    },
  },
}));
