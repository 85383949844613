import defaultTheme from "./default";

import { createMuiTheme } from "@material-ui/core";

const overrides = {
  typography: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
      fontWeight: 600,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
  },
  MuiCssBaseline: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: "10px",
        backgroundColor: "#F5F5F5",
      },
      "*::-webkit-scrollbar-track": {
        "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
        backgroundColor: "#F5F5F5",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "#000000",
        border: "2px solid #555555",
        //outline: '1px solid slategrey'
      },
    },
  },
};

export default {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};
