import React from "react";
import { Grid, Tooltip, Fab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import GraphIcon from "./graphIcon";

import { useDrop } from "react-dnd";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    background: "#fff",
    padding: 10,
    color: "#000",
    fontSize: "12px",
    maxWidth: 550,
    opacity: 1,
    border: "1px solid #616161",
  },
}))(Tooltip);

// components
export default function GraphSquare(props) {
  const [{ isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: "select",
      drop: () => ({ name: props.value }),
    }),
    [],
  );

  return (
    <>
      <div
        className="graph_square_section"
        style={{ minWidth: props.minWidth }}
      >
        <div ref={drop} className="graph_square_box">
          {props.data.length !== 0 ? (
            props.data.length > 1 ? (
              <div>
                <Fab
                  color="primary"
                  size="small"
                  className="fabStyle"
                  aria-label="add"
                >
                  <div>
                    <HtmlTooltip
                      arrow
                      interactive
                      title={
                        <Grid container spacing={1}>
                          {props.data.map((v, i) => {
                            return (
                              <Grid item className="tranLenGrid">
                                <GraphIcon
                                  transitionName={v.transitionName}
                                  imageIcon={v.imageIcon}
                                  onDelete={() => props.onDelete(i)}
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      }
                    >
                      <div className="tranLen">{props.data.length}</div>
                    </HtmlTooltip>
                  </div>
                </Fab>
              </div>
            ) : (
              <GraphIcon
                transitionName={props.data[0].transitionName}
                imageIcon={props.data[0].imageIcon}
                onDelete={() => props.onDelete(0)}
              />
            )
          ) : (
            <div className="graph_square_section_div"></div>
          )}
        </div>
      </div>
    </>
  );
}
