import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  TextareaAutosize,
} from "@material-ui/core";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import IconButton from "@material-ui/core/IconButton";
import ApiUri from "../../../constants/apiUri";
import { getRequest, postRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import {DataNotFoundDispatchContext}  from '../../../context/DataNotFoundContext'
import "./styles.css";
import SvgIcon from "./svgIcon";
import useStyles from "./styles";
import InfoDialog from "../components/Dialog/info";
import ViewDialog from "../components/Dialog/view";
import { useSnackbar } from 'notistack';


export default function Question(props) {
  const setBackdrop = useContext(BackdropDispatchContext);
  const setDataNotFound = useContext(DataNotFoundDispatchContext);
  const classes = useStyles();

  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [selectedQus, setSelectedQus] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState(null);

  const [qustionList, setQustionList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();


  const getQusList = async () => {
    setBackdrop(true);
    let reqData = await getRequest(
      `${ApiUri.lifeIdealogyProfiler.qustionList}/${props.userData.code}`,
    );
    if (reqData.status === 200) {
      // console.log("reqData", reqData.data)
      setBackdrop(false);
      setQustionList(reqData.data.data.lifeIdealogyProfiler);
    } else {
      setBackdrop(false);
      setDataNotFound(true);
    }
  };

  const onSubmit = async () => {
    let data = {
      lifeIdealogyProfiler: qustionList,
      userDetails: {
        code: props.userData.code,
      },
    };
    setBackdrop(true);
    setDisabledSubmit(true);
    let reqData = await postRequest(
      ApiUri.lifeIdealogyProfiler.submitQustion,
      data,
    );

    if (reqData.status === 200 || reqData.status === 201) {
      enqueueSnackbar("Data Save Successfully");
      setQustionList(reqData.data.data.lifeIdealogyProfiler);
    } else {
      enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
    }
    setBackdrop(false);
      setDisabledSubmit(false);
  };
  const onChangeValue = (value, code) => {
    const newQustionList = [...qustionList];
    const { userData } = props;
    const findIndex = newQustionList.findIndex((e) => code === e.code);
    if (newQustionList[findIndex].userEntry) {
      newQustionList[findIndex].userEntry.entry = value;
    } else {
      newQustionList[findIndex].userEntry = {
        entry: value,
        userName: userData.userName,
        userId: userData.id,
      };
    }
    setQustionList(newQustionList);
  };

  useEffect(() => {
    getQusList();
  }, []);

  const getTextArea = (v, rows) => {
    return (
      <div>
        <TextareaAutosize
          rowsMax={rows || 3}
          rowsMin={rows || 3}
          style={{
            width: "100%",
            padding: 8,
            border: "2px solid lightgray",
            marginTop: 10,
            fontSize: "15px",
            resize: "vertical",
          }}
          aria-label="maximum height"
          placeholder={v.displayName}
          value={v.userEntry && v.userEntry.entry}
          onChange={(e) => {
            onChangeValue(e.target.value, v.code);
          }}
        />
      </div>
    );
  };

  return (
    <>
      <Box style={{ position: "relative" }}>
        {selectedInfo !== null && (
          <InfoDialog
            infoData={qustionList[selectedInfo]}
            setSelectedInfo={setSelectedInfo}
            onSubmit={(value) => {
              onChangeValue(value, qustionList[selectedInfo].code);
            }}
          />
        )}
        <div className="main-root">
          <div className="root">
            <div className="main-container">
              {qustionList &&
                qustionList.map((v, i) => {
                  return (
                    <Grid container className="grid-container">
                      <Grid item sm={2} className="leftContent">
                        <SvgIcon
                          icon={v.image}
                          textValue={i + 1}
                          type={v.code}
                        />
                      </Grid>
                      <Grid item sm={9} className="rightContent">
                        <div className="heading">
                          <h2>{v.displayName}</h2>
                          <div className="headingIcon">
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                setSelectedInfo(i);
                              }}
                              size="small"
                            >
                              <InfoOutlinedIcon />
                            </IconButton>
                            <IconButton
                              aria-label="delete"
                              onClick={() => {
                                setSelectedQus(v);
                                setOpenDialog(true);
                              }}
                              size="small"
                            >
                              <OpenInNewOutlinedIcon />
                            </IconButton>
                          </div>
                        </div>
                        {getTextArea(v)}
                      </Grid>
                    </Grid>
                  );
                })}
            </div>
          </div>
        </div>
      </Box>
      {openDialog && (
        <ViewDialog
          setOpenDialog={setOpenDialog}
          selectedQus={selectedQus}
          getTextArea={getTextArea}
        />
      )}
      <Box margin="auto" textAlign="center">
        <Grid container justify="space-evenly" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              component={Link}
              to={"/app/life-idealogy-profiler/result"}
              className={classes.resultButton}
            >
              View Result
            </Button>
          </Grid>
          <Grid item>
            {" "}
            <Button
              variant="contained"
              disabled={disabledSubmit}
              onClick={onSubmit}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
