import React, { useEffect, useState, useContext } from "react";
import FirScore from "./icon/firScore";
import useStyles from "./styles";
import { Grid, Box, Badge, Paper, Button,Typography } from "@material-ui/core";
import FirSection from "../components/firSection";
import { getRequest, postRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import ApiUri from "../../../constants/apiUri";
import { Link ,useLocation} from "react-router-dom";
import {DataNotFoundDispatchContext}  from '../../../context/DataNotFoundContext';
// import { PrintDataDispatchContext } from "../../../context/PrintDataContext";
import { PageDataContext } from "../../../context/PageDataContext";
import {isEmpty} from 'lodash'
// import {printSectionTypes} from "../../../constants/index"



export default function Fir(props) {
  const setBackdrop = useContext(BackdropDispatchContext);
  const setDataNotFound = useContext(DataNotFoundDispatchContext);
  // const setPrintData = useContext(PrintDataDispatchContext);
  const { submenuContainer } = useContext(PageDataContext);
  const location = useLocation();


  const classes = useStyles();
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [firScore, setFirScore] = useState(0);
  const [firScoreColor, setFirScoreColor] = useState(0);
  const [tawScores, setTawScores] = useState([]);
  const [tawAns, setTawAns] = useState([]);
  const [firScoreMeters, setFirScoreMeters] = useState([]);
  const [selectedCircle, setSelectedCircle] = useState(0);

  const getQusList = async () => {
    setBackdrop(true);
    try {
      let resData = await getRequest(ApiUri.rm.getFirData);
      if (resData.status == 200) {
        setBackdrop(false);
        const { firScore, legends, tawResult } = resData.data.data;
        if(isEmpty(firScore)){
          throw new Error(); 
          return;
        }
        // console.log("firScore", firScore);
        setFirScore(firScore?.score);
       

        const findCOlor = legends?.firScoreMeters.find((v) => {
          return (
            v.lowScore <= firScore?.score && firScore?.score <= v.highScore
          );
        })?.associatedHexCode;
        // setPrintData(printSectionTypes.retireMeant)
        setFirScoreColor(findCOlor);
        setFirScoreMeters(legends.firScoreMeters);
        setTawScores(tawResult?.questionAnswerMappingByUser?.tawScores || []);
        setTawAns(tawResult?.questionAnswerMappingByUser?.categories || []);
        // setTawResult(data?.tawResult?.questionAnswerMappingByUser?);
      } else {
        setBackdrop(false);
      setDataNotFound(true);
      }
    } catch (error) {
      setBackdrop(false);
      setDataNotFound(true);
      console.log(error);
    }
  };
  useEffect(() => {
    // console.log("DemoData",DemoData)
    getQusList();
  }, []);

 
  const additionalStaticData = (submenuContainer?.submenus || []).find((v) => {
    return location.pathname === v.data.targetUri && v.additionalStaticData;
  })?.additionalStaticData?.data;

  console.log("submenuContainer",additionalStaticData)

  return (
    <>
      <div style={{ textAlign: "center" }}>
        {tawAns.length > 0 && (
          <FirScore
            firScore={firScore}
            firScoreColor={firScoreColor}
            tawAns={tawAns}
            selectedCircle={selectedCircle}
            setSelectedCircle={setSelectedCircle}
          />
        )}
      </div>
      <div className="print_svg">
      <Box p={2}>
        <Grid
          container
          direction="row"
          justify="center"
          aliginItems="flex-start"
          spacing={4}
          className={classes.statusSection}
        >
          {firScoreMeters.map((v) => {
            return (
              <Grid item>
                {/* <span
                  className={classes.dot}
                  style={{ backgroundColor: v.associatedHexCode }}
                ></span>
                {v.lowScore}-{v.highScore} */}
                <Paper
                  style={{
                    backgroundColor: v.associatedHexCode,
                    color: "#fff",
                    width: "75px",
                    padding: "5px 0px",
                    fontSize: "16px",
                    textAlign: "center",
                    fontWeight: 600,
                  }}
                >
                  {v.lowScore}-{v.highScore}
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      </div>
      <Box style={{ position: "relative" }} p={3}>
      <Typography className={classes.additionalStaticData}>
      {(additionalStaticData || '').split("\n").map((i,key) => {
            return <div key={key}>{i}</div>;
        })}
          {}
       </Typography>
      </Box>

      <Box style={{ position: "relative" }} p={3}>
        {tawAns.length && (
          <FirSection
            tawScores={tawScores}
            selectetAns={tawAns[selectedCircle]}
          />
        )}
      </Box>
      <Box margin="auto" textAlign="center" marginTop="30px">
        <Grid container justify="space-evenly" alignItems="center">
          {/* <Grid item>
            <Button
              variant="contained"
              diabled={disabledSubmit}
              onClick={() => {
              }}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </Grid> */}
          <Grid item>
            <Link
              to={`${props.path}/impression`}
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" className={classes.submitButton}>
                Next
              </Button>
            </Link>{" "}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
