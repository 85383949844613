import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Box,
  Badge,
  Typography,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
} from "@material-ui/core";
import PieChart from "./pieChart";
// import PieChart2 from "./pieChart/index_new";
import Result from "../components/Result";
import useStyles from "./styles";
import ApiUri from "../../../constants/apiUri";
import { getRequest, postRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import {DataNotFoundDispatchContext}  from '../../../context/DataNotFoundContext'
import { PrintDataDispatchContext } from "../../../context/PrintDataContext";
// import PrintDataComponent from '../../../PrintData/FinviewMeter'
import {printSectionTypes} from "../../../constants/index"



let graphSection = ["low", "fair", "average", "good", "excellent"];
const colors = {
  A002: "#fe8b32",
  A004: "#05b97a",
  A001: "#ff5351",
  A005: "#00b7d7",
  A003: "#fcc900",
};
const Answers = React.forwardRef((props, ref) => {
  const setBackdrop = useContext(BackdropDispatchContext);
  const setDataNotFound = useContext(DataNotFoundDispatchContext);
  const setPrintData = useContext(PrintDataDispatchContext)



  const classes = useStyles();
  const [selectedResult, setSelectedResult] = useState([]);

  const getUserResult = async () => {
    setBackdrop(true);
    let reqData = await getRequest(
      `${ApiUri.finView.userResults}/${props.userData.code}`,
    );
    if (reqData.status == 200) {
      setSelectedResult(
        reqData.data.data.questionAnswerMappingByUser.categories,
      );
      setPrintData(printSectionTypes.finView)

      setBackdrop(false);
    } else {
      setBackdrop(false);
      setDataNotFound(true);
    }
  };

  useEffect(() => {
    getUserResult();
  }, []);

  return (
    <>
      <Box component="div" ref={ref} textAlign="center">
        <PieChart selectedResult={selectedResult} graphSection={graphSection} />
        {/* <PieChart2
          selectedResult={selectedResult}
          graphSection={graphSection}
        /> */}
        <Box p={2} className="print_svg">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="flex-start"
            spacing={4}
            className={classes.statusSection}
          >
            <Grid item className={classes.low}>
              <Paper style={{ backgroundColor: colors.A001 }}>Low</Paper>
            </Grid>
            <Grid item className={classes.fair}>
              <Paper style={{ backgroundColor: colors.A002 }}>FAIR</Paper>
            </Grid>
            <Grid item className={classes.average}>
              <Paper style={{ backgroundColor: colors.A003 }}>AVERAGE</Paper>
            </Grid>
            <Grid item className={classes.good}>
              <Paper style={{ backgroundColor: colors.A004 }}>GOOD</Paper>
            </Grid>
            <Grid item className={classes.excellent}>
              <Paper style={{ backgroundColor: colors.A005 }}>EXCELLENT</Paper>
            </Grid>
          </Grid>
        </Box>
        <Box p={3}>
          {selectedResult.map((v) => {
            let selectedItemScore = v.questions[0].answers.find((item) => {
              return item.result == 1;
            });
            selectedItemScore = graphSection[selectedItemScore.score - 1];
            return (
              <Result
                qustionId={v.code}
                defaultValue={""}
                icon={v.iconBlob}
                type={v.displayName}
                graphSection={graphSection}
                options={v.questions[0].answers}
                subHeading={v.questions[0].question}
                selectedItemScore={selectedItemScore}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
});

export default Answers;
