import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { TextField, InputAdornment, Box } from "@material-ui/core";
import {
  getRequest,
  postRequest,
  deleteRequest,
} from "./../../../../utils/API";
import ApiUri from "./../../../../constants/apiUri";
import DeleteIcon from "@material-ui/icons/Delete";
const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.action.hover,
    color: "#365f69",
    textTransform: "uppercase",
    fontWeight: 600,
  },
  body: {
    fontSize: 14,
    color: "#080808",
    padding: "10px 20px",
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const CustomTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&:hover fieldset": {
        borderColor: "yellow",
      },
      "&.Mui-focused fieldset": {
        borderColor: "green",
      },
    },
  },
})(TextField);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  inputFiels: {
    width: 230,
  },
});

export default function Tables(props) {
  const {
    qusGroup,
    qustionList,
    setQustionList,
    sectionId,
    roundOf,
    getQusList,
  } = props;
  const classes = useStyles();
  const [sectionMonthlyTotal, setSectionMonthlyTotal] = useState(0);
  const [sectionYearlyTotal, setSectionYearlyTotal] = useState(0);

  const [sectionMonthlyBalance, setSectionMonthlyBalance] = useState(0);
  const [sectionYearlyBalance, setSectionYearlyBalance] = useState(0);

  const onChange = (value, type, groupId, qusId) => {
    const newQusList = [...qustionList];

    var patt = new RegExp(/^[0-9]\d*\.?\d{0,2}$/);

    if (patt.test(value) || value == "") {
      let selectedRow = newQusList[sectionId].groups[groupId].questions[qusId];

      if (!selectedRow.questionMpvAmount) {
        selectedRow.questionMpvAmount = {
          monthlyAmount: 0,
          yearlyAmount: 0,
        };
      }

      if (type == "yearly") {
        selectedRow.questionMpvAmount.yearlyAmount = value;
        selectedRow.questionMpvAmount.monthlyAmount = value
          ? roundOf(value / 12)
          : "";
      } else {
        selectedRow.questionMpvAmount.yearlyAmount = value
          ? roundOf(value * 12)
          : "";
        selectedRow.questionMpvAmount.monthlyAmount = value;
      }

      setQustionList(newQusList);
    }
  };

  const onDelete = async (e, value) => {
    let data = [
      {
        code: value,
      },
    ];
    console.log(data);
    // setBackdrop(true);
    // setAddNewStatus(false);
    let reqData = await deleteRequest(ApiUri.mpv.deleteQustion, { data: data });
    if (reqData.status === 200) {
      console.log(reqData.data);
      getQusList();
    } else {
    }
  };

  return (
    <Box pb={5}>
      <TableContainer>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell></StyledTableCell>
              <StyledTableCell align="center" className={classes.inputFiels}>
                Monthly
              </StyledTableCell>
              <StyledTableCell align="center" className={classes.inputFiels}>
                Yearly
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {qusGroup.map((groupList, groupId) => {
              return (
                <>
                  {groupList.questions.map((row, qusId) => {
                    // console.log(
                    //   "row222",
                    //   groupList.code,
                    //   row.questionMpvAmount?.monthlyAmount,
                    // );
                    return (
                      <StyledTableRow key={row.code}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{
                            color: row.questionFontHexCode,
                            fontStyle: row.questionDisplayedInItalics
                              ? "italic"
                              : "normal",
                            fontWeight: row.questionFontWeightBold
                              ? "bold"
                              : "400",
                          }}
                        >
                          {row.question}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={classes.inputFiels}
                        >
                          {row.questionTextBoxRequired ? (
                            <TextField
                              variant="outlined"
                              value={
                                groupList.code !== "TOT"
                                  ? row.questionMpvAmount?.monthlyAmount || ""
                                  : row.questionArithmeticOperation == 1
                                  ? props.sectionTotal
                                  : props.sectionBalance
                              }
                              onChange={(e) => {
                                onChange(
                                  e.target.value,
                                  "monthly",
                                  groupId,
                                  qusId,
                                );
                              }}
                              size="small"
                              disabled={groupList.code == "TOT"}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      src="static/images/money-purpose-wheel/rupee.svg"
                                      alt="rupee"
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          ) : row.code == "MON-OUTFLOW-HEADER-QUES" ? (
                            props.totalIncome
                          ) : row.code == "SAVINGS-HEADER-QUES" ? (
                            props.outflowSaving
                          ) : (
                            row?.questionMpvAmount?.monthlyAmount
                          )}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          className={classes.inputFiels}
                        >
                          {row.questionTextBoxRequired ? (
                            <TextField
                              variant="outlined"
                              value={
                                groupList.code !== "TOT"
                                  ? row.questionMpvAmount
                                    ? row.questionMpvAmount.yearlyAmount
                                    : ""
                                  : row.questionArithmeticOperation == 1
                                  ? roundOf(props.sectionTotal * 12)
                                  : roundOf(props.sectionBalance * 12)
                              }
                              onChange={(e) => {
                                onChange(
                                  e.target.value,
                                  "yearly",
                                  groupId,
                                  qusId,
                                );
                              }}
                              size="small"
                              disabled={groupList.code == "TOT"}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <img src="static/images/money-purpose-wheel/rupee.svg" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          ) : row.code == "MON-OUTFLOW-HEADER-QUES" ? (
                            roundOf(props.totalIncome * 12)
                          ) : row.code == "SAVINGS-HEADER-QUES" ? (
                            props.outflowSaving * 12
                          ) : (
                            row?.questionMpvAmount?.yearlyAmount
                          )}
                        </StyledTableCell>
                        <StyledTableCell width={2}>
                          {
                            // userAddedQuestion
                            row.userAddedQuestion ? (
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={(e) => onDelete(e, row.code)}
                              />
                            ) : (
                              ""
                            )
                          }
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
