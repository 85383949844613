import React from "react";
import {
  DialogActions,
  Button,
  Dialog,
  DialogContent,
} from "@material-ui/core";
import useStyles from "./../../styles";
import Firscore from "./Firscore";
export default function ResponsiveDialog(props) {
  const classes = useStyles();

  const onSubmit = () => {
    let newData = "";
    props.onSubmit(newData);
    props.setSelectedInfo(null);
  };
  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={true}
        // onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent className={classes.dialogContentSection}>
          <Firscore
            totalScore={props.totalScore}
            selectedResult={props.selectedResult}
            tawScores={props.tawScores}
          />

          <DialogActions className={classes.dialogActionSection}>
            <Button
              onClick={() => {
                props.setSelectedInfo(null);
              }}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>{" "}
      </Dialog>
    </div>
  );
}
