import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Container,
  ListItem,
  ListItemText,
  Collapse,
  List,
} from "@material-ui/core";
import MinimizeIcon from "@material-ui/icons/Minimize";
import AddIcon from "@material-ui/icons/Add";
import { ChatOutlined as ChatOutlinedIcon } from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "./styles";
import CardHeader from "../../../../components/CardHeader";
import ClassNames from "classnames";
import PageTitle from "../../../../components/PageTitle";
import CurvedSection from "./curvedSection";
import CategoryRow from "./categoryRow";
// components

export default function Result(props) {
  let { mpvResult, titleName, totalBalance } = props;
  console.log("tyyyy4444",totalBalance)
  const classes = useStyles();
  const [health, setHealth] = React.useState(true);
  const [money, setMoney] = React.useState(true);
  const [personal, setPersonal] = React.useState(false);
  const [relationship, setRelationship] = React.useState(false);
  const [recreation, setRecreation] = React.useState(false);
  const [career, setCareer] = React.useState(false);
  const [defaultData, setDefaultData] = React.useState(false);
  const handleClick = (val) => {
    

    switch (val) {
      case "health":
        setHealth(!health);
        break;
      case "money":
        setMoney(!money);
        break;
      case "personal":
        setPersonal(!personal);
        break;
      case "relationship":
        setRelationship(!relationship);
        break;
      case "recreation":
        setRecreation(!recreation);
        break;
      case "career":
        setCareer(!career);
        break;
      case "default":
        setDefaultData(!defaultData);
        break;
    }
  };
  let iconData = (data) => {
    return data ? <MinimizeIcon /> : <AddIcon />;
  };
  return (
    <>
      <PageTitle title={titleName} propTitle={true} />

      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        // alignItems="center"
        style={{ margin: "20px 0 20px 0" }}
      >
        <Grid item xs={7}></Grid>
        <Grid item xs={2}>
          <Box component="div" fontWeight={750}>
            MONTHLY
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box fontWeight={750} component="div">
            YEARLY
          </Box>
        </Grid>
        <Grid item xs={1}></Grid>
      </Grid>
      {
        (mpvResult?.code !='INC-WHL')?
      mpvResult?.groups?.map((d) => {

        return (
          <>
            <ListItem
              button
              onClick={() => handleClick(d.name.toLowerCase())}
              style={{ backgroundColor: "rgb(251 251 251)" }}
            >
              <ListItemText
                primary={
                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                  >
                    <Grid item xs={7}>
                      <h3>{d.name.toUpperCase()}</h3>
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        component="h3"
                        style={{ color: "#00b7d7" }}
                        fontWeight={700}
                      >
                        &#x20B9; {(+d.totalMonthlyAmount).toFixed(2)}
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        component="h3"
                        style={{ color: "#00b7d7" }}
                        fontWeight={700}
                      >
                        &#x20B9; {(+d.totalYearlyAmount).toFixed(2)}
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      {d.name.toLowerCase() == "health"
                        ? iconData(health)
                        : d.name.toLowerCase() == "money"
                        ? iconData(money)
                        : d.name.toLowerCase() == "relationship"
                        ? iconData(relationship)
                        : d.name.toLowerCase() == "personal"
                        ? iconData(personal)
                        : d.name.toLowerCase() == "recreation"
                        ? iconData(recreation)
                        : d.name.toLowerCase() == "career"
                        ? iconData(career)
                        : d.name.toLowerCase() == "default"
                        ? iconData(defaultData)
                        : ""}
                    </Grid>
                  </Grid>
                }
              />
            </ListItem>

            <Collapse
              in={
                d.name.toLowerCase() == "health"
                  ? health
                  : d.name.toLowerCase() == "money"
                  ? money
                  : d.name.toLowerCase() == "relationship"
                  ? relationship
                  : d.name.toLowerCase() == "personal"
                  ? personal
                  : d.name.toLowerCase() == "recreation"
                  ? recreation
                  : d.name.toLowerCase() == "career"
                  ? career
                  : d.name.toLowerCase() == "default"
                  ? defaultData
                  : ""
              }
              timeout="auto"
              unmountOnExit
            >
              <List component="div">
                <ListItem className={classes.nested}>
                  <ListItemText
                    primary={d.questions.map((data) => {
                      return (
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                        >
                          <Grid item xs={7}>
                            <p>{data.question}</p>
                          </Grid>
                          <Grid item xs={2}>
                            <Box component="h4">
                              &#x20B9; {(+data.questionMpvAmount?.monthlyAmount).toFixed(2)}
                            </Box>
                          </Grid>
                          <Grid item xs={2}>
                            <Box component="h4">
                              &#x20B9; {(+data.questionMpvAmount?.yearlyAmount).toFixed(2)}
                            </Box>
                          </Grid>
                          <Grid item xs={1}></Grid>
                        </Grid>
                      );
                    })}
                  />
                </ListItem>
              </List>
            </Collapse>
          </>
        );
      }):<></>}
      <ListItem>
        <ListItemText
          primary={
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs={7}>
                <h3 style={{ color: "#00b7d7" }} fontWeight={700}>
                  Total
                </h3>
              </Grid>
              <Grid item xs={2}>
                <Box
                  component="h3"
                  style={{ color: "#00b7d7" }}
                  fontWeight={700}
                >
                  &#x20B9; {(+mpvResult.totalMonthlyAmount || 0).toFixed(2)}
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  component="h3"
                  style={{ color: "#00b7d7" }}
                  fontWeight={700}
                >
                  &#x20B9; {(+mpvResult.totalYearlyAmount || 0).toFixed(2)}
                </Box>
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          }
        />
      </ListItem>
      {totalBalance && (
        <ListItem>
          <ListItemText
            primary={
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item xs={7}>
                  <h3 style={{ color: "#C3C3C3" }} fontWeight={700}>
                    Balance
                  </h3>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    component="h3"
                    style={{ color: "#C3C3C3" }}
                    fontWeight={700}
                  >
                    &#x20B9; {(+totalBalance?.monthlyAmount || 0).toFixed(2)}
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    component="h3"
                    style={{ color: "#C3C3C3" }}
                    fontWeight={700}
                  >
                    &#x20B9; {(+totalBalance?.yearlyAmount || 0).toFixed(2)}
                  </Box>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            }
          />
        </ListItem>
      )}
    </>
  );
}
