import React, { useState, useEffect } from "react";

import SignUpForm from "../signUp/signUpForm";
import { getRequest, postRequest } from "./../../utils/API";
import ApiUri from "./../../constants/apiUri";
import { isEmpty } from "lodash";
import {
  useUserDispatch,
  signOut,
  useUserState,
} from "../../context/UserContext";
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  Container,
  Box,
  TextField,
  IconButton
} from "@material-ui/core";
import useStyles from "./styles";
import {Close as CloseIcon} from '@material-ui/icons';



function EditProfile({ isClient,onClose}) {
  const [userProfile, setUserProfile] = React.useState({});
  const { userData, clientData } = useUserState();

  const clientCode = clientData?.code;
  const userCode = userData.code;
  const isADVISOR = userData.userType === "ADVISOR";
  const classes = useStyles();


  const getQusList = async () => {
    try {
      const reqUrl =
        isClient && isADVISOR
          ? `${ApiUri.profile.client}?cc=${clientCode}`
          : ApiUri.profile.user;

      let resData = await getRequest(reqUrl, {
        headers: {
          ["user-code"]: userCode,
        },
      });

      if (resData.status == 200) {
        const fieldValues = resData.data.data;

        const submitData = {
          emailId: fieldValues.userName,
          firstName: fieldValues.firstName,
          lastName: fieldValues.lastName,
          contactEmailAddress: fieldValues.userProfileDetails.email,
          dateOfBirth: fieldValues.dateOfBirth,
          zipCode: fieldValues.userProfileDetails?.shippingAddress?.zipCode,
          address: fieldValues.userProfileDetails?.shippingAddress?.address1,
          cityName: "",
          stateName: "",
          countryName: "",
          billingZip: fieldValues.userProfileDetails?.shippingAddress?.zipCode,
          billingAddress:
            fieldValues.userProfileDetails?.shippingAddress?.address1,
          billingCity: "",
          billingState: "",
          billingCountry: "",
          companyName: fieldValues.userProfileDetails?.companyName,
          arnNumber: fieldValues.userProfileDetails?.ariaCode,
          gstn: fieldValues.userProfileDetails?.gstin,
        };

        console.log("userProfile", submitData);

        setUserProfile(submitData);
      } else {
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getQusList();
  }, []);

  if (isEmpty(userProfile)) {
    return <></>;
  }
  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={true}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogContent className={classes.dialogContentSection}>
          <DialogTitle className={classes.dialogTitleSection}>
            <h1 style={{ margin: 0, fontSize: 30, color: "rgb(25, 63, 124)" }}>
              USER-PROFILE
            </h1>
            <div
              className={classes.underLineSection}
              style={{ width: "20%" }}
            >
              <div className={classes.underLine}></div>
              <span className={classes.dot}>.</span>
              <div className={classes.underLine}></div>
            </div>
            <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeButton}
        >
          <CloseIcon />
        </IconButton>
          </DialogTitle>
          <SignUpForm
            isEdit={true}
            isClient={isClient}
            userProfileData={userProfile}
            closeEditDilog={onClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default EditProfile;
