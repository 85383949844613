import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid } from "@material-ui/core";
import QuestionList from "../components/QuestionList";
import useStyles from "./../styles";
import ApiUri from "../../../constants/apiUri";
import { getRequest, postRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import { useSnackbar } from 'notistack';


export default function Question(props) {
  const setBackdrop = useContext(BackdropDispatchContext);
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState([]);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [qustionList, setQustionList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getQusList = async () => {
    setBackdrop(true);
    let reqData = await getRequest(
      `${ApiUri.attainmentWheel.qustionList}/${props.userData.code}`,
    );
    const newSelectedItem = [...selectedItem];
    if (reqData.status === 200) {
      reqData.data.data.categories.forEach((list) => {
        list.questions.forEach((v) => {
          newSelectedItem.push({
            questionCode: v.questionCode,
            resultScore: v.resultScore || 1,
          });
        });
      });
      setSelectedItem(newSelectedItem);
      setBackdrop(false);
      setQustionList(reqData.data.data.categories);
    } else {
    }
  };

  const onChange = (resultScore, questionCode) => {
    const newItem = [...selectedItem];
    let index = newItem.findIndex((v) => v.questionCode === questionCode);
    if (index === -1) {
      newItem.push({ resultScore, questionCode });
    } else {
      newItem[index] = { questionCode, resultScore };
    }
    // console.log("newItem",newItem)
    setSelectedItem(newItem);
  };

  const onSubmit = async () => {
    let data = {
      userAnswer: selectedItem,
      userCode: `${props.userData.code}`,
    };
    setBackdrop(true);
    setDisabledSubmit(true);
    let reqData = await postRequest(ApiUri.attainmentWheel.submitQustion, data);
    if (reqData.status === 200) {
      
      enqueueSnackbar("Data Save Successfully");
    } else {
      enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
      
    }
    setBackdrop(false);
    setDisabledSubmit(false);
  };

  useEffect(() => {
    getQusList();
  }, []);

  return (
    <>
      <Box style={{ position: "relative" }} p={3}>
        {qustionList.map((v) => {
          return (
            <QuestionList
              categoryId={v.code}
              defaultValue={""}
              type={v.displayName}
              icon={v.imageIcon}
              options={v.questions}
              // subHeading={v.displayName}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              onChange={onChange}
            />
          );
        })}
      </Box>

      <Box margin="auto" textAlign="center">
        <Grid container justify="space-evenly" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              component={Link}
              to={"/app/attainment-wheel/result"}
              className={classes.resultButton}
            >
              View Result
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              diabled={disabledSubmit}
              onClick={onSubmit}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
