import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  statusSection: {
    "& *": {
      // fontSize:12,
      // fontWeight:700
      color: "#fff",
      width: "75px",
      padding: "5px 0px",
      fontSize: "10px",
      textAlign: "center",
      fontWeight: 600,
      marginLeft: "4px",
    },
    "& span": {
      marginRight: 5,
    },
  },
  low: {
    "& *": {
      backgroundColor: `${theme.palette.red.main}`,
    },
  },
  fair: {
    "& *": {
      backgroundColor: `${theme.palette.orange.main}`,
    },
  },
  average: {
    "& *": {
      backgroundColor: `${theme.palette.yellow.main}`,
    },
  },
  good: {
    "& *": {
      backgroundColor: `${theme.palette.green.main}`,
    },
  },
  excellent: {
    "& *": {
      backgroundColor: `${theme.palette.blue.main}`,
    },
  },
  lowFill: {
    fill: theme.palette.red.main,
  },
  fairFill: {
    fill: theme.palette.orange.main,
  },
  averageFill: {
    fill: theme.palette.yellow.main,
  },
  goodFill: {
    fill: theme.palette.green.main,
  },
  excellentFill: {
    fill: theme.palette.blue.main,
  },
  categoriesName: {
    lineHeight: "20px",
    fontSize: "15px",
    color: "#fff",
    fontWeight: 600,
    textTransform: "uppercase",
  },
}));
