import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  InputAdornment,
  InputLabel,
  IconButton,
  Link,
} from "@material-ui/core";
import {
  PersonOutline as PersonOutlineIcon,
  LockOutlined as LockOutlinedIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@material-ui/icons";
import { withRouter,useLocation } from "react-router-dom";
import queryParams from "../../utils/queryParams";
import { Alert } from "@material-ui/lab";


// styles
import useStyles from "./styles";
import Footer from "../../components/Footer";
import PublicHeader from "../../components/PublicHeader";
import { getRequest, putRequest } from "../../utils/API";
import ApiUri from "../../constants/apiUri";

function Login(props) {
  var classes = useStyles();
  const location = useLocation();


 
  const {status,username} = queryParams(location.search);
  // local
  var [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({ value: "", type: "" });
  var [passwordValue, setPasswordValue] = useState("");
  var [confirmPassword, setConfirmPassword] = useState("");
  useEffect(() => {

    if(!status || !username)
    {
      setMessage({
        value: "Please try again, password reset link is expired or invalid",
        type: "error",
      });
      setIsLoading(true)
    }



  }, []);

  const reset = async () => {
    if(passwordValue == "" || confirmPassword == "")
    {
      setMessage({ value: "Please enter password and Confirm Password ", type: "error" });
      
    }
    else if(confirmPassword !== passwordValue)
    {
      setMessage({ value: "Password and Confirm Password are not same", type: "error" });
      
    }
    else {
      setIsLoading(true);
      const res = await putRequest(ApiUri.auth.passwordReset,
        {
          password: passwordValue,
          username: username
        },
        {
          "user-code": '' }
      );
      setPasswordValue("");
      setConfirmPassword("");
      setIsLoading(false);
      if (res.status == 200) {
        if (res.data?.data?.status) {
          setMessage({
            value: "Password Reset Successfully",
            type: "success",
          });
        } else {
          setMessage({
            value: res.data?.data?.message || "Please Try again",
            type: "error",
          });
        }
      } else {
        setMessage({ value: "Please Try again", type: "error" });
      }
    }
  };

  return (
    <Grid container className={classes.container}>
      <PublicHeader />
      <Grid item className={classes.mainLayout} xs={12}>
        <Grid container justify="flex-end">
          <Grid item xs={4} className={classes.formContainer}>
            <Typography variant="h2" className={classes.greeting}>
              Reset Password
            </Typography>
            <Typography variant="h6" className={classes.subGreeting}>
               Reset your password
            </Typography>
            {message.value ? (
              <Fade in={message.value}>
                <Alert severity={message.type}>{message.value}</Alert>
              </Fade>
            ) : (
              ""
            )}

            <div>
              <InputLabel
                htmlFor="component-helper"
                className={classes.inputFieldLable}
              >
                Password
              </InputLabel>

              <TextField
                id="password"
                className={classes.inputFieldStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                  style: { borderRadius: "50px" }
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                variant="outlined"
                type={ "password"}
                fullWidth
              />
            </div>
            <div>
              <InputLabel
                htmlFor="component-helper"
                className={classes.inputFieldLable}
              >
                Confirm Password
              </InputLabel>

              <TextField
                id="password"
                className={classes.inputFieldStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                  style: { borderRadius: "50px" },
                }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                margin="normal"
                placeholder="Confirm Password"
                variant="outlined"
                type={ "password"}
                fullWidth
              />
            </div>

            <div className={classes.formButtonSection}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  className={classes.formButton}
                  onClick={reset}
                  variant="contained"
                  size="large"
                  fullWidth
                  color="warning"
                >
                  Submit
                </Button>
              )}
            </div>

            <div className={classes.signupSection}>
              <Typography variant="pre">
                Go Back ?{" "}
                <Link
                  href="#/login"
                  color="primary"
                  size="large"
                  className={classes.signupButton}
                >
                 Login
                </Link>
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Footer hideLinks={true} />
    </Grid>
  );
}

export default withRouter(Login);
