import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Paper,
  ButtonGroup,
  Button,
  IconButton,
} from "@material-ui/core";
import { Add as AddIcon, Remove as RemoveIcon } from "@material-ui/icons";
import { DragPreviewImage } from "react-dnd";

// styles
import useStyles from "../../styles";
import "./style.css";
import TransitionItem from "./transitionItem";
import AddNewTransitionDialog from "./addNewTransitionDialog";
import Graph from "./graph";
const colors = {
  A004: "#fe8b32",
  A005: "#05b97a",
  A001: "#ff5351",
  A003: "#00b7d7",
  A002: "#fcc900",
};

// components
export default function Dashboard(props) {
  var classes = useStyles();
  const [year, setYear] = useState([]);
  const [activeQus, setActiveQus] = useState(0);
  const [openNewTransitionDilog, setOpenNewTransitionDilog] = useState({
    status: false,
    categoryCode: "",
    categoryName: "",
  });

  //false for short Term true for Long Term
  const [goalType, setGoalType] = useState(false);

  useEffect(() => {
    
    const { startAge, startYear, endYear  } = props.userDetails;
    const priorityMasters = props.priorityMasters.sort((a, b) =>
      a.priorityValue < b.priorityValue
        ? 1
        : b.priorityValue < a.priorityValue
        ? -1
        : 0,
    );
    const newYear = [...year];
    const newInitialBoard = [];
    const diff = endYear - startYear + 1;
    const { lifeTransitions } = props.userDetails;
    let [startingYear, startingAge] = [startYear, startAge];
    // console.log("lifeTransitions", startingYear, startingAge)
    while (startingYear <= endYear) {
      newYear.push(
        <>
          {startingYear}/<span>{startingAge}</span>
        </>,
      );

      startingAge++;
      startingYear++;
    }
    priorityMasters.forEach((e) => {
      newInitialBoard.push({
        ...e,
        row: new Array(diff).fill().map((u) => {
          return [];
        }),
      });
      const findRows = (lifeTransitions || []).filter(
        (v) => v.priorityCode === e.priorityCode,
      );
      if (findRows.length > 0) {
        findRows.forEach((v) => {
          const currentRow = newInitialBoard.length - 1;
          const currentColumn = v.transitionYear - startYear;
          newInitialBoard[currentRow].row[currentColumn].push({
            categoryCode: v.categoryCode,
            // imageIcon: v.iconUrl,
            imageIcon: v.categoryDisplayName,
            transitionCode: v.transitionCode,
            transitionName: v.transitionName,
            transitionYear: v.transitionYear,
          });
        });
      }
    });

    props.setInitialBoard(newInitialBoard);
    setYear(newYear);
  }, []);

  const onDelete = (data) => {
    const { priorityIndex, yearIndex, rowIndex } = data;
    const newInitialBoard = [...props.initialBoard];
    newInitialBoard[priorityIndex].row[yearIndex].splice([rowIndex], 1);
    props.setInitialBoard(newInitialBoard);
  };

  const onDrop = (item, dropResult) => {
    const newInitialBoard = [...props.initialBoard];
    let indexs = dropResult.name.split("_");
    const { startYear } = props.userDetails;
    item.transitionYear = parseInt(startYear) + parseInt(indexs[1]);

    newInitialBoard.forEach((v, i) => {
      const findRows = v.row[indexs[1]].findIndex((indexRow) => {
        return indexRow.transitionCode === item.transitionCode;
      });
      if (findRows !== -1) {
        newInitialBoard[i].row[indexs[1]].splice(findRows, 1);
      }
    });

    newInitialBoard[indexs[0]].row[indexs[1]].push(item);
    props.setInitialBoard(newInitialBoard);
  };

  return (
    <>
      {props.initialBoard.length > 0 && (
        <Box pt={1} pb={2}>
          <div className="life-line-planner">
            <ul>
              {props.qustion.map((v, i) => {
                return (
                  <li>
                    <label htmlFor="rad1">
                      <div>
                        <img
                          src={`static/images/life-transition/${v.displayName}.png`}
                          width="80%"
                          alt={v.displayName}
                        />

                        <div className="icon">
                          {activeQus !== i ? (
                            <IconButton
                              color="primary"
                              aria-label="add"
                              onClick={() => {
                                setActiveQus(i);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          ) : (
                            <IconButton
                              color="primary"
                              aria-label="add"
                              onClick={() => {
                                setActiveQus(0);
                              }}
                            >
                              <RemoveIcon />
                            </IconButton>
                          )}
                        </div>
                      </div>
                    </label>
                    {activeQus === i && (
                      <div className="accslide">
                        <div className="content">
                          <Typography
                            variant="h6"
                            component="h4"
                            className={`displayName${i}`}
                          >
                            {v.displayName}
                          </Typography>
                          <div className="transitionItem_flux">
                            {v.transitions.map((tran, i) => {
                              return (
                                <TransitionItem
                                  transitionCode={tran.transitionCode}
                                  transitionName={tran.transitionName}
                                  userAddedTransition={tran.userAddedTransition}
                                  type={v.displayName}
                                  categoryCode={v.categoryCode}
                                  // imageIcon={v.imageIcon}
                                  imageIcon={v.displayName}
                                  onDrop={onDrop}
                                  getQusList={props.getQusList}
                                />
                              );
                            })}
                            <div>
                              <DragPreviewImage
                                //data:image/png;base64,${props.imageIcon}
                                src={`static/images/life-transition/${v.displayName}.png`}
                              />
                              <Box
                                borderRadius="50%"
                                className="circle"
                                bgcolor="background.paper"
                                borderColor="#858585"
                                m={0.5}
                                border={1}
                              >
                                <div
                                  className="circle-text"
                                  onClick={() => {
                                    setOpenNewTransitionDilog({
                                      status: true,
                                      categoryCode: v.categoryCode,
                                      categoryName: v.displayName,
                                    });
                                  }}
                                >
                                  <AddIcon />
                                  <p style={{ margin: "0px" }}>ADD NEW</p>
                                </div>
                              </Box>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          <Box p={2}>
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="center"
            >
              <Grid item xs={3} style={{ textAlign: "start" }}>
                <ButtonGroup
                  color="primary"
                  aria-label="outlined primary button group"
                >
                  <Button
                    onClick={() => {
                      setGoalType(false);
                    }}
                    variant={!goalType && "contained"}
                    color={!goalType && "primary"}
                  >
                    Short Term
                  </Button>
                  <Button
                    onClick={() => {
                      setGoalType(true);
                    }}
                    variant={goalType && "contained"}
                    color={goalType && "primary"}
                  >
                    {" "}
                    Long Term
                  </Button>
                </ButtonGroup>
              </Grid>
              <Grid item xs={9}>
                {props.qustion.length > 0 && (
                  <Grid
                    container
                    direction="row"
                    justify="flex-end"
                    alignItems="center"
                    spacing={1}
                    className={classes.statusSection}
                  >
                    <Grid item>
                      <span> LEGENDS:</span>
                    </Grid>
                    {props.qustion.map((v) => {
                      return (
                        <Grid item>
                          <Paper
                            style={{ backgroundColor: colors[v.categoryCode] }}
                          >
                            {v.displayName}
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
          <div className="graph">
            <Graph
              qustion={props.qustion}
              initialBoard={props.initialBoard}
              yearData={year}
              onDelete={(data) => onDelete(data)}
              goalType={goalType}
              setGoalType={setGoalType}
              userDetails={props.userDetails}

            />
          </div>
        </Box>
      )}
      <AddNewTransitionDialog
        {...openNewTransitionDilog}
        getQusList={props.getQusList}
        setOpenNewTransitionDilog={setOpenNewTransitionDilog}
      />
    </>
  );
}
