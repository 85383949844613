import React, { createContext, useState } from "react";

const PageDataContext = createContext({ headerData: {}, submenuContainer: {} });
const PageDataDispatchContext = createContext({
  headerData: {},
  submenuContainer: {},
  footerData:{}
});

function PageDataProvider({ children }) {
  const [pageData, setPageData] = useState({
    headerData: {},
    submenuContainer: {},
    footerData:{}
  });

  return (
    <PageDataContext.Provider value={pageData}>
      <PageDataDispatchContext.Provider value={setPageData}>
        {children}
      </PageDataDispatchContext.Provider>
    </PageDataContext.Provider>
  );
}

export { PageDataProvider, PageDataContext, PageDataDispatchContext };
