import React, { useEffect, useState, useContext } from "react";
import { Box, Typography, Divider } from "@material-ui/core";
import SvgIcon from "../components/SvgIcon";
import PageTitle from "../../../components/PageTitle";
import ApiUri from "../../../constants/apiUri";
import { getRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import { PrintDataDispatchContext } from "../../../context/PrintDataContext";
import {DataNotFoundDispatchContext}  from '../../../context/DataNotFoundContext'
// import PrintDataComponent from '../../../PrintData/LifeIdealogyProfiler'
import {printSectionTypes} from "../../../constants/index"


const colorSection = {
  success: "red.main",
  failure: "orange.main",
  family: "blue.main",
  money: "green.main",
  career: "yellow.light",
};

const Answers = React.forwardRef((props, ref) => {
  const setBackdrop = useContext(BackdropDispatchContext);
  const setPrintData = useContext(PrintDataDispatchContext)
  const setDataNotFound = useContext(DataNotFoundDispatchContext);


  const [ansList, setAnsList] = useState([]);

  const getAnsList = async () => {
    setBackdrop(true);
    let reqData = await getRequest(
      `${ApiUri.lifeIdealogyProfiler.qustionList}/${props.userData.code}`,
    );
    if (reqData.status === 200) {
      setPrintData(printSectionTypes.lifeIdealogyProfiler)
      setBackdrop(false);
      setAnsList(reqData.data.data.lifeIdealogyProfiler);
    } else {
      setBackdrop(false);
      setDataNotFound(true);
    }
  };

  useEffect(() => {
    getAnsList();
  }, []);

  return (
    <>
      <Box component="div" ref={ref}>
        <Box component="div" textAlign="right" mr={7}></Box>
        <Box component="div" textAlign="center">
          <SvgIcon ansList={ansList} />
        </Box>

        <Box mt={5} pt={2} pb={2} bgcolor="#f8f8f8">
          <PageTitle title={"YOUR DEFINITIONS"} />
        </Box>

        <Box border={30} p={4} bgcolor="#ffff" borderColor="#dfdfdf">
          {ansList.map((v, i) => {
            return (
              <Box color={colorSection[v.code]} mb={2}>
                <Typography variant="h6" id={v.displayName}>
                  {v.name}
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  style={{ color: "#747474" }}
                >
                  <ul style={{ padding: "0px 15px", margin: "10px 0px" }}>
                    {v.userEntry &&
                      v.userEntry.entry.split("\n").map((str, i) => {
                        return str ? <li>{str}</li> : "";
                      })}
                  </ul>
                </Typography>

                <Divider style={{ backgroundColor: "#7e7e7e" }} />
              </Box>
            );
          })}
        </Box>
      </Box>
    </>
  );
});

export default Answers;
