import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
    passwordHelperIcon: {
        cursor: "grab"
    },
    passwordPopover: {
        backgroundColor: "#f2f2f2",
        width: "600px",
        height: "200px"
    },
    listItemText: {
        fontSize: "1.0em"
    },
    bulletIcon: {
        transform: "scale(0.6)"
    },
    listItem: {
        padding: 0,
        margin: 0
    },
    rulesHeading: {
        marginLeft: "10px",
        paddingTop: "10px"
    }
}));