import React, { useState } from "react";

export default function NegativeImpression(props) {
  let { negative, setNegative } = props;

  let select = (id, status) => {
    let newNegative = [...negative];
    newNegative[id].selected = !status;
    setNegative(newNegative);
  };
  return (
    <svg
      version="1.2"
      baseProfile="tiny-ps"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 992 514"
      width={992}
      height={514}
      className="print_svg"
    >
      <title>Negative copy</title>
      <style
        dangerouslySetInnerHTML={{
          __html:
            '\n\t\ttspan { white-space:pre }\n\t\t.NegativeImpression0 { fill: #767373 } \n\t\t.NegativeImpression1 { fill: #ffffff } \n\t\t.txt2 { font-size: 75px;fill: #ff5351;font-weight: bold;font-family: "Myriad Pro";text-anchor: middle } \n\t\t.NegativeImpression3 { fill: none;stroke: #ff5351;stroke-width: 4 } \n\t\t.NegativeImpression4 { fill: #e44c4a } \n\t\t.txt5 { font-size: 24px;line-height: 33px;fill: #ffffff;font-weight: 700;font-family: "Montserrat-Bold", "Montserrat";text-anchor: middle } \n\t\t.NegativeImpression6 { fill: #d7d7d7 } \n\t\t.txt7 { font-size: 24px;line-height: 33px;fill: #000000;font-weight: 700;font-family: "Montserrat-Bold", "Montserrat";text-anchor: middle } \n\t\t.txt8 { font-size: 24px;line-height: 33px;fill: #010101;font-weight: 700;font-family: "Montserrat-Bold", "Montserrat";text-anchor: middle } \n\t\t.txt9 { font-size: 24px;line-height: 34px;fill: #000000;font-weight: 700;font-family: "Montserrat-Bold", "Montserrat";text-anchor: middle } \n\t',
        }}
      />
      <g id="Negative copy">
        <g id="line">
          <path
            id="Rectangle 11 copy"
            className="NegativeImpression0"
            d="M265.8 308.69L733.79 211.35L734.2 213.31L266.21 310.65L265.8 308.69Z"
          />
          <path
            id="Rectangle 11 copy 2"
            className="NegativeImpression0"
            d="M307.49 402.63L691.32 117.76L692.51 119.37L308.68 404.24L307.49 402.63Z"
          />
          <path
            id="Rectangle 11 copy 3"
            className="NegativeImpression0"
            d="M390.03 473.2L608.19 47.89L609.97 48.8L391.81 474.11L390.03 473.2Z"
          />
          <path
            id="Rectangle 11 copy 4"
            className="NegativeImpression0"
            d="M608.57 473.92L389.65 49L391.43 48.08L610.35 473L608.57 473.92Z"
          />
          <path
            id="Rectangle 11 copy 5"
            className="NegativeImpression0"
            d="M691.16 404.46L307.65 119.15L308.84 117.54L692.35 402.85L691.16 404.46Z"
          />
          <path
            id="Rectangle 11 copy 6"
            className="NegativeImpression0"
            d="M733.74 310.86L265.85 213.1L266.26 211.14L734.15 308.9L733.74 310.86Z"
          />
        </g>
        <path
          id="Ellipse 1"
          className="NegativeImpression0"
          d="M498.26 106.83C581.87 106.83 649.64 174.71 649.64 258.45C649.64 342.19 581.87 410.08 498.26 410.08C414.66 410.08 346.88 342.19 346.88 258.45C346.88 174.71 414.66 106.83 498.26 106.83Z"
        />
        <path
          id="Ellipse 1 copy"
          className="NegativeImpression1"
          d="M498.5 112C578.86 112 644 177.14 644 257.5C644 337.86 578.86 403 498.5 403C418.14 403 353 337.86 353 257.5C353 177.14 418.14 112 498.5 112Z"
        />
        <text
          id="Negative ImpressionS"
          style={{ transform: "matrix(0.546,0,0,0.546,497.921,247.069)" }}
        >
          <tspan x={0} y={0} className="txt2">
            NEGATIVE
          </tspan>
          <tspan x={0} y={84} className="txt2">
            IMPRESSIONS
          </tspan>
        </text>
        <g id="small circle">
          <path
            id="Ellipse 2"
            className="NegativeImpression3"
            d="M498.44 70.86C601.63 70.86 685.28 154.64 685.28 258C685.28 361.36 601.63 445.14 498.44 445.14C395.25 445.14 311.6 361.36 311.6 258C311.6 154.64 395.25 70.86 498.44 70.86Z"
          />
          <path
            id="Ellipse 3 copy 12"
            className="NegativeImpression0"
            d="M585.63 415.11C591.65 415.11 596.53 420 596.53 426.03C596.53 432.06 591.65 436.95 585.63 436.95C579.61 436.95 574.74 432.06 574.74 426.03C574.74 420 579.61 415.11 585.63 415.11Z"
          />
          <path
            id="Ellipse 3 copy 12"
            className="NegativeImpression0"
            d="M413.98 415.11C420 415.11 424.88 420 424.88 426.03C424.88 432.06 420 436.95 413.98 436.95C407.97 436.95 403.09 432.06 403.09 426.03C403.09 420 407.97 415.11 413.98 415.11Z"
          />
          <path
            id="Ellipse 3 copy 6"
            className="NegativeImpression0"
            d="M649.22 359.85C655.24 359.85 660.11 364.74 660.11 370.77C660.11 376.8 655.24 381.69 649.22 381.69C643.2 381.69 638.33 376.8 638.33 370.77C638.33 364.74 643.2 359.85 649.22 359.85Z"
          />
          <path
            id="Ellipse 3 copy 6"
            className="NegativeImpression0"
            d="M350.39 359.85C356.41 359.85 361.29 364.74 361.29 370.77C361.29 376.8 356.41 381.69 350.39 381.69C344.38 381.69 339.5 376.8 339.5 370.77C339.5 364.74 344.38 359.85 350.39 359.85Z"
          />
          <path
            id="Ellipse 3 copy 8"
            className="NegativeImpression0"
            d="M682.57 289C688.59 289 693.47 293.89 693.47 299.92C693.47 305.95 688.59 310.84 682.57 310.84C676.55 310.84 671.68 305.95 671.68 299.92C671.68 293.89 676.55 289 682.57 289Z"
          />
          <path
            id="Ellipse 3 copy 8"
            className="NegativeImpression0"
            d="M317.04 289C323.06 289 327.94 293.89 327.94 299.92C327.94 305.95 323.06 310.84 317.04 310.84C311.03 310.84 306.15 305.95 306.15 299.92C306.15 293.89 311.03 289 317.04 289Z"
          />
          <path
            id="Ellipse 3 copy 9"
            className="NegativeImpression0"
            d="M682.57 213.24C688.59 213.24 693.47 218.13 693.47 224.16C693.47 230.19 688.59 235.08 682.57 235.08C676.55 235.08 671.68 230.19 671.68 224.16C671.68 218.13 676.55 213.24 682.57 213.24Z"
          />
          <path
            id="Ellipse 3 copy 9"
            className="NegativeImpression0"
            d="M317.04 213.24C323.06 213.24 327.94 218.13 327.94 224.16C327.94 230.19 323.06 235.08 317.04 235.08C311.03 235.08 306.15 230.19 306.15 224.16C306.15 218.13 311.03 213.24 317.04 213.24Z"
          />
          <path
            id="Ellipse 3 copy 10"
            className="NegativeImpression0"
            d="M649.22 137.93C655.24 137.93 660.11 142.82 660.11 148.85C660.11 154.88 655.24 159.77 649.22 159.77C643.2 159.77 638.33 154.88 638.33 148.85C638.33 142.82 643.2 137.93 649.22 137.93Z"
          />
          <path
            id="Ellipse 3 copy 10"
            className="NegativeImpression0"
            d="M350.39 137.93C356.41 137.93 361.29 142.82 361.29 148.85C361.29 154.88 356.41 159.77 350.39 159.77C344.38 159.77 339.5 154.88 339.5 148.85C339.5 142.82 344.38 137.93 350.39 137.93Z"
          />
          <path
            id="Ellipse 3 copy 11"
            className="NegativeImpression0"
            d="M585.63 81.78C591.65 81.78 596.53 86.67 596.53 92.7C596.53 98.73 591.65 103.62 585.63 103.62C579.61 103.62 574.74 98.73 574.74 92.7C574.74 86.67 579.61 81.78 585.63 81.78Z"
          />
          <path
            id="Ellipse 3 copy 11"
            className="NegativeImpression0"
            d="M413.98 81.78C420 81.78 424.88 86.67 424.88 92.7C424.88 98.73 420 103.62 413.98 103.62C407.97 103.62 403.09 98.73 403.09 92.7C403.09 86.67 407.97 81.78 413.98 81.78Z"
          />
        </g>
        <g
          id={1}
          style={{ cursor: "pointer" }}
          onClick={() => select(0, negative[0]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[0]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M174 0L387 0C403.02 0 416 12.98 416 29L416 30C416 46.02 403.02 59 387 59L174 59C157.98 59 145 46.02 145 30L145 29C145 12.98 157.98 0 174 0Z"
          />
          <text
            id="Boring & Inactive lifestlye"
            style={{ transform: "matrix(0.525,0,0,0.586,280.372,33.795)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,280.372,33.795)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[0]?.selected ? "txt5" : "txt7"}
            >
              {negative[0]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={2}
          style={{ cursor: "pointer" }}
          onClick={() => select(1, negative[1]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[1]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M78 91L291 91C307.02 91 320 103.98 320 120L320 121C320 137.02 307.02 150 291 150L78 150C61.98 150 49 137.02 49 121L49 120C49 103.98 61.98 91 78 91Z"
          />
          <text
            id="Concerns about money"
            style={{ transform: "matrix(0.525,0,0,0.586,184.372,124.795)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,184.372,124.795)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[1]?.selected ? "txt5" : "txt7"}
            >
              {negative[1]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={3}
          style={{ cursor: "pointer" }}
          onClick={() => select(2, negative[2]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[2]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M29 182L242 182C258.02 182 271 194.98 271 211L271 212C271 228.02 258.02 241 242 241L29 241C12.98 241 0 228.02 0 212L0 211C0 194.98 12.98 182 29 182Z"
          />
          <text
            id="Feeling isolated"
            style={{ transform: "matrix(0.525,0,0,0.586,135.372,215.795)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,135.372,215.795)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[2]?.selected ? "txt5" : "txt7"}
            >
              {negative[2]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={4}
          style={{ cursor: "pointer" }}
          onClick={() => select(3, negative[3]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[3]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M29 273L242 273C258.02 273 271 285.98 271 302L271 303C271 319.02 258.02 332 242 332L29 332C12.98 332 0 319.02 0 303L0 302C0 285.98 12.98 273 29 273Z"
          />
          <text
            id="Health Problems"
            style={{ transform: "matrix(0.525,0,0,0.586,135.372,308.795)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,135.372,308.795)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[3]?.selected ? "txt5" : "txt7"}
            >
              {negative[3]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={5}
          style={{ cursor: "pointer" }}
          onClick={() => select(4, negative[4]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[4]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M78 364L291 364C307.02 364 320 376.98 320 393L320 394C320 410.02 307.02 423 291 423L78 423C61.98 423 49 410.02 49 394L49 393C49 376.98 61.98 364 78 364Z"
          />
          <text
            id="Heavy Medical Bills"
            style={{ transform: "matrix(0.525,0,0,0.586,184.372,397.795)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,184.372,397.795)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[4]?.selected ? "txt5" : "txt7"}
            >
              {negative[4]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={6}
          style={{ cursor: "pointer" }}
          onClick={() => select(5, negative[5]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[5]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M174 455L387 455C403.02 455 416 467.98 416 484L416 485C416 501.02 403.02 514 387 514L174 514C157.98 514 145 501.02 145 485L145 484C145 467.98 157.98 455 174 455Z"
          />
          <text
            id="Identity Loss"
            style={{ transform: "matrix(0.525,0,0,0.586,280.372,488.795)" }}
            // style={{ transform: "matrix(0.546,0,0,0.546,280.372,488.795)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[5]?.selected ? "txt5" : "txt8"}
            >
              {negative[5]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={7}
          style={{ cursor: "pointer" }}
          onClick={() => select(6, negative[6]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[6]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M605 0L818 0C834.02 0 847 12.98 847 29L847 30C847 46.02 834.02 59 818 59L605 59C588.98 59 576 46.02 576 30L576 29C576 12.98 588.98 0 605 0Z"
          />
          <text
            id="Unhealthy family relationships"
            style={{ transform: "matrix(0.525,0,0,0.574,711.058,33.948)" }}
            // style={{ transform: "matrix(0.544,0,0,0.534,711.058,33.948)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[6]?.selected ? "txt5" : "txt9"}
            >
              {negative[6]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={8}
          style={{ cursor: "pointer" }}
          onClick={() => select(7, negative[7]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[7]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M701 91L914 91C930.02 91 943 103.98 943 120L943 121C943 137.02 930.02 150 914 150L701 150C684.98 150 672 137.02 672 121L672 120C672 103.98 684.98 91 701 91Z"
          />
          <text
            id="Not following any Hobby / Activity"
            style={{ transform: "matrix(0.525,0,0,0.58,806.5,125.106)" }}
            // style={{ transform: "matrix(0.545,0,0,0.54,806.5,125.106)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[7]?.selected ? "txt5" : "txt8"}
            >
              {negative[7]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={9}
          style={{ cursor: "pointer" }}
          onClick={() => select(8, negative[8]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[8]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M750 182L963 182C979.02 182 992 194.98 992 211L992 212C992 228.02 979.02 241 963 241L750 241C733.98 241 721 228.02 721 212L721 211C721 194.98 733.98 182 750 182Z"
          />
          <text
            id="Lack of social engagements"
            style={{ transform: "matrix(0.525,0,0,0.58,855.714,216.106)" }}
            // style={{ transform: "matrix(0.546,0,0,0.54,855.714,216.106)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[8]?.selected ? "txt5" : "txt8"}
            >
              {negative[8]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={10}
          style={{ cursor: "pointer" }}
          onClick={() => select(9, negative[9]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[9]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M750 273L963 273C979.02 273 992 285.98 992 302L992 303C992 319.02 979.02 332 963 332L750 332C733.98 332 721 319.02 721 303L721 302C721 285.98 733.98 273 750 273Z"
          />
          <text
            id="Trouble adjusting to retirement phase"
            style={{ transform: "matrix(0.525,0,0,0.58,856.714,307.116)" }}
            // style={{ transform: "matrix(0.547,0,0,0.54,856.714,305.106)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[9]?.selected ? "txt5" : "txt8"}
            >
              {negative[9]?.displayName}
            </tspan>
            <tspan x={0} y="26.647" className="txt5"></tspan>
          </text>
        </g>
        <g
          id={11}
          style={{ cursor: "pointer" }}
          onClick={() => select(10, negative[10]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[10]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M701 364L914 364C930.02 364 943 376.98 943 393L943 394C943 410.02 930.02 423 914 423L701 423C684.98 423 672 410.02 672 394L672 393C672 376.98 684.98 364 701 364Z"
          />
          <text
            id="Living a Vision less life"
            style={{ transform: "matrix(0.525,0,0,0.58,807.215,398.106)" }}
            // style={{ transform: "matrix(0.544,0,0,0.54,807.215,398.106)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[10]?.selected ? "txt5" : "txt8"}
            >
              {negative[10]?.displayName}
            </tspan>
          </text>
        </g>
        <g
          id={12}
          style={{ cursor: "pointer" }}
          onClick={() => select(11, negative[11]?.selected)}
        >
          <path
            id="Rounded Rectangle 14 copy"
            className={
              negative[11]?.selected
                ? "NegativeImpression4"
                : "NegativeImpression6"
            }
            d="M605 455L818 455C834.02 455 847 467.98 847 484L847 485C847 501.02 834.02 514 818 514L605 514C588.98 514 576 501.02 576 485L576 484C576 467.98 588.98 455 605 455Z"
          />
          <text
            id="Financial Dependence on others"
            style={{ transform: "matrix(0.525,0,0,0.58,711,489.106)" }}
            // style={{ transform: "matrix(0.543,0,0,0.54,711,489.106)" }}
          >
            <tspan
              x={0}
              y={0}
              className={negative[11]?.selected ? "txt5" : "txt8"}
            >
              {negative[11]?.displayName}
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
}
