import React, { useEffect, useState } from "react";
import {
  Grid,
  CircularProgress,
  Backdrop,
  Typography,
  Button,
  TextField,
  Fade,
  InputAdornment,
  InputLabel,
  IconButton,
  Link,
} from "@material-ui/core";
import {
  PersonOutline as PersonOutlineIcon,
  LockOutlined as LockOutlinedIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
  Error as ErrorIcon
} from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import { postRequest, getRequest } from "../../utils/API";
import ApiUri from "../../constants/apiUri";

// styles
import useStyles from "./styles";


// logo

import Footer from "../../components/Footer/Footer";
import PublicHeader from '../../components/PublicHeader'

// context
import { useUserDispatch, loginUser } from "../../context/UserContext";
import queryParams from "../../utils/queryParams";
import Loader from "./loader";

function Paymentresponse(props) {
  const classes = useStyles();
  const history = useHistory();
  const status = {
    inProgress: "inProgress",
    success: "success",
    failed: "failed",
  };

  const location = useLocation();
  const [currentStatus, setCurrentStatus] = useState(status.inProgress);
  const [paymentDetails, setPaymentDetails] = useState();
  const params = queryParams(location.search);

  const paymentReqId = async () => {
    let data = {
      paymentRequestId: params.payment_request_id,
    };
    let reqData = await postRequest(ApiUri.payment.response, data);
    if (reqData.status == 200) {
      setPaymentDetails(reqData.data.data.payment_request);
      setCurrentStatus(status.success);
      // setTimeout(() => {
      //   clearInterval(timerInterval);
      //   const paymentResponse = reqData.data.data.paymentResponse;
      //   console.log("reqData", paymentResponse);
      //   history.push(
      //     `/signUp?pid=${params.payment_id}&eid=${btoa(paymentResponse.email)}`,
      //   );
      // }, 3000);
    } else {
      alert("payment failed");
    }
  };
  useEffect(() => {
    if(params?.payment_status == "Credit" || params?.payment_status == "completed")
    {
      setCurrentStatus(status.success)
    }
    else{
      setCurrentStatus(status.failed)
    }
   // paymentReqId();
  }, []);
  return (
    <>
      <Grid container className={classes.container}>
        <PublicHeader />
        <Grid item className={classes.mainLayout} xs={12}>
          {currentStatus == status.failed && (
            <Grid container justify="flex-end">
              <Grid item xs={12}>
                <ErrorIcon style={{fontSize:"100px",color:"#e30614"}} />
                <Typography component="h1" variant="h2">
                   Your Payment is failed
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="h2">
                Your payment was not successfully processed. Please contact our customer support.
                 </Typography>
              </Grid>
            </Grid>
          )}
          {currentStatus == status.success && (
            <Grid container justify="flex-end">
              <Grid item xs={12}>
                <img src="static/images/payment-completed.webp"  width="400"/>
                <Typography component="h2" variant="h4">
                  Your Payment is Successful
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography component="h4">
                To complete your registration, click on the link provided to you in the welcome email sent
                </Typography>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          )}
        </Grid>

        <Footer hideLinks={true} />
      </Grid>
    </>
  );
}

export default Paymentresponse;
