import React, { createContext, useState } from "react";
import {
    Box, Button, Grid,Backdrop,CircularProgress
  } from "@material-ui/core";
  import { makeStyles } from '@material-ui/core/styles';


const BackdropContext = createContext(false);
const BackdropDispatchContext = createContext(false);
const useStyles = makeStyles((theme) => ({
    backdropStyle: {
      zIndex: 1024,
      color: '#000',
      backgroundColor:"#ffffffa1"
    },
  }));
function BackdropProvider({ children }) {
  const [backdrop, setBackdrop] = useState(false);
  const classes = useStyles();

  return (
    <BackdropContext.Provider value={backdrop}>
      <BackdropDispatchContext.Provider value={setBackdrop}>
      <Backdrop className={classes.backdropStyle} open={backdrop}>
<CircularProgress color="inherit" />

</Backdrop>
{children}
      </BackdropDispatchContext.Provider>
    </BackdropContext.Provider> 
  );
}

export { BackdropProvider, BackdropContext, BackdropDispatchContext };