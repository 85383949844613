import React, { useState, useEffect, useContext } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  InputBase,
  Menu,
  MenuList,
  MenuItem,
  Grid,
  ClickAwayListener,
  Popper,
  Paper,
  Grow,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import {
  PersonOutlineOutlined as AccountIcon,
  Language as LanguageIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@material-ui/icons";
import { getRequest, postRequest } from "./../../utils/API";
import ApiUri from "./../../constants/apiUri";
import classNames from "classnames";
import Profile from "./profile";

// styles
import useStyles from "./styles";

// components
import { Typography, Button } from "../Wrappers";
import {
  useUserDispatch,
  signOut,
  useUserState,
} from "../../context/UserContext";
import { PageDataContext } from "../../context/PageDataContext";
import { EditProfileDispatchContext } from "../../context/EditProfileContext";
import { Link } from "react-router-dom";

export default function Header(props) {
  var classes = useStyles();

  const { userData } = useUserState();
  var userDispatch = useUserDispatch();
  const { headerData } = useContext(PageDataContext);
  const editProfile = useContext(EditProfileDispatchContext);

  var [profileMenu, setProfileMenu] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [language, setLanguage] = React.useState("");
  const anchorRef = React.useRef(null);
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = (props) => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setLanguage(event.target.value);
  };
  // const getQusList = async () => {
  //   try {
  //     let resData = await getRequest(ApiUri.profile.user);
  //     if (resData.status == 200) {
  //       const data = resData.data.data;
  //       setUserProfile(data);
  //     } else {
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };
  // useEffect(() => {
  //   getQusList();
  // }, []);

  const backgroundImage = headerData?.headerImage
    ? `url(data:image/png;base64,${headerData?.headerImage})`
    : "";
  return (
    <>
      {open && (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <DialogContentText>
              <b>Language</b> : English
            </DialogContentText>
            <FormControl variant="standard" style={{ width: "100%" }}>
              <InputLabel id="demo-simple-select-standard-label">
                Select Language
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={language}
                onChange={handleChange}
                label="Select Language"
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="english">English</MenuItem>
                <MenuItem value="hindi">Hindi</MenuItem>
                <MenuItem value="germany">Germany</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
      <AppBar position="absolute" className={classes.appBar}>
        <Grid
          item
          className={classes.header}
          xs={12}
          style={{ backgroundImage: backgroundImage }}
        >
          <Link to="/">
            <img src="logo.png" className={classes.headerImg} />
          </Link>
        </Grid>
        <Toolbar className={classes.toolbar}>
          <div className={classes.grow} />
          <IconButton
            aria-haspopup="true"
            color="inherit"
            className={classes.headerMenuButton}
            aria-controls="profile-menu"
            onClick={(e) => setProfileMenu(e.currentTarget)}
          >
            <AccountIcon classes={{ root: classes.headerIcon }} />
            <ArrowDropDownIcon classes={{ root: classes.dropheaderIcon }} />
          </IconButton>
          <Popper
            open={Boolean(profileMenu)}
            anchorEl={anchorRef}
            role={undefined}
            transition
            disablePortal
            className={classes.headerMenu}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "left top" : "left bottom",
                  position: "absolute",
                  marginYop: "-12px",
                  width: "190px",
                  right: "-110px",
                  top: "-20px",
                }}
              >
                <Paper classesName={classes.profileMenu}>
                  <ClickAwayListener onClickAway={() => setProfileMenu(null)}>
                    <MenuList id="profile-menu" disableAutoFocusItem>
                      <div className={classes.profileMenuUser}>
                        <Typography variant="h4" weight="medium">
                          {userData?.firstName} {userData?.lastName}
                        </Typography>
                      </div>
                      <MenuItem
                        className={classNames(
                          classes.profileMenuItem,
                          classes.headerMenuItem,
                        )}
                        onClick={() => {
                          editProfile({
                            show: true,
                            isClient:
                              userData.userType == "ADVISOR" ? false : true,
                          });
                        }}
                      >
                        <AccountIcon className={classes.profileMenuIcon} />
                        Profile
                      </MenuItem>
                      {/* <MenuItem
                        className={classNames(
                          classes.profileMenuItem,
                          classes.headerMenuItem,
                        )}
                        onClick={handleClickOpen}
                      >
                        <LanguageIcon className={classes.profileMenuIcon} />{" "}
                        Language
                      </MenuItem> */}

                      <div className={classes.profileMenuUser}>
                        <Typography
                          className={classes.profileMenuLink}
                          color="primary"
                          onClick={() => signOut(userDispatch, props.history)}
                        >
                          Sign Out
                        </Typography>
                      </div>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Toolbar>
      </AppBar>
    </>
  );
}
