
export const signUpText = {
    mainHeadingForAdvisor:"Please Complete the following information to register as an LUM Advisor",
    subHeading:"Please select and email id and password to register/login to LUM Advisor Console",
    subHeadingForClientregister:"Please select and email id and password to register/login to LUM Client Console"

}


export const printSectionTypes = {
    finView: "FINVIEW",
    attainmentWheel: "ATTAINMENTWHEEL",
    lifeIdealogyProfiler: "LIFEIDEALOGYPROFILER",
    lifeline: "LIFELINE",
    mpw: "MPW",
    retireMeant: "RETIREMEANT",
    wrs: "WRS",
    fullReport:"FULLREPORT"
  };