import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Modal, Button } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { deleteRequest } from "./../../../../utils/API";
import ApiUri from "./../../../../constants/apiUri";
import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function DeleteModal({
  transitionCode,
  transitionName,
  transitionYear,
  setDisplayEdit,
  setNewComment,
  setBackdrop,
  setOpen,
  open,
  deleteType = "comment",
  updateTransition,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();


  const handleClose = () => {
    setOpen(false);
  };

  const deleteData = async () => {
    setBackdrop(true);

    const url =
      ApiUri.lifeline[
        deleteType === "comment" ? "commentDelete" : "deleteUserTransition"
      ];
    const data = { transitionCode };
    if (deleteType === "comment") {
      data.transitionYear = transitionYear;
    }
    let resData = await deleteRequest(url, {
      data,
    });
    if (resData.status === 200) {
      if (deleteType === "comment") {
         enqueueSnackbar("Transition comment Deleted Successfully");
         setNewComment("")
         setDisplayEdit(false);
      } else {
        enqueueSnackbar("Transition remove Successfully");
        updateTransition();
      }

      handleClose();
    } else {
      enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
    }
    setBackdrop(false);
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <p id="transition-modal-description">
              {deleteType === "comment"
                ? "Do you want to remove the comment from this transition?"
                : `Do you want to remove '${transitionName}' transition?`}
            </p>
            <div>
              <Button onClick={deleteData}>Yes</Button>
              <Button onClick={handleClose}>N0</Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
