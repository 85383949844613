import React from "react";
import useStyles from "./../styles";
import { backgroundPath, getScoreClass, circleTransform } from "./config";
import { Link } from "react-scroll";

import "./style.css";
export default function PieChart(props) {
  const classes = useStyles();
  const { selectedResult, totalScore } = props;
  return (
    <>
      {selectedResult.length > 0 && (
        <svg
          version="1.2"
          baseProfile="tiny-ps"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1017 828"
          width={800}
          height={600}
          className="print_svg"
        >
          <defs>
            <g id="img1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="764.667px"
                height="759.599px"
                viewBox="0 0 764.667 759.599"
                enableBackground="new 0 0 764.667 759.599"
                xmlSpace="preserve"
              >
                {selectedResult.map(
                  ({ totalCategoryScore, categoriesName }, i) => {
                    return (
                      <path
                        className={classes[getScoreClass(totalCategoryScore)]}
                        d={backgroundPath[i]}
                        index={i}
                        categoriesName={categoriesName}
                      />
                    );
                  },
                )}
              </svg>
            </g>
            <linearGradient
              id="grd1"
              gradientUnits="userSpaceOnUse"
              x1="324.999"
              y1="415.499"
              x2="691.999"
              y2="415.499"
            >
              <stop offset={0} stopColor="#000000" />
              <stop offset={1} stopColor="#ffffff" />
            </linearGradient>
            <linearGradient
              id="grd2"
              gradientUnits="userSpaceOnUse"
              x1="436.774"
              y1="536.999"
              x2="578.225"
              y2="291.999"
            >
              <stop offset={0} stopColor="#c4c4c4" />
              <stop offset={1} stopColor="#ffffff" />
            </linearGradient>
            <radialGradient
              id="grd3"
              gradientUnits="userSpaceOnUse"
              cx="507.499"
              cy="414.499"
              r="115.8"
            >
              <stop offset={0} stopColor="#f5f5f5" />
              <stop offset={1} stopColor="#b9b9b9" />
            </radialGradient>
          </defs>
          <use
            id="circle (1)"
            href="#img1"
            transform="matrix(1,0,0,1,125,34)"
          />
          <path
            id="Ellipse 4"
            className={classes.shp0}
            d="M508.5 232C609.84 232 692 314.15 692 415.5C692 516.84 609.84 599 508.5 599C407.16 599 325 516.84 325 415.5C325 314.15 407.16 232 508.5 232Z"
          />
          <path
            id="Ellipse 4 copy"
            className={classes.shp1}
            d="M507.5 292C575.15 292 630 346.84 630 414.5C630 482.15 575.15 537 507.5 537C439.84 537 385 482.15 385 414.5C385 346.84 439.84 292 507.5 292Z"
          />
          <path
            id="Ellipse 4 copy 2"
            className={classes.shp2}
            d="M507.5 318C560.79 318 604 361.2 604 414.5C604 467.79 560.79 511 507.5 511C454.2 511 411 467.79 411 414.5C411 361.2 454.2 318 507.5 318Z"
          />
          <g>
            {selectedResult.map(({ categoriesName }, i) => {
              return (
                <>
                <Link to={categoriesName} smooth style={{ cursor: "pointer" }}> 
                  <foreignObject
                    x={circleTransform[i]?.name.x}
                    y={circleTransform[i]?.name.y}
                    width="180"
                    height="50"
                  >
                    <div className={classes.categoriesName}>
                      {categoriesName}
                    </div>
                  </foreignObject>
                  <image
                    width={58}
                    height={55}
                    transform={circleTransform[i]?.image}
                    href={`static/images/attainment_icon/${categoriesName
                      .replace(/ /g, "_")
                      .toLowerCase()}.png`}
                  />
                  </Link>
                </>
              );
            })}
          </g>
          <path
            id="Ellipse 1"
            className={classes.shp4}
            d="M502.47 434.88C536.97 434.88 564.94 444.54 564.94 456.44C564.94 468.35 536.97 478 502.47 478C467.97 478 440 468.35 440 456.44C440 444.54 467.97 434.88 502.47 434.88Z"
          />
          <path
            id="Ellipse 1 copy"
            className={classes.shp5}
            d="M502.47 438.74C530.8 438.74 553.76 446.67 553.76 456.44C553.76 466.22 530.8 474.14 502.47 474.14C474.14 474.14 451.17 466.22 451.17 456.44C451.17 446.67 474.14 438.74 502.47 438.74Z"
          />
          <path
            id="Ellipse 1 copy 2"
            className={classes.shp4}
            d="M502.98 441.9C526.54 441.9 545.64 448.49 545.64 456.62C545.64 464.75 526.54 471.34 502.98 471.34C479.41 471.34 460.31 464.75 460.31 456.62C460.31 448.49 479.41 441.9 502.98 441.9Z"
          />
          <path
            id="Ellipse 1 copy 3"
            className={classes.shp4}
            d="M502.98 445.4C520.93 445.4 535.48 450.34 535.48 456.44C535.48 462.54 520.93 467.48 502.98 467.48C485.02 467.48 470.47 462.54 470.47 456.44C470.47 450.34 485.02 445.4 502.98 445.4Z"
          />
          <path
            id="Ellipse 1 copy 4"
            className={classes.shp5}
            d="M503.48 448.2C516.67 448.2 527.35 451.81 527.35 456.27C527.35 460.72 516.67 464.33 503.48 464.33C490.3 464.33 479.61 460.72 479.61 456.27C479.61 451.81 490.3 448.2 503.48 448.2Z"
          />
          <path
            id="Ellipse 1 copy 5"
            className={classes.shp4}
            d="M502.98 451.36C510.83 451.36 517.2 453.56 517.2 456.27C517.2 458.98 510.83 461.17 502.98 461.17C495.12 461.17 488.76 458.98 488.76 456.27C488.76 453.56 495.12 451.36 502.98 451.36Z"
          />
          <path
            id="Rectangle 1"
            className={classes.shp4}
            d="M499.93 354.82L506.02 354.82L506.02 457.47L499.93 457.47L499.93 354.82Z"
          />
          <path
            id="Shape 1"
            className={classes.shp4}
            d="M508.05 355.84C508.05 355.84 529.39 349 541.57 356.87L539.54 395.88C539.54 395.88 518.89 385.61 508.05 396.9L508.05 355.84Z"
          />
          <path
            id="Shape 2"
            className={classes.shp4}
            d="M527.35 394.85L542.59 399.98L544.62 366.11C544.62 366.11 560.54 368.16 569 360.97L569 402.04C569 402.04 543.61 409.22 528.37 399.98L527.35 394.85Z"
          />
          <path
            id="Shape 3"
            className={classes.shp4}
            d="M478.6 393.82L478.6 462.6C478.6 462.6 478.26 466.71 474.54 466.71C470.81 466.71 471.49 462.6 471.49 462.6L471.49 429.75L465.39 429.75L465.39 462.6C465.39 462.6 465.39 466.71 462.35 466.71C459.3 466.71 458.28 462.6 458.28 462.6L458.28 395.88C458.28 395.88 456.59 394.17 456.25 395.88L456.25 422.57C456.25 422.57 455.24 426.67 452.19 426.67C449.14 426.67 449.14 422.57 449.14 422.57L449.14 399.98C449.14 399.98 447.79 386.98 461.33 386.64C474.87 386.3 506.02 386.64 506.02 386.64L506.02 393.82L478.6 393.82Z"
          />
          <path
            id="Ellipse 2"
            className={classes.shp4}
            d="M468.44 364.05C473.49 364.05 477.58 368.19 477.58 373.29C477.58 378.4 473.49 382.53 468.44 382.53C463.39 382.53 459.3 378.4 459.3 373.29C459.3 368.19 463.39 364.05 468.44 364.05Z"
          />

          <text id={50} style={{ transform: "translate(508px, 413px)" }}>
            <tspan x="-46.224" y={0} className={classes.totalScore} dx="0 1 1">
              {totalScore}
            </tspan>
          </text>
          <text id={50} style={{ transform: "translate(506px, 480px)" }}>
            <tspan x="-46.224" y={0} className={classes.navText} dx="0 1 1">
              NAV
            </tspan>
          </text>

          {/* <path
            d="M100,100 a100,100 0 0,1 200,0"
            id="curv12"
            className={classes.navPath}
          ></path>
          <text text-anchor="middle" dy="40">
            <textPath
              className={classes.navText}
              startOffset="50%"
              href="#curv12"
            >
              NAV
            </textPath>
          </text> */}
        </svg>
      )}
    </>
  );
}
