import React from "react";
import {
  Grid,
  Link,
} from "@material-ui/core";
import useStyles from "./styles";



function PublicHeader() {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item className={classes.header} xs={12}>
          <Link href="#/">
            <img src="logo.png" className={classes.headerImg} alt="logo" />
          </Link>
        </Grid>
      </Grid>
    </>
  );
}

export default PublicHeader;