import Axios from "axios";
import { getUserData } from "../../context/UserContext";

const tokenData = "1234556788";

const getheaders = (headers, secure) => {
  const { clientData, userData, isAuthenticated } = getUserData();
  headers =
    typeof headers !== "undefined" && Object.keys(headers).length
      ? headers
      : {};
  if (secure) {
    headers.Authorization = `Bearer ${tokenData}`;
  }
  if (isAuthenticated && !headers["user-code"]) {
     const userCode = clientData?clientData : userData
    headers["user-code"] = userCode.code;
    
  }
  headers["language-code"] = "en";
  return headers;
};

const getErorResponse = (error) => {
  if (!error.response?.status) {
    console.error("erorr in api ", error);
    return {
      status: 500,
      data: {},
    };
  }
  return error.response;
};

export async function postRequest(url, data, headers, secure = false) {
  try {
    headers = getheaders(headers, secure);

    return await Axios.post(url, data, { headers }, { timeout: 600000 });
  } catch (error) {
    return getErorResponse(error);
  }
}

export async function putRequest(url, data, headers, secure = false) {
  try {
    headers = getheaders(headers, secure);

    return await Axios.put(url, data, { headers }, { timeout: 600000 });
  } catch (error) {
    return getErorResponse(error);
  }
}

export async function getRequest(url, config, secure = false) {
  try {
    const headers = getheaders(config?.headers || {}, secure);

    return await Axios.get(url, { ...config, headers });
  } catch (error) {
    return getErorResponse(error);
  }
}

export async function deleteRequest(url, config, secure = false) {
  try {
    const headers = getheaders(config?.headers || {}, secure);

    return await Axios.delete(url, { ...config, headers });
  } catch (error) {
    return getErorResponse(error);
  }
}

export async function printPostRequest(url, data, headers, secure = false) {
  try {
    // headers = getheaders(headers, secure);

    return await Axios.post(url, data, { headers,responseType: 'blob' }, { timeout: 600000 });
  } catch (error) {
    return getErorResponse(error);
  }
}