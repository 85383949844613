import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    backgroundColor:"#fff",
    height:'100vh'
  },
  header:{
    backgroundImage: "url('static/images/header.png')",
    backgroundSize:'cover',
    backgroundPosition:"bottom",
    height:"110px",
    lineHeight:"110px",
    backgroundRepeat:"no-repeat",
    padding: "0px 40px", 
    backgroundPositionY: "bottom",
  },
  headerImg:{
    width:"120px",height: "auto",verticalAlign:"middle"
  },
  mainLayout:{
    padding:"48px 120px",
    minHeight:"calc(100vh - 220px)",
    textAlign:"center"
    
     
  },
   backdropStyle: {
    zIndex: 1024,
    color: '#000',
    backgroundColor:"#ffffffa1"
  },
  danger:{
    color:"red"
  }
}));
