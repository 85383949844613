import React from "react";
import {
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
} from "@material-ui/core";
import useStyles from "./styles";

export default function ResponsiveDialog(props) {
  const classes = useStyles();
  const { selectedQus, getTextArea, setOpenDialog } = props;

  return (
    <div>
      <Dialog fullWidth={true} maxWidth={"md"} open={true}>
        <DialogContent className={classes.dialogContentSection}>
          <DialogTitle className={classes.dialogTitleSection}>
            {selectedQus.displayName}
          </DialogTitle>

          <DialogContentText>
            <DialogContent>{getTextArea(selectedQus, 10)}</DialogContent>
          </DialogContentText>
          <DialogActions className={classes.dialogActionSection}>
            <Button
              onClick={() => {
                setOpenDialog(false);
              }}
              color="primary"
              autoFocus
            >
              Submit
            </Button>
            <Button
              onClick={() => {
                setOpenDialog(false);
              }}
              color="primary"
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>{" "}
      </Dialog>
    </div>
  );
}
