import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },

  resultHeading: {
    position: "absolute",
    marginTop: "10px",
    color: "#fff",
    fontWeight: "bold !important",
    fontSize: "14px !important",
  },
  resultValues: {
    border: "40px solid",
    borderImageSlice: 1,
    borderWidth: "40px",
    borderImageSource: "linear-gradient(180deg, #507096, #f5f6f8)",
    padding: "20px",
  },
  resultText: {
    color: "#575757",
    fontWeight: "bold !important",
    fontSize: "14px !important",
    marginBottom: "10px",
    padding: "10px",
  },
  resultTextActive: {
    background: "#f6f6f6",
  },
  checkedRadio: {
    background: "#F5F5F5",
  },
  radioCSS: {
    margin: "0px",
  },
  arrowDownInner: {
    width: 0,
    height: 0,
    borderLeft: "6px solid transparent",
    borderRight: "6px solid transparent",
    borderTop: "22px solid #000",
    position: "absolute",
    // left: -18,
    bottom: -10,
  },
  buttonList: {
    "& a": {
      position: "relative",
      borderRadius: 0,
      padding: 0,
      borderBottom: `3px solid #F1F8FF`,
      color: `${theme.palette.primary.main}`,
      background: "transparent",
      marginRight: 50,
      fontWeight: 700,
      "&:hover": {
        background: "transparent",
      },
    },
  },
  tabBar: {
    padding: 20,
    background: "#F1F8FF",
    marginBottom: 20,
  },

  activeLink: {
    borderBottom: `3px solid ${theme.palette.primary.main} !important`,
    "&::before": {
      content: '""',
      display: "block",
      width: 0,
      height: 0,
      borderLeft: "5px solid transparent",
      borderRight: "5px solid transparent",
      borderTop: `10px solid ${theme.palette.primary.main}`,
      position: "absolute",
      bottom: -13,
    },
  },
  questionHeading: {
    // border:"1px solid #aed6ff",
    color: "#767676",
    fontSize: 16,
    fontWeight: 600,
    padding: 5,
  },
  resultButton: {
    background: `${theme.palette.warning.main}`,
    borderRadius: 50,
    padding: "10px 20px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 200,
    "&:hover": {
      background: `${theme.palette.warning.dark}`,
    },
  },
  submitButton: {
    background: `${theme.palette.orange.main}`,
    borderRadius: 50,
    padding: "10px 20px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 200,
    "&:hover": {
      background: `${theme.palette.orange.dark}`,
    },
  },
  statusSection: {
    "& *": {
      fontSize: 12,
      fontWeight: 700,
    },
    "& span": {
      marginRight: 5,
    },
  },
  low_bg: { backgroundColor: `${theme.palette.red.main}` },
  fair_bg: { backgroundColor: `${theme.palette.orange.main}` },
  average_bg: { backgroundColor: `${theme.palette.yellow.main}` },
  good_bg: { backgroundColor: `${theme.palette.green.main}` },
  excellent_bg: { backgroundColor: `${theme.palette.blue.main}` },
  low: { color: `${theme.palette.red.main}` },
  fair: { color: `${theme.palette.orange.main}` },
  average: { color: `${theme.palette.yellow.main}` },
  good: { color: `${theme.palette.green.main}` },
  excellent: { color: `${theme.palette.blue.main}` },
}));
