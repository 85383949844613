import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


const PrintDataContext = createContext({ data: null, component: null });
const PrintDataDispatchContext = createContext({ data: null, component: null });

function PrintDataProvider({ children }) {
  const [PrintData, setPrintData] = useState({ data: null, component: null });
  const location = useLocation();

  useEffect(()=>{
    console.log("location",PrintData)
  },[PrintData])


  return (
    <PrintDataContext.Provider value={PrintData}>
      <PrintDataDispatchContext.Provider value={setPrintData}>
        {children}
      </PrintDataDispatchContext.Provider>
    </PrintDataContext.Provider>
  );
}

export { PrintDataProvider, PrintDataContext, PrintDataDispatchContext };
