import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  questionHeading: {
    color: "#114551",
    backgroundColor: "#e3e4e7",
    textAlign: "center",
    position: "relative",
  },
  action: {
    position: " absolute",
    right: 10,
    top: 6,
  },
  actionButton: {
    color: "#3776be",
  },
}));
