import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  pageTitleContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(5),
  },
  typo: {
    color: theme.palette.text.hint,
  },
  button: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    "&:active": {
      boxShadow: theme.customShadows.widgetWide,
    },
  },
  underLine: {
    float: "left",
    width: "calc( 50% - 8px )",
    borderBottom: "3px solid #E84750",
    marginTop: 10,
  },
  dot: {
    float: "left",
    height: 6,
    width: 6,
    backgroundColor: "#154854",
    borderRadius: "50%",
    margin: "9px 5px",
  },
  underLineSection: {
    display: "inline-block",
    width: "20%",
  },
}));
