import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Grid } from "@material-ui/core";
import QuestionList from "../components/QuestionList";
import useStyles from "./../styles";
import ApiUri from "../../../constants/apiUri";
import { getRequest, postRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import {DataNotFoundDispatchContext}  from '../../../context/DataNotFoundContext'
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useSnackbar } from 'notistack';


const colors = {
  A004: "#fe8b32",
  A005: "#05b97a",
  A001: "#ff5351",
  A003: "#00b7d7",
  A002: "#fcc900",
};

export default function Question(props) {
  const setBackdrop = useContext(BackdropDispatchContext);
  const setDataNotFound = useContext(DataNotFoundDispatchContext);
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [qustionList, setQustionList] = useState([]);
  const [userDetails, setUserDetails] = useState(null);
  const [priorityMasters, setPriorityMasters] = useState(null);
  const [initialBoard, setInitialBoard] = useState([]);
  const [isDragging, setIsDragging] = useState(false);

  const getQusList = async () => {
    setBackdrop(true);
    try {
      let reqData = await getRequest(
        `${ApiUri.lifeline.qustionList}/${props.userData.code}`,
      );
      if (reqData.status == 200) {
        // console.log("reqData", reqData.data);
        setBackdrop(false);
        setUserDetails(reqData.data.data.usersCategories);
        setQustionList(reqData.data.data.categories);
        setPriorityMasters(reqData.data.data.priorityMasters);
      } else {
        setDataNotFound(true)
        setBackdrop(false)
      }
    } catch (error) {
      setDataNotFound(true)
      setBackdrop(false)
      // console.log(error);
    }
  };

  const onChange = (resultScore, questionCode) => {
    const newItem = [...selectedItem];
    let index = newItem.findIndex((v) => v.questionCode == questionCode);
    if (index == -1) {
      newItem.push({ resultScore, questionCode });
    } else {
      newItem[index] = { questionCode, resultScore };
    }
    // console.log("newItem", newItem);
    setSelectedItem(newItem);
  };

  const onSubmit = async () => {
    const lifeTransitions = [];
    const { startAge, startYear, endAge, endYear } = userDetails;

    initialBoard.forEach((v) => {
      v.row.forEach((rowData) => {
        rowData.length > 0 &&
          rowData.forEach((data) => {
            lifeTransitions.push({
              transitionCode: data.transitionCode,
              priorityCode: v.priorityCode,
              userCode: props.userData.code,
              transitionYear: data.transitionYear,
            });
          });
      });
    });

    const submitData = {
      startYear,
      endYear,
      startAge,
      endAge,
      lifeTransitions,
    };

    // console.log("initialBoard", submitData);

    setBackdrop(true);
    setDisabledSubmit(true);
    try {
      let reqData = await postRequest(
        ApiUri.lifeline.submitQustion,
        submitData,
      );
      if (reqData.status == 200) {
        enqueueSnackbar("Data Save Successfully");
        
        // console.log("reqData", reqData.data);
      } else {
        enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
      }
      setBackdrop(false);
        setDisabledSubmit(false);
    } catch (error) {
      enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
      setBackdrop(false);
      setDisabledSubmit(false);
      console.log("eeeeeeee", error);
    }
  };

  useEffect(() => {
    getQusList();
  }, []);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        {qustionList.length && userDetails && priorityMasters && (
          <QuestionList
            initialBoard={initialBoard}
            setInitialBoard={setInitialBoard}
            qustion={qustionList}
            priorityMasters={priorityMasters}
            userDetails={userDetails}
            setIsDragging={setIsDragging}
            getQusList={getQusList}
          />
        )}
      </DndProvider>

      <Box margin="auto" textAlign="center">
        <Grid container justify="space-evenly" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              component={Link}
              to={"/app/life-transition-planner/result"}
              className={classes.resultButton}
            >
              View Result
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              diabled={disabledSubmit}
              onClick={onSubmit}
              className={classes.submitButton}
            >
              Submit
            </Button>{" "}
          </Grid>
        </Grid>
      </Box>
      
    </>
  );
}
