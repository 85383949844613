import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "./styles";
import CardHeader from "../../../../components/CardHeader";
import StepSlider from "../../../../components/StepSlider";
// components

export default function Dashboard(props) {
  const classes = useStyles();
  const { selectetAns, tawScores } = props;

  const meterImage = tawScores.find((v) => {
    return (
      v.lowScore <= selectetAns.totalCategoryScore &&
      selectetAns.totalCategoryScore <= v.highScore
    );
  })?.meterImage;

  return (
    <>
      <CardHeader
        img={`data:image/png;base64,${selectetAns.imageIcon}`}
        type={selectetAns.name}
      />
      <Box>
        <Box pt={2} pb={2}>
          {selectetAns.questions.map((v) => {
            return (
              <Grid
                container
                spacing={2}
                alignItems="center"
                key={v.questionCode}
              >
                <Grid item sm={9}>
                  <Typography
                    variant="h5"
                    className={classes.resultText}
                    questionId={1223}
                  >
                    {v.question}
                  </Typography>
                </Grid>
                <Grid item sm={3}>
                  <StepSlider
                    onChange={(event, newValue) => {}}
                    min={v.lowScore}
                    max={v.highScore}
                    value={v.resultScore}
                    result={v.resultScore}
                    disabled={true}
                  />
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Box>
      <Box pt={5} pb={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={12} style={{ textAlign: "center" }}>
            <img src={`data:image/png;base64,${meterImage}`} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
