import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({


  footer:{
    backgroundImage: "url('static/images/footer.png')",
    backgroundSize:'cover',
    backgroundRepeat:"no-repeat",
    color:"#fff",
    padding: "6px 50px", 
  //  position: "fixed",
    width: "100%",
    // bottom:"0px",
    fontSize:"12px",
    zIndex: 1300,
    position: "relative"
    
    
  },
  footerHeading:{
    margin:"10px 0px"
  },
  footerDivider:{
    backgroundColor:"#fff",
    margin:"0px 10px"
      },
}));
