import React, { useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box } from "@material-ui/core";
import PageData from "./../../components/PageData";

// styles
import useStyles from "./styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import Step from "../Step";
import Footer from "../Footer";

// pages
import routes from "../../router";

// context
import { useLayoutState } from "../../context/LayoutContext";
import { BackdropProvider } from "../../context/BackdropContext";
import { EditProfileProvider } from "../../context/EditProfileContext";
import PagePermissionContainer from "./pagePermissionContainer";
import { DataNotFoundProvider } from "../../context/DataNotFoundContext";
import { PrintDataProvider } from "../../context/PrintDataContext";
import { useUserState } from "../../context/UserContext";

import TabBar from "../../components/TabBar";
import PageTitle from "../../components/PageTitle";
function Layout(props) {
  var classes = useStyles();
  // global
  var layoutState = useLayoutState();
  const { clientData, userData } = useUserState();

  const routersList = routes(clientData, userData);

  return (
    <>
      <div className={classes.root}>
        <EditProfileProvider>
          <Header history={props.history} />
          <Sidebar />
          <BackdropProvider>
            <div
              className={classnames(classes.content, {
                [classes.contentShift]: layoutState.isSidebarOpened,
              })}
            >
              <div className={classes.fakeToolbar} />

              <Switch>
                {routersList.map((v, i) => {
                  if (v.hasPermission) {
                    return (
                      <Route exact={v.exact} path={v.path} key={`title_${i}`}>
                        {v.step && <Step value={v.stepValue} />}
                        <PrintDataProvider>
                          <PageData {...v} />
                          <PageTitle title={v.title} sup={v.sup} />
                          <TabBar basePath={v.path} showNav={v.showNav} />

                          <DataNotFoundProvider>
                            <v.component {...v} />
                          </DataNotFoundProvider>
                        </PrintDataProvider>
                      </Route>
                    );
                  }
                })}
                <Route path="*">
                  <PagePermissionContainer />
                </Route>
              </Switch>
              <Box
                mt={5}
                width={"100%"}
                display={"flex"}
                alignItems={"center"}
                justifyContent="space-between"
              ></Box>
            </div>
          </BackdropProvider>
        </EditProfileProvider>
      </div>
      <Footer />
    </>
  );
}

export default withRouter(Layout);
