import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography
} from "@material-ui/core";

import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "../../styles";
import CardHeader from '../../../../components/CardHeader'
import ClassNames from 'classnames'
// components


export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();
 
  const activeStatus = (v)=>{
    if(props.selectedResult.answersCode == v.code)
    {
      return true
    }
    return false
  }

 
  return (
    <>
    <div id={props.type}>
    <CardHeader  img={`data:image/png;base64,${props.icon}`} {...props} />
    </div>
   
    <Box p={2}>
    <Grid container spacing={2}  alignItems="center">
      
      <Grid item sm={9}>
       {
                            props.options.sort((a,b)=>{
                              return a.score - b.score
                            }).map((v)=>{
                              let scoreType = props.graphSection[v.score-1]
                              
                               return  <><Typography variant="h5" className={ClassNames(classes.resultText,{[classes.resultTextActive]:v.result},{[classes[scoreType]]:v.result})}>{
                                v.answer
                              }
                              </Typography>
                        </>
                            })
                        }   
      
      </Grid>
      <Grid item sm={3}>
      <img src={`static/images/dashboard/${props.selectedItemScore}.png`} />
      </Grid>
      </Grid>
    </Box>
     
  </>
    );
}

