import React, { useState, useEffect,useContext } from "react";
import {
  Drawer,
  IconButton,
  List,
  Avatar,
  Grid,
  Divider,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  ExitToApp as ExitToAppIcon,
} from "@material-ui/icons";
import ApiUri from "./../../constants/apiUri";
import { getRequest, postRequest } from "./../../utils/API";
import SidebarMenuList from "../../utils/sidebarMenuList";
import { useTheme } from "@material-ui/styles";
import { useHistory, useLocation } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";
import Dot from "./components/Dot";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import {
  useUserState,
  signOut,exit,
  useUserDispatch,
} from "../../context/UserContext";
import {
  EditProfileDispatchContext
} from '../../context/EditProfileContext'

export default function Sidebar() {
  var theme = useTheme();
  const location = useLocation();
  const history = useHistory();
  const userDispatch = useUserDispatch();
  const editProfile =  useContext(EditProfileDispatchContext)

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  const [sideBarOfset, setSideBarOfset] = useState(110);
  const { clientData,userData} = useUserState();

  const loginUser = clientData?clientData : userData
  const {userType} = userData
  // local
  var [isPermanent, setPermanent] = useState(true);

  var [profileData, setProfileData] = useState({});
  var classes = useStyles();

  // const getQusList = async () => {
  //   try {
  //     let resData = await getRequest(ApiUri.profile.qustionList);
  //     if (resData.status == 200) {
  //       const data = resData.data.data.userProfileDetails;
  //       setProfileData(data);
  //     } else {
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(
    function () {
      handleScroll();
      window.addEventListener("resize", handleWindowWidthChange);
      window.addEventListener("scroll", handleScroll);
      handleWindowWidthChange();

      return function cleanup() {
        window.removeEventListener("resize", handleWindowWidthChange);
        window.removeEventListener("scroll", handleScroll);
      };
    },
    [handleWindowWidthChange],
  );
  const sidebarMenu = SidebarMenuList(classes,clientData ,userData,userType)

  const exitButton = ()=>{
   
  if (clientData)
    {

     let onClick = userType == "ADVISOR" ? ()=> exit(userDispatch, history): ()=> signOut(userDispatch, history)

      return <div
      style={{ fontSize: "14px", cursor: "pointer" }}
      onClick={onClick}
    >
      <span>Exit</span>{" "}
      <ExitToAppIcon
        style={{ fontSize: "14px", marginBottom: "-2px" }}
      />
    </div>
    }
   
  }



  // useEffect(() => {
  //   getQusList();
  // }, []);
  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames(classes.paperClass, {
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div
        style={{
          top: `${sideBarOfset}px`,
          height: `calc(100% - ${sideBarOfset}px)`,
          position: "relative",
        }}
      >
        <div>
          <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse,
                ),
              }}
            />
          </IconButton>
          <div style={{ padding: "10px" }}>
            <Grid container spacing={2}>
              {/* <Grid item sm={isSidebarOpened ? 5 : 12}>
                <Avatar
                  alt="Remy Sharp"
                  style={{ width: "100%", height: "auto", marginBottom: "2px" }}
                  src={
                    profileData.profilePhoto
                      ? `data:image/png;base64,${profileData.profilePhoto}`
                      : "/static/images/profile.png"
                  }
                />
              </Grid> */}
              <Grid
                item
                sm={12}
                className={classes.userProfileSection}
                
              >
                <div className={classes.userProfile} onClick={()=>{
                  editProfile({show:true,isClient:clientData? true: false})
                }}>
                  {loginUser && loginUser.firstName}
                  {isSidebarOpened ? <></> : <br />}{" "}
                  {loginUser && loginUser.lastName}
                </div>
                {exitButton()}
              </Grid>
            </Grid>
            <Divider />
          </div>
        </div>
        <List className={classes.sidebarList}>
          {sidebarMenu.map((link) => (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              {...link}
            />
          ))}
        </List>
      </div>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }

  function handleScroll(event) {
    const pageYOffset = window.pageYOffset < 110 ? window.pageYOffset - 110 : 0;
    // console.log("pageYOffset",pageYOffset)
    setSideBarOfset(-pageYOffset);
  }
}
