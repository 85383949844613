import React, { useEffect, useState, useContext } from "react";
import { Grid, Box, Paper } from "@material-ui/core";
import PieChart from "./pieChart";
import Result from "../components/Result";
import useStyles from "./styles";
import ApiUri from "../../../constants/apiUri";
import { getRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import {DataNotFoundDispatchContext}  from '../../../context/DataNotFoundContext'
// import PrintDataComponent from '../../../PrintData/LifeTransitionPlanner'
import { PrintDataDispatchContext } from "../../../context/PrintDataContext";
import {printSectionTypes} from "../../../constants/index"

 
const colors = {
  A004: "#fe8b32",
  A005: "#05b97a",
  A001: "#ff5351",
  A003: "#00b7d7",
  A002: "#fcc900",
};
export default function Answers(props) {
  const setBackdrop = useContext(BackdropDispatchContext);
  const setDataNotFound = useContext(DataNotFoundDispatchContext);
  const classes = useStyles();
  const [aggregationPercentage, setAggregationPercentage] = useState([]);
  const [topComforts, setTopComforts] = useState([]);
  const [topConcerns, setTopConcerns] = useState([]);
  const [lifeTransitions, setLifeTransitions] = useState([]);
  const [selectedResult, setSelectedResult] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const setPrintData = useContext(PrintDataDispatchContext);


  const getUserResult = async () => {
    setBackdrop(true);
    let resData = await getRequest(ApiUri.lifeline.userResults);
    let topFiveComforts = [];
    let topFiveConcerns = [];
    let lifeTransitions = [];
    if (resData.status === 200) {
      const data = resData.data.data;
      if (data.aggregationPercentage.categories) {
        const aggregationPercentage = data.aggregationPercentage.categories.map(
          (v) => {
            return {
              color: colors[v.categoryCode],
              displayName: v.displayName,
              imageIcon: v.imageIcon,
              value: v.percentageValue || 0,
            };
          },
        );
        setAggregationPercentage(aggregationPercentage);
      }

      if (data?.topComfortsAndConcerns?.topFiveComforts)
      {
       
         
        topFiveComforts =  data.topComfortsAndConcerns.topFiveComforts.reduce((total,current)=>{

        return  [...total, ...current.transitions.map((newValue)=>({...newValue,imageIcon:current.imageIcon , categoryName: current.displayName}))]
         
       },[]).sort((a,b)=>{return a.transitionYear-b.transitionYear})
        setTopComforts(topFiveComforts);
      }
      if (data?.topComfortsAndConcerns?.topFiveConcerns) {

        
        topFiveConcerns =  data.topComfortsAndConcerns.topFiveConcerns.reduce((total,current)=>{

         return  [...total, ...current.transitions.map((newValue)=>({...newValue,imageIcon:current.imageIcon , categoryName: current.displayName}))]
          
        },[]).sort((a,b)=>{return a.transitionYear-b.transitionYear})
        setTopConcerns(topFiveConcerns);
      }
      if (data.usersCategories.transitionYearAges)
      {
        lifeTransitions = data.usersCategories.transitionYearAges;
        setLifeTransitions(lifeTransitions);
      }
        

        setPrintData(printSectionTypes.lifeline)

      setBackdrop(false);
    } else {
      setBackdrop(false);
      setDataNotFound(true);
    }
  };

  useEffect(() => {
    getUserResult();
  }, []);

  return (
    <>
      {aggregationPercentage.length > 0 && (
        <>
        <div>
        <PieChart
            selectedResult={selectedResult}
            totalScore={totalScore}
            aggregationPercentage={aggregationPercentage}
          />
        </div>
          <div className="print_svg">
          <Box pb={2} pt={5}>
            <Grid
              container
              direction="row"
              justify="center"
              aliginItems="flex-start"
              spacing={2}
              className={classes.statusSection}
            >
              {aggregationPercentage.map((v) => {
                return (
                  <Grid item spacing={2}>
                    <Paper style={{ backgroundColor: v.color }}>
                      {v.displayName} ({v.value}%)
                    </Paper>
                  </Grid>
                );
              })}
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              aliginItems="flex-start"
            >
              <Grid item className={classes.disclaimer}>
                Disclaimer: Percentage values are rounded off to nearest whole
                number (ceiling value). Error percentage not more than +2%.
              </Grid>
            </Grid>
          </Box>

          </div>
          
          <Box p={3}>
            <Result
              topComforts={topComforts}
              topConcerns={topConcerns}
              lifeTransitions={lifeTransitions}
              user={props.userData}
            />
          </Box>
        </>
      )}
    </>
  );
}
