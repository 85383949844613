import React, { useEffect, useState, useContext } from "react";

import { Box, Button } from "@material-ui/core";

import PrintIcon from "@material-ui/icons/Print";
import { PrintDataContext } from "../../context/PrintDataContext";

import { printPostRequest, getRequest } from "../../utils/API";

export default function PrintPage({ selectedPath, linkName, userData }) {
  // const [svgImage, setSvgImage] = useState([]);
  const { REACT_APP_REPORT_GENERATOR_BASE_API_URL } = process.env;
  const printType = useContext(PrintDataContext);
  const fileDownalod = async () => {
    const headers = {
      "Content-Type": "application/json",
    };
    console.log("ddddd", process.env);
    let reqData = await printPostRequest(
      REACT_APP_REPORT_GENERATOR_BASE_API_URL,
      { userData, printFor: printType },
      // headers,
    );
    if (reqData.status === 200) {
      const filename = reqData.headers["filename"];
      const url = window.URL.createObjectURL(new Blob([reqData.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename); //or any other extension
      document.body.appendChild(link);
      link.click();
      // enqueueSnackbar("Data Save Successfully");
    } else {
      // enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
    }
  };

  return (
    <Button variant="text" startIcon={<PrintIcon />} onClick={fileDownalod}>
      {linkName}
    </Button>
  );
}
