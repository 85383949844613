import React from "react";
import Donut from "./Donut";

import "./style.css";

export default function PieChart(props) {
  const chartData = props.aggregationPercentage;
  const segments = chartData.filter((v) => v.value !== 0);

  return (
    <div style={{ textAlign: "center" }} className="print_svg">
      <svg
        version="1.2"
        baseProfile="tiny-ps"
        viewBox="0 0 924 812"
        width={800}
        height={"auto"}
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <g id="img9">
            <Donut
              size={500}
              radius={107}
              showPercentage={true}
              segments={segments}
              thickness={100}
              startAngle={45}
            />
          </g>
        </defs>
        <g id="Fir">
          <path
            id="Ellipse 1 copy"
            className="shp0"
            d="M 459.668 62.348 C 554.438 62.348 631.268 139.158 631.268 233.908 C 631.268 328.658 554.438 405.468 459.668 405.468 C 364.898 405.468 288.068 328.658 288.068 233.908 C 288.068 139.158 364.898 62.348 459.668 62.348 Z"
            style={{ strokeWidth: 15 }}
          />
          <g id="Group 2">
            <path
              id="Ellipse 1"
              className="shp1"
              d="M297.87 291.43C303.33 291.43 307.75 295.85 307.75 301.3C307.75 306.76 303.33 311.18 297.87 311.18C292.42 311.18 287.99 306.76 287.99 301.3C287.99 295.85 292.42 291.43 297.87 291.43Z"
            />
            <path
              id="Ellipse 1 copy 5"
              className="shp1"
              d="M622.35 291.43C627.81 291.43 632.23 295.85 632.23 301.3C632.23 306.76 627.81 311.18 622.35 311.18C616.89 311.18 612.47 306.76 612.47 301.3C612.47 295.85 616.89 291.43 622.35 291.43Z"
            />
            <path
              id="Ellipse 1 copy 3"
              className="shp1"
              d="M359.23 374.61C364.69 374.61 369.11 379.03 369.11 384.49C369.11 389.94 364.69 394.36 359.23 394.36C353.78 394.36 349.35 389.94 349.35 384.49C349.35 379.03 353.78 374.61 359.23 374.61Z"
            />
            <path
              id="Ellipse 1 copy 5"
              className="shp1"
              d="M558.91 374.61C564.37 374.61 568.79 379.03 568.79 384.49C568.79 389.94 564.37 394.36 558.91 394.36C553.45 394.36 549.03 389.94 549.03 384.49C549.03 379.03 553.45 374.61 558.91 374.61Z"
            />
            <path
              id="Ellipse 1 copy 4"
              className="shp1"
              d="M461 406C466.52 406 471 410.48 471 416C471 421.52 466.52 426 461 426C455.48 426 451 421.52 451 416C451 410.48 455.48 406 461 406Z"
            />
          </g>
          <g id="Group 3">
            <path
              id="Rectangle 1 copy"
              fill={chartData[0].color}
              d="M142.06 355.49L241.00 318.06L242.00 321.98L143.49 359.42L142.06 355.49Z"
            />
            <path
              id="Rectangle 1 copy 2"
              fill={chartData[1].color}
              d="M260.87 518.65L324.18 429.86L327.6 432.28L264.29 521.07L260.87 518.65Z"
            />
            <path
              id="Rectangle 1 copy 3"
              fill={chartData[2].color}
              d="M458 579.99L458.39 472L463 472.01L462.61 580L458 579.99Z"
            />

            <path
              id="Rectangle 1 copy 4"
              fill={chartData[3].color}
              d="M654.1 518.67L592.62 429.64L589.2 432.01L650.67 521.03L654.1 518.67Z"
            />
            <path
              id="Rectangle 1 copy 5"
              fill={chartData[4].color}
              d="M779.25 356.39L676.53 319.74L675.11 323.69L777.83 360.34L779.25 356.39Z"
            />
          </g>
          <g id="Red">
            <path
              id="Ellipse 1 copy 8"
              stroke={chartData[0].color}
              className="shp7"
              d="M258.87 296.63C269.21 296.63 277.59 305.01 277.59 315.34C277.59 325.68 269.21 334.06 258.87 334.06C248.54 334.06 240.16 325.68 240.16 315.34C240.16 305.01 248.54 296.63 258.87 296.63Z"
            />
            <path
              id="Ellipse 1 copy 7"
              className="shp1"
              d="M258.88 308.06C262.9 308.06 266.16 311.32 266.16 315.34C266.16 319.36 262.9 322.62 258.88 322.62C254.85 322.62 251.6 319.36 251.6 315.34C251.6 311.32 254.85 308.06 258.88 308.06Z"
            />
          </g>
          <g id="Amber">
            <path
              id="Ellipse 1 copy 8"
              stroke={chartData[1].color}
              className="shp8"
              d="M336.87 397.48C347.21 397.48 355.59 405.86 355.59 416.2C355.59 426.53 347.21 434.91 336.87 434.91C326.54 434.91 318.15 426.53 318.15 416.2C318.15 405.86 326.54 397.48 336.87 397.48Z"
            />
            <path
              id="Ellipse 1 copy 7"
              className="shp1"
              d="M336.87 408.92C340.89 408.92 344.15 412.18 344.15 416.2C344.15 420.22 340.89 423.48 336.87 423.48C332.85 423.48 329.59 420.22 329.59 416.2C329.59 412.18 332.85 408.92 336.87 408.92Z"
            />
          </g>
          <g id="yellow">
            <path
              id="Ellipse 1 copy 8"
              stroke={chartData[2].color}
              className="shp9"
              d="M460.5 436C470.72 436 479 444.28 479 454.5C479 464.72 470.72 473 460.5 473C450.28 473 442 464.72 442 454.5C442 444.28 450.28 436 460.5 436Z"
            />
            <path
              id="Ellipse 1 copy 7"
              className="shp1"
              d="M460.5 447.31C464.47 447.31 467.69 450.53 467.69 454.5C467.69 458.47 464.47 461.69 460.5 461.69C456.53 461.69 453.31 458.47 453.31 454.5C453.31 450.53 456.53 447.31 460.5 447.31Z"
            />
          </g>
          <g id="Green">
            <path
              id="Ellipse 1 copy 8"
              stroke={chartData[3].color}
              className="shp10"
              d="M580.23 397.48C590.57 397.48 598.95 405.86 598.95 416.2C598.95 426.53 590.57 434.91 580.23 434.91C569.89 434.91 561.51 426.53 561.51 416.2C561.51 405.86 569.89 397.48 580.23 397.48Z"
            />
            <path
              id="Ellipse 1 copy 7"
              className="shp1"
              d="M580.23 408.92C584.25 408.92 587.51 412.18 587.51 416.2C587.51 420.22 584.25 423.48 580.23 423.48C576.21 423.48 572.95 420.22 572.95 416.2C572.95 412.18 576.21 408.92 580.23 408.92Z"
            />
          </g>
          <g id="Blue">
            <path
              id="Ellipse 1 copy 8"
              stroke={chartData[4].color}
              className="shp11"
              d="M658.23 296.63C668.57 296.63 676.95 305.01 676.95 315.34C676.95 325.68 668.57 334.06 658.23 334.06C647.89 334.06 639.51 325.68 639.51 315.34C639.51 305.01 647.89 296.63 658.23 296.63Z"
            />
            <path
              id="Ellipse 1 copy 7"
              className="shp1"
              d="M658.23 308.06C662.25 308.06 665.51 311.32 665.51 315.34C665.51 319.36 662.25 322.62 658.23 322.62C654.21 322.62 650.95 319.36 650.95 315.34C650.95 311.32 654.21 308.06 658.23 308.06Z"
            />
          </g>
          <g id="Circle">
            <path
              id="Ellipse 2"
              className=" dropShadow"
              fill={chartData[0].color}
              d="M111.5 279C161.49 279 202.01 319.47 202.01 369.4C202.01 419.33 161.49 459.81 111.5 459.81C61.52 459.81 21 419.33 21 369.4C21 319.47 61.52 279 111.5 279Z"
            />
            <path
              id="Ellipse 2 copy 6"
              className="shp12 dropShadow"
              d="M111.02 301.9C148.08 301.9 178.12 331.91 178.12 368.92C178.12 405.94 148.08 435.95 111.02 435.95C73.97 435.95 43.93 405.94 43.93 368.92C43.93 331.91 73.97 301.9 111.02 301.9Z"
            />
            <text
              id="60%"
              style={{
                transform: "matrix(1.04, 0, 0, 1.039, 111.769, 382.724)",
                whiteSpace: "pre",
              }}
            >
              <tspan x={0} dy="1em">
                ​
              </tspan>{" "}
              <tspan x={0} y={0} className="txt13">
                {chartData[0].value}%
              </tspan>{" "}
            </text>
            <path
              id="Ellipse 2 copy 12"
              className=" dropShadow"
              fill={chartData[1].color}
              d="M246.74 455.65C296.73 455.65 337.25 496.13 337.25 546.05C337.25 595.98 296.73 636.46 246.74 636.46C196.76 636.46 156.24 595.98 156.24 546.05C156.24 496.13 196.76 455.65 246.74 455.65Z"
            />
            <path
              id="Ellipse 2 copy 13"
              className="shp12 dropShadow"
              d="M246.26 478.55C283.32 478.55 313.36 508.56 313.36 545.57C313.36 582.59 283.32 612.6 246.26 612.6C209.2 612.6 179.16 582.59 179.16 545.57C179.16 508.56 209.2 478.55 246.26 478.55Z"
            />
            <text
              id="20%"
              style={{
                transform: "matrix(1.04, 0, 0, 1.039, 245.951, 559.351)",
                whiteSpace: "pre",
              }}
            >
              <tspan x={0} dy="1em">
                ​
              </tspan>{" "}
              <tspan x={0} y={0} className="txt13">
                {chartData[1].value}%
              </tspan>
              <tspan x={0} dy="1em">
                ​
              </tspan>
            </text>
            <path
              id="Ellipse 2 copy 14"
              className=" dropShadow"
              fill={chartData[2].color}
              d="M461 523.19C510.98 523.19 551.5 563.67 551.5 613.6C551.5 663.52 510.98 704 461 704C411.02 704 370.5 663.52 370.5 613.6C370.5 563.67 411.02 523.19 461 523.19Z"
            />
            <path
              id="Ellipse 2 copy 15"
              className="shp12 dropShadow"
              d="M460.52 546.09C497.58 546.09 527.62 576.1 527.62 613.12C527.62 650.13 497.58 680.14 460.52 680.14C423.46 680.14 393.42 650.13 393.42 613.12C393.42 576.1 423.46 546.09 460.52 546.09Z"
            />
            <text
              id="3%"
              style={{
                transform: "matrix(1.04, 0, 0, 1.039, 460.227, 626.978)",
                whiteSpace: "pre",
              }}
            >
              <tspan x={0} dy="1em">
                ​
              </tspan>{" "}
              <tspan x={0} y={0} className="txt13">
                {chartData[2].value}%
              </tspan>
              <tspan x={0} dy="1em">
                ​
              </tspan>{" "}
            </text>
            <path
              id="Ellipse 2 copy 16"
              className=" dropShadow"
              fill={chartData[3].color}
              d="M673.26 455.65C723.24 455.65 763.76 496.13 763.76 546.05C763.76 595.98 723.24 636.46 673.26 636.46C623.27 636.46 582.75 595.98 582.75 546.05C582.75 496.13 623.27 455.65 673.26 455.65Z"
            />
            <path
              id="Ellipse 2 copy 17"
              className="shp12 dropShadow"
              d="M672.78 478.55C709.84 478.55 739.88 508.56 739.88 545.57C739.88 582.59 709.84 612.6 672.78 612.6C635.72 612.6 605.68 582.59 605.68 545.57C605.68 508.56 635.72 478.55 672.78 478.55Z"
            />
            <text
              id="7%"
              style={{
                transform: "matrix(1.04, 0, 0, 1.039, 672.463, 559.351)",
                whiteSpace: "pre",
              }}
              x="6.734"
            >
              <tspan
                x="6.734"
                y={0}
                className="txt13"
                style={{ fontSize: 40, wordSpacing: 0 }}
              >
                {chartData[3].value}%
              </tspan>
            </text>
            <path
              id="Ellipse 2 copy 18"
              className=" dropShadow"
              fill={chartData[4].color}
              d="M808.5 279C858.48 279 899 319.47 899 369.4C899 419.33 858.48 459.81 808.5 459.81C758.51 459.81 717.99 419.33 717.99 369.4C717.99 319.47 758.51 279 808.5 279Z"
            />
            <path
              id="Ellipse 2 copy 19"
              className="shp12 dropShadow"
              d="M808.02 301.9C845.07 301.9 875.11 331.91 875.11 368.92C875.11 405.94 845.07 435.95 808.02 435.95C770.96 435.95 740.92 405.94 740.92 368.92C740.92 331.91 770.96 301.9 808.02 301.9Z"
            />
            <text
              id="20%"
              style={{
                transform: "matrix(1.04, 0, 0, 1.039, 807.646, 382.724)",
                whiteSpace: "pre",
              }}
            >
              <tspan x={0} dy="1em">
                ​
              </tspan>{" "}
              <tspan x={0} y={0} className="txt13">
                {chartData[4].value}%
              </tspan>
            </text>
            <text
              x="860.076"
              y="378.597"
              style={{
                whiteSpace: "pre",
                fill: "rgb(51, 51, 51)",
                fontFamily: '"Roboto Slab"',
                fontSize: 28,
              }}
            >
              {" "}
            </text>
            <text
              x="860.076"
              y="378.597"
              style={{
                whiteSpace: "pre",
                fill: "rgb(51, 51, 51)",
                fontFamily: '"Roboto Slab"',
                fontSize: 28,
              }}
            >
              {" "}
            </text>
          </g>
          <text
            id="Family"
            style={{
              transform: "matrix(1.037, 0, 0, 1.037, 29.795, 531.933)",
              whiteSpace: "pre",
            }}
          >
            <tspan x={0} y={0} className="txt14">
              {chartData[0].displayName}
            </tspan>
          </text>
          <image
            width={265}
            height={255}
            id="Layer 177 copy 25"
            transform="matrix(0.1850000023841858, 0, 0, 0.18400000035762787, 6, 462)"
            href={`data:image/png;base64,${chartData[0].imageIcon}`}
          />
          <text
            id="Health"
            style={{
              transform: "matrix(1.042, 0, 0, 1.042, 154.511, 711.103)",
              whiteSpace: "pre",
            }}
          >
            <tspan x={0} y={0} className="txt14">
              {chartData[1].displayName}
            </tspan>
          </text>
          <image
            width={209}
            height={223}
            id="Layer 239"
            transform="matrix(0.2150000035762787, 0, 0, 0.2150000035762787, 132, 641)"
            href={`data:image/png;base64,${chartData[1].imageIcon}`}
          />
          <text
            id="Work"
            style={{
              transform: "matrix(1.04, 0, 0, 1.039, 461.667, 810.918)",
              whiteSpace: "pre",
            }}
          >
            <tspan x={0} y={0} className="txt14">
              {chartData[2].displayName}
            </tspan>
          </text>
          <image
            width={223}
            height={199}
            id="Layer 236"
            transform="matrix(0.2330000102519989, 0, 0, 0.23600000143051147, 436, 741)"
            href={`data:image/png;base64,${chartData[2].imageIcon}`}
          />
          <text
            id="Retirement"
            style={{
              transform: "matrix(1.04, 0, 0, 1.039, 761.035, 710.941)",
              whiteSpace: "pre",
            }}
          >
            <tspan x={0} y={0} className="txt14">
              {chartData[3].displayName}
            </tspan>
          </text>
          <image
            width={230}
            height={214}
            id="Layer 237"
            transform="matrix(-0.23000000417232513, 0, 0, 0.2199999988079071, 789, 642)"
            href={`data:image/png;base64,${chartData[3].imageIcon}`}
          />
          <text
            id="Financial"
            style={{
              transform: "matrix(1.04, 0, 0, 1.039, 877.698, 532.127)",
              whiteSpace: "pre",
            }}
          >
            <tspan x={0} y={0} className="txt14">
              {chartData[4].displayName}
            </tspan>
          </text>
          <image
            width={137}
            height={276}
            id="Layer 238"
            transform="matrix(0.1679999977350235, 0, 0, 0.17000001668930054, 867, 462)"
            href={`data:image/png;base64,${chartData[4].imageIcon}`}
          />
          <g id="pie" transform="matrix(1, 0, 0, 1, -0.961502, -8.653619)">
            <g id="Layer 232" transform="matrix(1, 0, 0, 1, 210.5, -7.5)">
              <use id="Layer 232" href="#img9"></use>
            </g>
            <path
              id="Ellipse 15"
              className="shp12"
              d="M457.5 182C490.91 182 518 209.09 518 242.5C518 275.91 490.91 303 457.5 303C424.09 303 397 275.91 397 242.5C397 209.09 424.09 182 457.5 182Z"
            />
            <g id="lifeline copy 3" style={{ opacity: "0.302" }}>
              <path
                id="Shape 1"
                className="shp16"
                d="M433.22 241.41C433.22 241.41 432.08 236.82 428.1 237.11C424.11 237.4 423.83 237.54 423.83 237.54C423.83 237.54 421.69 228.79 427.24 222.04C432.8 215.3 442.34 214.15 448.17 217.3C454.01 220.46 453.16 222.9 457.14 222.9C461.13 222.9 464.4 218.6 466.54 217.74C468.67 216.87 477.07 213 484.48 218.6C491.88 224.19 492.31 229.5 491.74 237.11C491.74 237.11 487.47 236.1 486.19 240.55C484.9 245 484.48 245.72 484.48 245.72L481.06 231.08C481.06 231.08 480.49 226.63 476.36 226.78C476.36 226.78 474.23 226.35 472.09 230.65C469.96 234.96 467.82 238.4 467.82 238.4L460.56 238.4C460.56 238.4 457 238.4 455.86 242.28C454.72 246.15 453.3 250.02 453.3 250.02L446.89 227.21C446.89 227.21 446.18 223.19 442.19 223.76C438.21 224.34 437.92 227.64 437.92 227.64L433.22 241.41Z"
              />
              <path
                id="Shape 2"
                className="shp16"
                d="M448.6 265.52L438.35 255.19L442.62 243.14L448.6 265.52Z"
              />
              <path
                id="Shape 3"
                className="shp16"
                d="M452.02 268.97C452.02 268.97 456.15 269.83 457.57 265.09C458.99 260.36 463.12 247.44 463.12 247.44L469.96 247.44C469.96 247.44 473.23 248.02 474.65 244.43L477.22 254.76L463.12 268.97C463.12 268.97 458.85 276 452.02 268.97Z"
              />
              <path
                id="Shape 4"
                className="shp16"
                d="M418.7 243.14L427.67 243.14L432.8 253.9C432.8 253.9 434.22 256.2 435.36 253.9L442.62 232.37L451.16 262.94C451.16 262.94 451.59 266.1 452.87 265.95C454.15 265.81 454.15 264.23 454.15 264.23L460.99 244.43L471.24 244.43L475.94 235.39L482.77 262.94C482.77 262.94 483.48 265.81 485.33 262.94L491.74 242.71L494.73 242.71C494.73 242.71 499 244.14 499 241.41C499 238.69 496.29 239.12 495.16 240.12L490.46 240.12C490.46 240.12 489.46 240.55 489.18 241.41C488.89 242.28 484.05 257.34 484.05 257.34L478.07 231.08C478.07 231.08 476.79 228.5 475.08 231.51C473.37 234.53 469.53 241.85 469.53 241.85L460.13 241.85C460.13 241.85 459.42 241.41 458.85 243.14C458.28 244.86 452.87 259.93 452.87 259.93L443.9 227.64C443.9 227.64 442.19 225.05 441.34 227.64L433.65 250.46L429.81 241.41C429.81 241.41 429.24 240.7 427.67 240.55C426.11 240.41 418.27 240.55 418.27 240.55C418.27 240.55 415.57 238.69 415.28 241.41C415 244.14 418.7 243.14 418.7 243.14Z"
              />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}
