import React from "react";
import { InfoOutlined } from "@material-ui/icons";
import {
  Popover,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import useStyles from "./styles";

const PasswordRuleHelperIcon = (props) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverToggle = (event) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={classes.passwordHelperIcon}>
      <InfoOutlined
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handlePopoverToggle}
      />
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        style={{ width: "100%" }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box className={classes.passwordPopover}>
          <Typography className={classes.rulesHeading}>Password Rules:</Typography>
          <List disablePadding dense>
            <ListItem classes={{root: classes.listItem}}>
              <ListItemIcon className={classes.bulletIcon}>
                <FiberManualRecord />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Password must be atleast 8 characters long and should not exceed 20 characters"
                disableTypography
              />
            </ListItem>
            <ListItem classes={{root: classes.listItem}}>
              <ListItemIcon className={classes.bulletIcon}>
                <FiberManualRecord />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Must contain atleast 1 upper case character"
                disableTypography
              />
            </ListItem>
            <ListItem classes={{root: classes.listItem}}>
              <ListItemIcon className={classes.bulletIcon}>
                <FiberManualRecord />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Must contain atleast 1 lower case character"
                disableTypography
              />
            </ListItem>
            <ListItem classes={{root: classes.listItem}}>
              <ListItemIcon className={classes.bulletIcon}>
                <FiberManualRecord />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Must contain atleast 1 number"
                disableTypography
              />
            </ListItem>
            <ListItem classes={{root: classes.listItem}}>
              <ListItemIcon className={classes.bulletIcon}>
                <FiberManualRecord />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primary="Must contain atleast 1 special character"
                disableTypography
              />
            </ListItem>
          </List>
        </Box>
      </Popover>
    </div>
  );
};

export default PasswordRuleHelperIcon;
