import React, { useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Grid,
  LinearProgress,
} from "@material-ui/core";
import classNames from "classnames";
import useStyles from "./styles";
import { Link, useLocation } from "react-router-dom";
var step = [
  { name: "Life Idealogy Profiler", imgName: "my-idealogy.png",sup:String.fromCharCode('8482')},
  { name: "FinView Meter", imgName: "finviewmeter.png",sup:String.fromCharCode('174') },
  { name: "The Attainment Wheel - N.A.V", imgName: "wheel-of-attainment.png",sup:String.fromCharCode('8482') },
  { name: "Life Transition Planner", imgName: "lifeline.png",sup:String.fromCharCode('8482') },
  { name: "Retire-Meant", imgName: "retirement-planning.png",sup:String.fromCharCode('174') },
  { name: "Money Purpose Wheel - C.A.R.E", imgName: "money-purpose-wheel.png",sup:String.fromCharCode('8482')},
];

export default function Step(props) {
  var classes = useStyles();
  const location = useLocation();
  const pathType = location.pathname.split("/")[3];
  let stepValue = props.value - (pathType == "question" ? 1 : 0);
  const getStepProcess = () => {
    return 20 * stepValue;
  };
  return (
    <>
      <Box pr={2} pl={2}>
        <Grid container justify="center">
          <Grid item sm={12}>
            <LinearProgress
              variant="determinate"
              value={getStepProcess()}
              className={classes.hexagonHr}
            />

            <Grid container justify="space-between">
              {step.map((v, i) => {
                let hexagonActive = i <= stepValue && classes.hexagonActive;
                return (
                  <Grid item xs className={classes.textAlignCenter}>
                    <div className={classes.hexagonWrapper}>
                      <div
                        className={classNames(classes.hexagon, hexagonActive)}
                      >
                        <img
                          className={classes.hexagonImg}
                          src={`static/images/tab/${v.imgName}`}
                        />
                      </div>
                    </div>
                    <Typography
                      variant="subtitle1"
                      className={classes.hexagonWrapperTitle}
                    >
                      {v.name} 

                      {
                        v.sup &&   <sup className={classNames(
                          classes.supRTM)}>{v.sup}</sup>
                      }
                     
                    </Typography>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
