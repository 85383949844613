import React from "react";
import {
  Grid,
  Box,
  Typography,
  TableCell,
  TableRow,
  withStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "./../styles";
import CardHeader from "../../../../components/CardHeader";
import StepSlider from "../../../../components/StepSlider";
// components

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#4B6B92",
    fontSize: 16,
    fontWeight: 600,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(question, score) {
  return { question, score };
}

export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();

  let meterImage = (v) => {
    return (
      <Box pt={5} pb={5}>
        <Grid container spacing={2} alignItems="center">
          <Grid item sm={12} style={{ textAlign: "center" }}>
            <img src={`data:image/png;base64,${tawScores[v].meterImage}`} />
          </Grid>
        </Grid>
      </Box>
    );
  };
  // console.log(props.selectedResult,props.tawScores)
  let selectedResult = props.selectedResult;
  let tawScores = props.tawScores;

  if (selectedResult) {
    return (
      <>
        <CardHeader
          img={`data:image/png;base64,${selectedResult.imageIcon}`}
          type={selectedResult.categoriesName}
        />
        <Box>
          <Box pt={2} pb={2}>
            <Grid container spacing={2} alignItems="center">
              {selectedResult.options.map((v, i) => {
                return (
                  <>
                    <Grid item sm={9}>
                      <Typography
                        variant="h5"
                        className={classes.resultText}
                        questionId={selectedResult.categoriesCode}
                      >
                        {v.question}
                      </Typography>
                    </Grid>
                    <Grid item sm={3}>
                      <StepSlider
                        onChange={(event, newValue) => {}}
                        min={v.lowScore}
                        max={v.highScore}
                        result={v.resultScore}
                        value={v.resultScore}
                        disabled={true}
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Box>
        </Box>
        {selectedResult.totalCategoryScore >= 1 &&
        selectedResult.totalCategoryScore <= 10
          ? meterImage(0)
          : selectedResult.totalCategoryScore >= 11 &&
            selectedResult.totalCategoryScore <= 20
          ? meterImage(1)
          : selectedResult.totalCategoryScore >= 21 &&
            selectedResult.totalCategoryScore <= 30
          ? meterImage(2)
          : selectedResult.totalCategoryScore >= 31 &&
            selectedResult.totalCategoryScore <= 40
          ? meterImage(3)
          : selectedResult.totalCategoryScore >= 41 &&
            selectedResult.totalCategoryScore <= 50
          ? meterImage(4)
          : ""}
      </>
    );
  } else {
    return (
      <div style={{ textAlign: "center" }}>
        <img
          src="static/images/no-data.svg"
          style={{ width: "70%", height: "auto" }}
        />
      </div>
    );
  }
}
