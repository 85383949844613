import React, { useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import Question from "./qus";
import Answers from "./ans";

import { useUserState } from "./../../context/UserContext";

// import PageTitle from "../../components/PageTitle";
// import TabBar from "../../components/TabBar";

export default function LifeIdealogy(props) {
  const componentRef = useRef();
  const userStateData = useUserState();

  // const pages = [
  //   { name: "Your Definitions", path: "question" },
  //   { name: "Result", path: "result" },
  //   { name: "Archives", path: "" },
  // ];
  // const printPageData = [
  //   {
  //     path: "result",
  //     componentRef: componentRef,
  //     pageName: "life-idealogy",
  //   },
  // ];

  return (
    <>
      {/* <PageTitle title={"Life Idealogy Profiler"} />
      <TabBar
        basePath={props.path}
        pages={pages}
        printPageData={printPageData}
      /> */}
      <Switch>
        <Route
          exact
          path={`${props.path}`}
          render={() => <Redirect to={`${props.path}/question`} />}
        />
        <Route
          exact
          path={`${props.path}/question`}
          render={() => <Question userData={userStateData.clientData || {}} />}
        />
        <Route
          exact
          path={`${props.path}/result`}
          render={() => (
            <Answers
              ref={componentRef}
              userData={userStateData.clientData || {}}
            />
          )}
        />
      </Switch>
    </>
  );
}
