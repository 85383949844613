import React, { createContext, useEffect, useState } from "react";
import {
    Box, Button, Grid,PagePermission,CircularProgress
  } from "@material-ui/core";
  import { makeStyles } from '@material-ui/core/styles';
  import {useHistory,useLocation} from 'react-router-dom';
  import { useSnackbar } from 'notistack';



function PagePermissionContainer() {
  
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

  useEffect(()=>{  
    enqueueSnackbar("Please select client", { variant: 'error',preventDuplicate: true, });
    history.push("/app/dashboard/clients");
  },[])
  return (
    <div></div>

  );
}

export default PagePermissionContainer;