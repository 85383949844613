import React, { useContext } from "react";

// styles
import useStyles from "./styles";

// components
import { Grid, Box, Typography } from "@material-ui/core";
import { PageDataContext } from "../../context/PageDataContext";

export default function PageTitle(props) {
  var classes = useStyles();
  const { p = "", mt = 3, mb = "", background = "", title, propTitle ,sup} = props;
  const { headerData } = useContext(PageDataContext);

  const pageTitle =
    (!propTitle && headerData?.headerText) || title.toUpperCase();
  return (
    <>
      <Box p={p} mt={mt} mb={mb} style={{ background, textAlign: "center" }}>
        <Grid container justify="center">
          <Grid item sm={12}>
            <Typography
              variant="h4"
              style={{
                color: "#193F7C",
                fontWeight: "bold",
                fontSize: "26px",
                margin: "auto",
                marginTop: "22px",
                width: "fit-content",
              }}
            >
              <div>{pageTitle}{sup && <sup>{sup}</sup>} </div>
              <div
                className={classes.underLineSection}
                style={{ width: "80%" }}
              >
                <div className={classes.underLine}></div>
                <span className={classes.dot}></span>
                <div className={classes.underLine}></div>
              </div>
            </Typography>
            {/* <img src="static/images/unde_line.png" /> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
