import React from "react";

import { Tooltip } from "@material-ui/core";
import { Cancel as CancelIcon } from "@material-ui/icons";

// components
export default function GraphIcon(props) {
  console.log("ddd", props);
  return (
    <div className="toolTip" style={{ position: "relative" }}>
      <Tooltip
        title={props.transitionName}
        arrow
        classes={{ tooltip: "toolTipIcon" }}
      >
        <div>
          <div className="cross">
            <CancelIcon
              fontSize="inherit"
              color="secondary"
              style={{ fontSize: 30 }}
              onClick={props.onDelete}
            />
          </div>
          <img
            // src={`data:image/png;base64,${props.imageIcon}`}
            src={`static/images/life-transition/${props.imageIcon}.png`}
            alt={props.transitionName}
          />
        </div>
      </Tooltip>
    </div>
  );
}
