import React from "react";
import { Grid, Box } from "@material-ui/core";
// styles
import useStyles from "./styles";
import PageTitle from "../../../../components/PageTitle";
import CurvedSection from "./curvedSection";
import CategoryRow from "./categoryRow";
// components

export default function Result(props) {
  const classes = useStyles();

  const { topComforts, topConcerns, lifeTransitions } = props;

  const ShortTermPriorities = () => {
    if (topComforts.length == 0 && topConcerns.length == 0) {
      return null;
    }
    return (
      <>
        <PageTitle title={"Top 5 Short-Term Priorities"} propTitle={true} />
        <Box pt={3} pb={3} className={classes.virticalLineSection}>
          <div className={classes.virticalLine}>
            <div className={classes.dot}></div>
          </div>

          {topComforts.length > 0 && (
            <Box pb={4}>
              <CurvedSection name="COMFORTS" />
              <Box pl={3} pr={4}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-start"
                  spacing={1}
                >
                  {topComforts.map((tra) => {
                    const year = `${tra.transitionYear}/${
                      tra.startAge + tra.transitionYear - tra.startYear
                    }`;
                    return (
                      <CategoryRow
                        name={`${tra.transitionName} (${tra.categoryName})`}
                        year={year}
                        icon={`data:image/png;base64,${tra.imageIcon}`}
                      />
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          )}
          {topConcerns.length > 0 && (
            <Box pb={4}>
              <CurvedSection name="CONCERNS" />

              <Box pl={3} pr={4}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-start"
                  spacing={1}
                >
                  {topConcerns.map((tra) => {
                    const year = `${tra.transitionYear}/${
                      tra.startAge + tra.transitionYear - tra.startYear
                    }`;
                    return (
                      <CategoryRow
                        name={`${tra.transitionName} (${tra.categoryName})`}
                        year={year}
                        icon={`data:image/png;base64,${tra.imageIcon}`}
                      />
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </>
    );
  };

  return (
    <>
      {ShortTermPriorities()}
      {/* new Data */}

      <PageTitle title={"All Transitions"} propTitle={true} />
      <Box pt={3} pb={3} className={classes.virticalLineSection}>
        <div className={classes.virticalLine}>
          <div className={classes.dot}></div>
        </div>
        {lifeTransitions.map((row) => {
          return (
            <Box pb={4}>
              <CurvedSection name={`${row.year} / ${row.age} `} />
              <Box pl={3} pr={4}>
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-start"
                  spacing={1}
                >
                  {row.categories.map((cat) => {
                    return (
                      <CategoryRow
                        name={cat.displayName}
                        icon={`data:image/png;base64,${cat.imageIcon}`}
                        transitions={cat.transitions}
                        transitionsPlans={true}
                        user={props.user}
                      />
                    );
                  })}
                </Grid>
              </Box>
            </Box>
          );
        })}
      </Box>
    </>
  );
}
