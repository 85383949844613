import React, { useState } from "react";
import "./style.css";

import { Route, Switch, Redirect } from "react-router-dom";
import { darkGray, white, black, circleTransform } from "./circlesImages";

export default function FirScore(props) {
  const {
    firScore,
    firScoreColor,
    tawAns,
    setSelectedCircle,
    selectedCircle,
  } = props;
  return (
    <svg
      id="master-artboard"
      viewBox="0 0 773.5630493164062 555.4609375"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="620"
      className="print_svg"
    >
      <defs>
        {darkGray}
        {white}
        {black}
      </defs>
      <g transform="matrix(0.6891639232635498, 0, 0, 0.6891639232635498, -33.8142204284668, 9.680089950561523)">
        <g
          id="Fir_copy"
          data-name="Fir copy"
          transform="matrix(1, 0, 0, 1, -32.1052972335965, -300.9871642036463)"
        >
          <g id="Ellipse_1_copy_11" data-name="Ellipse 1 copy 11">
            <path
              className="cls-2"
              d="M626.12,343a146.52,146.52,0,1,1-103.65,42.9A145.71,145.71,0,0,1,626.12,343m0-25c-94.76,0-171.58,76.76-171.58,171.46s76.82,171.45,171.58,171.45,171.57-76.76,171.57-171.45S720.88,318,626.12,318Z"
              style={{ fill: firScoreColor }}
            />
            <g id="_55.25" data-name="55.25">
              <text
                className="cls-24"
                transform="matrix(1, 0, 0, 1.2, 562.16, 427.95)"
                style={{
                  whiteSpace: "pre",
                  fontWeight: "800",
                  fontFamily: '"Myriad Pro","Myriad Pro"',
                  fontSize: "25.74px",
                  fill: "rgb(16, 39, 64)",
                }}
              >
                FIR SCORE
              </text>
            </g>
            <g id="_55.25" data-name="55.25">
              <foreignObject x="526" y="440" width="200" height="100">
                <div className="prefix__txt2">{firScore}</div>
              </foreignObject>
            </g>
          </g>
          <g id="Group_2" data-name="Group 2">
            <g id="Ellipse_1" data-name="Ellipse 1">
              <path
                className="cls-3"
                d="M463.38,538.29a9.88,9.88,0,1,0,9.88,9.88A9.87,9.87,0,0,0,463.38,538.29Z"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Ellipse_1_copy_5" data-name="Ellipse 1 copy 5">
              <path
                className="cls-3"
                d="M787.81,538.29a9.88,9.88,0,1,0,9.88,9.88A9.87,9.87,0,0,0,787.81,538.29Z"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Ellipse_1_copy_3" data-name="Ellipse 1 copy 3">
              <path
                d="M 534.609981 631.299988 A 9.88 9.87 0 0 1 524.72998 641.169988 A 9.88 9.87 0 0 1 514.84998 631.299988 A 9.88 9.87 0 0 1 524.72998 621.429988 A 9.88 9.87 0 0 1 534.609981 631.299988 Z"
                className="cls-3"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Ellipse_1_copy_5-2" data-name="Ellipse 1 copy 5">
              <path
                d="M 734.250005 631.299988 A 9.87 9.88 0 0 1 724.380005 641.179988 A 9.87 9.88 0 0 1 714.510005 631.299988 A 9.87 9.88 0 0 1 724.380005 621.419988 A 9.87 9.88 0 0 1 734.250005 631.299988 Z"
                className="cls-3"
                transform="translate(91.47 1354.26) rotate(-89.87)"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Ellipse_1_copy_4" data-name="Ellipse 1 copy 4">
              <path
                d="M 636.47999 662.789978 A 9.99 10 0 0 1 626.48999 672.789978 A 9.99 10 0 0 1 616.49999 662.789978 A 9.99 10 0 0 1 626.48999 652.789978 A 9.99 10 0 0 1 636.47999 662.789978 Z"
                className="cls-3"
                transform="translate(-38.45 1287) rotate(-89.8)"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
          </g>
          <g id="Group_3" data-name="Group 3">
            <g id="Rectangle_1_copy_3" data-name="Rectangle 1 copy 3">
              <path
                d="M 572.030029 770.419983 H 679.950027 V 775.029983 H 572.030029 V 770.419983 Z"
                className="cls-3"
                transform="translate(-148.98 1395.94) rotate(-89.79)"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Rectangle_1_copy_2" data-name="Rectangle 1 copy 2">
              <path
                d="M 489.67999267578125 676.6400146484375 L 426.3800048828125 765.3800048828125 L 429.80999755859375 767.7999877929688 L 493.1099853515625 679.0599975585938 L 489.67999267578125 676.6400146484375 Z"
                className="cls-3"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Rectangle_1_copy" data-name="Rectangle 1 copy">
              <path
                d="M 408.739990234375 564.9099731445312 L 307.5899963378906 602.3200073242188 L 309.0199890136719 606.239990234375 L 410.1700134277344 568.8300170898438 L 408.739990234375 564.9099731445312 Z"
                className="cls-3"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Rectangle_1_copy_4" data-name="Rectangle 1 copy 4">
              <path
                d="M 785.030029 668.02002 H 789.190029 V 776.160019 H 785.030029 V 668.02002 Z"
                className="cls-3"
                transform="translate(-270.81 574.58) rotate(-34.6)"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Rectangle_1_copy_5" data-name="Rectangle 1 copy 5">
              <path
                d="M 843.989990234375 566.5900268554688 L 842.5700073242188 570.530029296875 L 945.280029296875 607.1699829101562 L 946.7000122070312 603.219970703125 L 843.989990234375 566.5900268554688 Z"
                className="cls-3"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
          </g>
          <g id="Group_1" data-name="Group 1">
            <g id="Ellipse_1_copy_8" data-name="Ellipse 1 copy 8">
              <path
                className="cls-3"
                d="M424.39,546.49a15.71,15.71,0,1,1-15.72,15.7,15.72,15.72,0,0,1,15.72-15.7m0-3a18.71,18.71,0,1,0,18.72,18.7,18.71,18.71,0,0,0-18.72-18.7Z"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Ellipse_1_copy_7" data-name="Ellipse 1 copy 7">
              <path
                className="cls-4"
                d="M424.39,554.92a7.28,7.28,0,1,0,7.28,7.27A7.28,7.28,0,0,0,424.39,554.92Z"
                style={{ fill: "rgb(88, 79, 100)" }}
              />
            </g>
          </g>
          <g id="Group_1_copy_8" data-name="Group 1 copy 8">
            <g id="Ellipse_1_copy_8-2" data-name="Ellipse 1 copy 8">
              <path
                className="cls-3"
                d="M823.69,546.49A15.71,15.71,0,1,1,808,562.19a15.72,15.72,0,0,1,15.72-15.7m0-3a18.71,18.71,0,1,0,18.72,18.7,18.71,18.71,0,0,0-18.72-18.7Z"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Ellipse_1_copy_7-2" data-name="Ellipse 1 copy 7">
              <path
                className="cls-4"
                d="M823.69,554.92a7.28,7.28,0,1,0,7.28,7.27A7.28,7.28,0,0,0,823.69,554.92Z"
                style={{ fill: "rgb(88, 79, 100)" }}
              />
            </g>
          </g>
          <g id="Group_1_copy_5" data-name="Group 1 copy 5">
            <g id="Ellipse_1_copy_8-3" data-name="Ellipse 1 copy 8">
              <path
                className="cls-3"
                d="M502.38,647.28A15.71,15.71,0,1,1,486.66,663a15.73,15.73,0,0,1,15.72-15.71m0-3A18.71,18.71,0,1,0,521.09,663a18.72,18.72,0,0,0-18.71-18.71Z"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Ellipse_1_copy_7-3" data-name="Ellipse 1 copy 7">
              <path
                className="cls-4"
                d="M502.38,655.72a7.27,7.27,0,1,0,7.28,7.27A7.27,7.27,0,0,0,502.38,655.72Z"
                style={{ fill: "rgb(88, 79, 100)" }}
              />
            </g>
          </g>
          <g id="Group_1_copy_7" data-name="Group 1 copy 7">
            <g id="Ellipse_1_copy_8-4" data-name="Ellipse 1 copy 8">
              <path
                className="cls-3"
                d="M745.7,647.28A15.71,15.71,0,1,1,730,663a15.73,15.73,0,0,1,15.72-15.71m0-3A18.71,18.71,0,1,0,764.42,663a18.72,18.72,0,0,0-18.72-18.71Z"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Ellipse_1_copy_7-4" data-name="Ellipse 1 copy 7">
              <path
                d="M 752.980012 662.98999 A 7.28 7.27 0 0 1 745.700012 670.25999 A 7.28 7.27 0 0 1 738.420012 662.98999 A 7.28 7.27 0 0 1 745.700012 655.71999 A 7.28 7.27 0 0 1 752.980012 662.98999 Z"
                className="cls-4"
                style={{ fill: "rgb(88, 79, 100)" }}
              />
            </g>
          </g>
          <g id="Group_1_copy_6" data-name="Group 1 copy 6">
            <g id="Ellipse_1_copy_8-5" data-name="Ellipse 1 copy 8">
              <path
                className="cls-3"
                d="M626,685.78a15.49,15.49,0,1,1-15.5,15.49A15.51,15.51,0,0,1,626,685.78m0-3a18.49,18.49,0,1,0,18.49,18.49A18.49,18.49,0,0,0,626,682.78Z"
                style={{ fill: "rgb(120, 117, 125)" }}
              />
            </g>
            <g id="Ellipse_1_copy_7-5" data-name="Ellipse 1 copy 7">
              <path
                className="cls-4"
                d="M626,694.08a7.19,7.19,0,1,0,7.19,7.19A7.19,7.19,0,0,0,626,694.08Z"
                style={{ fill: "rgb(88, 79, 100)" }}
              />
            </g>
          </g>
          <g id="Circle">
            {tawAns.map((v, i) => (
              <g className="fir_circle" onClick={() => setSelectedCircle(i)}>
                <use
                  id="black"
                  href={selectedCircle === i ? "#black" : "#drak-gary"}
                  transform={circleTransform[i]?.Outer}
                />
                <use
                  id="white"
                  href="#white"
                  transform={circleTransform[i]?.inner}
                />
                <foreignObject 
                  x={circleTransform[i]?.score.x}
                  y={circleTransform[i]?.score.y}
                  width="200"
                  height="50">
                     <div style={{
                    whiteSpace: "pre",
                    fill: "rgb(75, 75, 75)",
                    fontWeight: "bold,Myriad Pro",
                    fontSize: "41.56px",
                    fontFamily: '"Myriad Pro","Myriad Pro"',
                  }}>
                    {v.totalCategoryScore}
                  </div>
                </foreignObject>
                {/* <text
                  className="cls-20"
                  transform={circleTransform[i]?.score}
                  style={{
                    whiteSpace: "pre",
                    fill: "rgb(75, 75, 75)",
                    fontWeight: "bold,Myriad Pro",
                    fontSize: "41.56px",
                    fontFamily: '"Myriad Pro","Myriad Pro"',
                  }}
                >
                  {v.totalCategoryScore}
                </text> */}
                <foreignObject
                  x={circleTransform[i]?.name.x}
                  y={circleTransform[i]?.name.y}
                  width="200"
                  height="50"
                >
                  <div className="prefix__txt7">{v?.name}</div>
                </foreignObject>
                <image
                  width={50}
                  height={50}
                  transform={circleTransform[i]?.image}
                  id="prefix__img1"
                  href={`data:image/png;base64,${v.imageIcon}`}
                />
              </g>
            ))}
          </g>
        </g>
      </g>
    </svg>
  );
}
