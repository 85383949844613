import Dashboard from "../pages/dashboard";
import AddClient from "../pages/addClient";
import EditProfile from "../pages/editProfile";
import FinView from "../pages/finView";
import LifeIdealogy from "../pages/lifeIdealogy";
import AttainmentWheel from "../pages/attainmentWheel";
import LifeTransitionPlanner from "../pages/lifeTransitionPlanner";
import RetireMeant from "../pages/retireMeant";
import MoneyPurposeWheel from "../pages/moneyPurposeWheel";

// import Notifications from "src/pages/notifications";

export default function  router (clientData,userData){

  const hasPermission = (clientData )?true:false
  const {userType} = userData

  return [
    {
      path: "/app/dashboard",
      component: Dashboard,
      title: "LIVES UNDER MANAGEMENT - PROCESS",
      step: false,
      pageCode: "dashboard",
      showNav:true,
      hasPermission:true
    },
    {
      path: "/app/addClient",
      component: AddClient,
      title: "Add Client",
      step: false,
      pageCode: "addClient",
      showNav:false,
      hasPermission: userType == "ADVISOR"

    },
    // {
    //   path: "/app/edit-profile-advisor",
    //   component: EditProfile,
    //   title: "Edit Profile(Advisor)",
    //   step: false,
    //   pageCode: "editAdvisorProfile",
    //   showNav:false,
    //   hasPermission: userType == "ADVISOR"

    // },
    // {
    //   path: "/app/edit-profile-client",
    //   component: EditProfile,
    //   title: "Edit Profile",
    //   step: false,
    //   pageCode: "editClientProfile",
    //   showNav:false,
    //   hasPermission

    // },
    {
      path: "/app/life-idealogy-profiler",
      component: LifeIdealogy,
      step: true,
      stepValue: 0,
      showNav:true,
      title: "Life Idealogy Profiler",
      sup:String.fromCharCode('8482'),
      pageCode: "lifeIdealogyProfiler",
      hasPermission
    },
    {
      path: "/app/finview-meter",
      component: FinView,
      step: true,
      stepValue: 1,
      showNav:true,
      title: "FINVIEW METER",
      sup:String.fromCharCode('174'),
      pageCode: "finviewmeter",
      hasPermission
    },
    {
      path: "/app/attainment-wheel",
      component: AttainmentWheel,
      step: true,
      stepValue: 2,
      showNav:true,
      title: "THE ATTAINMENT WHEEL - N.A.V",
      sup:String.fromCharCode('8482'),
      pageCode: "theattainmentwheel",
      hasPermission
    },
    {
      path: "/app/life-transition-planner",
      component: LifeTransitionPlanner,
      step: true,
      stepValue: 3,
      showNav:true,
      title: "LIFE TRANSITION PLANNER",
      sup:String.fromCharCode('8482'),
      pageCode: "lifetransitionplanner",
      hasPermission
    },
    {
      path: "/app/retire-meant",
      component: RetireMeant,
      step: true,
      stepValue: 4,
      showNav:true,
      title: "RETIRE-MEANT",
      sup:String.fromCharCode('174'),
      pageCode: "retirement",
      hasPermission
    },
    {
      path: "/app/money-purpose-wheel",
      component: MoneyPurposeWheel,
      step: true,
      stepValue: 5,
      showNav:true,
      title: "MONEY PURPOSE WHEEL - C.A.R.E",
      sup:String.fromCharCode('8482'),
      pageCode: "moneypurposewheel",
      hasPermission
    },
  ];

}


