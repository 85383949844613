import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    backgroundColor:"#fff",
  },
  header:{
    backgroundImage: "url('static/images/header.png')",
    backgroundSize:'cover',
    backgroundPosition:"bottom",
    height:"110px",
    lineHeight:"110px",
    backgroundRepeat:"no-repeat",
    padding: "0px 40px", 
    backgroundPositionY: "bottom",
  },
  headerImg:{
    width:"120px",height: "auto",verticalAlign:"middle"
  },
  mainLayout:{
    backgroundImage: "url('static/images/loginBackground.png')",
    backgroundSize:'cover',
    backgroundPosition:"revert",
    backgroundRepeat:"no-repeat",
    padding:"48px 120px",   
  },
  formButtonSection: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft:"25%",
    paddingRight:"25%",
    color:"#fff"
   
  },
  formButton:{
    backgroundColor:theme.palette.warning.main,
    color:"#000" ,
    borderRadius:"13px",
    minWidth: "140px",
    width: "30%",
    marginBottom: "3%"
  },
  signUpForm: {
    marginLeft: "2%",
    display: "flex",
    flexDirection: "column",
    marginTop: "2%"
  },
  formSectionDoubleFields: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "3%",
    marginLeft: "1%"
  },
  formSectionSingleField: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "3%",
    marginLeft: "1%"
  },
  formField: {
    width: "40%",
    flex: "0 1 40%",
    marginTop: "1.5%",
    marginRight: "3%"
  },
  sectionHeader: {
    font: "Arial",
    fontWeight: "bold",
    fontSize: "medium"
  },
  formHeader: {
    font: "Arial",
    fontWeight: "bold",
    fontSize: "medium",
    marginLeft: "2%"
  },
  backdropStyle: {
    zIndex: 1024,
    color: '#000',
    backgroundColor:"#ffffffa1"
  },
}));