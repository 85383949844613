import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  statusSection: {
    "& *": {
      // fontSize:12,
      // fontWeight:700
      color: "#fff",
      width: "75px",
      padding: "5px 0px",
      fontSize: "10px",
      textAlign: "center",
      fontWeight: 600,
      marginLeft: "4px",
    },
    "& span": {
      marginRight: 5,
    },
  },
  low: {
    "& *": {
      backgroundColor: `${theme.palette.red.main}`,
    },
  },
  fair: {
    "& *": {
      backgroundColor: `${theme.palette.orange.main}`,
    },
  },
  average: {
    "& *": {
      backgroundColor: `${theme.palette.yellow.main}`,
    },
  },
  good: {
    "& *": {
      backgroundColor: `${theme.palette.green.main}`,
    },
  },
  excellent: {
    "& *": {
      backgroundColor: `${theme.palette.blue.main}`,
    },
  },
  lowFill: {
    fill: theme.palette.red.main,
  },
  fairFill: {
    fill: theme.palette.orange.main,
  },
  averageFill: {
    fill: theme.palette.yellow.main,
  },
  goodFill: {
    fill: theme.palette.green.main,
  },
  excellentFill: {
    fill: theme.palette.blue.main,
  },
  categoriesName: {
    lineHeight: "20px",
    fontSize: "15px",
    color: "#fff",
    fontWeight: 600,
    textTransform: "capitalize",
  },
  totalScore: {
    fontSize: "70px",
    fill: "#000000",
    fontWeight: "500",
    fontFamily: '"Montserrat-Bold", "Montserrat"',
  },
  navText: {
    fontSize: 40,
    fontWeight: 500,
    fill: "#000000",
    fontFamily: '"Montserrat-Bold", "Montserrat"',
  },
  navPath: {
    transform: "translate(307.5px, 315px)",
    fill: "transparent",
  },
  shp0: {
    opacity: 0.2,
    fill: "#ffffff",
    stroke: "url(#grd1)",
    paintOrder: "stroke fill markers",
    strokeWidth: 6,
  },
  shp1: {
    fill: "url(#grd2)",
    stroke: "#ffffff",
    paintOrder: "stroke fill markers",
    strokeWidth: 6,
  },

  shp2: {
    fill: "url(#grd3)",
    stroke: "#c0bebe",
    paintOrder: "stroke fill markers",
    strokeWidth: 6,
  },

  shp4: {
    opacity: 0.102,
    fill: "#4b4b4b",
  },

  shp5: {
    opacity: 0.102,
    fill: "#ffffff",
  },
}));
