import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  signUpForm: {
    marginLeft: "2%",
    display: "flex",
    flexDirection: "column",
    marginTop: "2%",
  },
  formField: {
    width: "40%",
    flex: "0 1 40%",
    marginTop: "1.5%",
    marginRight: "3%",
  },
  formSectionDoubleFields: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "3%",
    marginLeft: "1%",
  },
  formField: {
    width: "40%",
    flex: "0 1 40%",
    marginTop: "1.5%",
    marginRight: "3%",
  },
  formButtonSection: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-around",
    // alignItems: "center",
    paddingLeft: "25%",
    paddingRight: "25%",
    color: "#fff",
  },
  formButton: {
    backgroundColor: theme.palette.warning.main,
    color: "#000",
    borderRadius: "13px",
    minWidth: "100px",
    width: "20%",
    marginBottom: "3%",
    marginRight: "25%",
  },
  submitButton: {
    backgroundColor: theme.palette.orange.main,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.orange.dark,
    },
  },
  addClientDropzone: {
    minHeight: "200px !important",
  },
  pagination:{
    root: {background:"red"}
  }
}));
