import React, { createContext, useState } from "react";
import { Box, Button, Grid, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import EditProfile from "../pages/editProfile";

const EditProfileContext = createContext({show:false,isClient:false});
const EditProfileDispatchContext = createContext({show:false,isClient:false});

function EditProfileProvider({ children }) {
  const [showEditProfileData, setShowEditProfileData] = useState({show:false,isClient:false});
  // const classes = useStyles();
  const onClose = ()=>{
    setShowEditProfileData({show:false,isClient:false})
  }
  return (
    <EditProfileContext.Provider value={showEditProfileData}>
      <EditProfileDispatchContext.Provider value={setShowEditProfileData}>
        {showEditProfileData.show && <EditProfile isClient={showEditProfileData.isClient} onClose={onClose} />}
        {children}
      </EditProfileDispatchContext.Provider>
    </EditProfileContext.Provider>
  );
}

export { EditProfileProvider, EditProfileContext, EditProfileDispatchContext };
