import React from "react";
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
} from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
// styles
import useStyles from "../../styles";
import CardHeader from "../../../../components/CardHeader";
// components

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: "#4B6B92",
    fontSize: 16,
    fontWeight: 600,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function Dashboard(props) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
    <div id={props.type}>
    <CardHeader img={`data:image/png;base64,${props.icon}`} {...props} />
    </div>
      
      <Box pt={5} pb={5}>
        <Grid container spacing={2} alignItems="center">
          {props.tawScores.map((row) => {
            if (
              row.lowScore <= props.totalCategoryScore &&
              row.highScore >= props.totalCategoryScore
            ) {
              return (
                <>
                  <Grid item sm={12} style={{ textAlign: "center" }}>
                    <img
                      src={`data:image/png;base64,${row.meterImage}`}
                      alt={row.meterImage}
                    />
                  </Grid>
                </>
              );
            }
          })}

          <TableContainer component={Paper} style={{ boxShadow: "none" }}>
            <Table className={classes.Table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>QUESTION</StyledTableCell>
                  <StyledTableCell align="right">YOUR SCORE</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.options.map((row) => {
                  return (
                    <>
                      <StyledTableRow
                        key={row.questionCode}
                        questionId={row.questionCode}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.question}
                        </StyledTableCell>
                        <StyledTableCell align="right">
                          {row.resultScore}
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Box>
    </>
  );
}
