import React, { useState, useEffect } from "react";

import Footer from "../../components/Footer";
import PublicHeader from '../../components/PublicHeader'
import SignUpForm from "./signUpForm";
import useStyles from "./styles";
import {signUpText} from '../../constants'


function SignUp(props) {
  const classes = useStyles();
     
   const textData = {
     heading:signUpText.mainHeadingForAdvisor,
     subHeading:signUpText.subHeading
   }
  
  return (
    <>
      
      <PublicHeader />
        <SignUpForm pageTitle="USER-REGISTRATION" textData={textData} />
      <Footer hideLinks={true} />
    </>
  );
}

export default SignUp
