import React, { useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

export default function FirScore(props) {
  const { visionList, setVisionList } = props;

  const onClick = (id) => {
    const newVisionList = [...visionList];
    newVisionList[id].selected = !newVisionList[id].selected;
    setVisionList(newVisionList);
  };

  return (
    <>
      <svg
        version="1.2"
        baseProfile="tiny-ps"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1070 838"
        width={1100}
        height={900}
        className="print_svg"
      >
        <title>Visioning_new</title>
        <defs>
          <linearGradient
            id="grd1"
            gradientUnits="userSpaceOnUse"
            x1="116.406"
            y1="456.554"
            x2="116.406"
            y2="298.803"
          >
            <stop offset="0%" stopColor="#FCC900" />
            <stop offset="30%" stopColor="#09B977" />
            <stop offset="60%" stopColor="#00B9D7" />
            {/* <stop offset="40%" stop-color="#e10019" /> */}
            <stop offset="100%" stopColor="#FF574D" />
          </linearGradient>
          {visionList.map((v) => {
            return (
              <pattern id={v.code} x={0} y={0} width={1} height={1}>
                <image
                  href={`data:image/png;base64,${v.imageBlob}`}
                  x={0}
                  y={0}
                  width={200}
                  height={200}
                />

                {v.selected && (
                  <>
                    <image href="/static/images/retireMeant/image_bg.png" />
                    <image
                      href="/static/images/retireMeant/checked.png"
                      y={5}
                      x={50}
                    />
                  </>
                )}
              </pattern>
            );
          })}

          <image href="/static/images/retireMeant/text-bg.png" id="textbg" />
        </defs>
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .visioning-text{
            color: rgb(255, 255, 255);
            margin: 2px 14px;
            font-weight: 550;
            font-size: 11px;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
          g g{
            cursor:pointer;
          }
          
          tspan {white-space: pre}
         .txt1 {
                font-size: 18px;
                fill: #ffffff;
                font-weight: 600;
                font-family: "Montserrat-SemiBold", "Montserrat";
                text-anchor: middle
               }
          .txt2 {
                    font-size: 16px;
                    line-height: 23px;
                    fill: #ffffff;
                    font-weight: 600;
                    font-family: "Montserrat-SemiBold", "Montserrat";
                    text-anchor: middle
                  }
          .txt3 {   font-size: 16px;
                    line-height: 24px;
                    fill: #ffffff;
                    font-weight: 600;
                    font-family: "Montserrat-SemiBold", "Montserrat";
                    text-anchor: middle
                }
          .shp4 { opacity: 0.502;
                  fill: #000000
                 }        .txt5 {            font-size: 18px;            line-height: 22px;            fill: #ffffff;            font-weight: 600;            font-family: "Montserrat-SemiBold", "Montserrat";            text-anchor: middle        }        .txt6 {            font-size: 16px;            fill: #ffffff;            font-weight: 600;            font-family: "Montserrat-SemiBold", "Montserrat";            text-anchor: middle        }        .shp8 {            fill: #4b4b4b        }        .shp9 {            fill: #ffffff        }        .txt10 {            font-size: 26px;            fill: #322721;            font-weight: 700;            font-family: "Montserrat-Bold", "Montserrat";            text-anchor: middle        }        .shp11 {            fill: none;            stroke: url(#grd1);            stroke-width: 3        }    `,
          }}
        />
        <g>
          {/* index zero */}
          <g onClick={() => onClick(0)}>
            <path
              fill={`url(#${visionList[0]?.code})`}
              stroke={visionList[0]?.code ? "grey" : ""}
              stroke-width="3"
              d="M381.35 286.01L338.68 370.43L225.2 370.43L183.39 286.01L225.2 201.59L338.68 201.59L381.35 286.01Z"
            />
            {text(visionList[0]?.displayName, 191, 303)}
          </g>

          {/* index one */}
          <g onClick={() => onClick(1)}>
            <path
              fill={`url(#${visionList[1]?.code})`}
              stroke={visionList[1]?.code ? "grey" : ""}
              stroke-width="3"
              d="M381.35 469.34L338.68 553.76L225.2 553.76L183.39 469.34L225.2 384.93L338.68 384.93L381.35 469.34Z"
            />
            {text(visionList[1]?.displayName, 191, 486)}
          </g>

          {/* index two */}
          <g onClick={() => onClick(2)}>
            <path
              fill={`url(#${visionList[2]?.code})`}
              stroke={visionList[2]?.code ? "grey" : ""}
              stroke-width="3"
              d="M546.88 193.07L504.22 277.49L390.73 277.49L348.92 193.07L390.73 108.65L504.22 108.65L546.88 193.07Z"
            />
            {text(visionList[2]?.displayName, 354, 205)}
          </g>

          {/* index three */}

          <g onClick={() => onClick(3)}>
            <path
              fill={`url(#${visionList[3]?.code})`}
              stroke={visionList[3]?.code ? "grey" : ""}
              stroke-width="3"
              d="M546.88 377.25L504.22 461.67L390.73 461.67L348.92 377.25L390.73 292.83L504.22 292.83L546.88 377.25Z"
            />
            {text(visionList[3]?.displayName, 357, 394)}
          </g>

          {/* index four */}

          <g onClick={() => onClick(4)}>
            <path
              fill={`url(#${visionList[4]?.code})`}
              stroke={visionList[4]?.code ? "grey" : ""}
              stroke-width="3"
              d="M546.88 560.58L504.22 645L390.73 645L348.92 560.58L390.73 476.17L504.22 476.17L546.88 560.58Z"
            />
            {text(visionList[4]?.displayName, 357, 578)}
          </g>

          {/* index 5 */}

          <g onClick={() => onClick(5)}>
            <path
              fill={`url(#${visionList[5]?.code})`}
              stroke={visionList[5]?.code ? "grey" : ""}
              stroke-width="3"
              d="M713.27 101.83L670.61 186.25L557.12 186.25L515.31 101.83L557.12 17.41L670.61 17.41L713.27 101.83Z"
            />
            {text(visionList[5]?.displayName, 522, 116)}
          </g>

          {/* index 6 */}
          <g onClick={() => onClick(6)}>
            <path
              fill={`url(#${visionList[6]?.code})`}
              stroke={visionList[6]?.code ? "grey" : ""}
              stroke-width="3"
              d="M713.27 284.31L670.61 368.73L557.12 368.73L515.31 284.31L557.12 199.89L670.61 199.89L713.27 284.31Z"
            />
            {text(visionList[6]?.displayName, 522, 300)}
          </g>

          {/* index 7 */}
          <g onClick={() => onClick(7)}>
            <path
              fill={`url(#${visionList[7]?.code})`}
              stroke={visionList[7]?.code ? "grey" : ""}
              stroke-width="3"
              d="M713.27 466.79L670.61 551.2L557.12 551.2L515.31 466.79L557.12 382.37L670.61 382.37L713.27 466.79Z"
            />
            {text(visionList[7]?.displayName, 523, 483)}
          </g>

          {/* index 8 */}

          <g onClick={() => onClick(8)}>
            <path
              fill={`url(#${visionList[8]?.code})`}
              stroke={visionList[8]?.code ? "grey" : ""}
              stroke-width="3"
              d="M713.27 650.12L670.61 734.54L557.12 734.54L515.31 650.12L557.12 565.7L670.61 565.7L713.27 650.12Z"
            />
            {text(visionList[8]?.displayName, 523, 667)}
          </g>

          {/* index 9 */}

          <g onClick={() => onClick(9)}>
            <path
              fill={`url(#${visionList[9]?.code})`}
              stroke={visionList[9]?.code ? "grey" : ""}
              stroke-width="3"
              d="M878.81 193.07L836.14 277.49L722.66 277.49L680.85 193.07L722.66 108.65L836.14 108.65L878.81 193.07Z"
            />
            {text(visionList[9]?.displayName, 689, 210)}
          </g>

          {/* index 10 */}

          <g onClick={() => onClick(10)}>
            <path
              fill={`url(#${visionList[10]?.code})`}
              stroke={visionList[10]?.code ? "grey" : ""}
              stroke-width="3"
              d="M878.81 377.25L836.14 461.67L722.66 461.67L680.85 377.25L722.66 292.83L836.14 292.83L878.81 377.25Z"
            />
            {text(visionList[10]?.displayName, 689, 394)}
          </g>

          {/* index 11 */}

          <g onClick={() => onClick(11)}>
            <path
              fill={`url(#${visionList[11]?.code})`}
              stroke={visionList[11]?.code ? "grey" : ""}
              stroke-width="3"
              d="M878.81 560.58L836.14 645L722.66 645L680.85 560.58L722.66 476.17L836.14 476.17L878.81 560.58Z"
            />
            {text(visionList[11]?.displayName, 689, 578)}
          </g>

          {/* index 16 */}

          <g onClick={() => onClick(16)}>
            <path
              fill={`url(#${visionList[16]?.code})`}
              stroke={visionList[16]?.code ? "grey" : ""}
              stroke-width="3"
              d="M880.51 741.36L837.85 825.78L724.36 825.78L682.55 741.36L724.36 656.94L837.85 656.94L880.51 741.36Z"
            />
            {
              // text(visionList[12]?.displayName,690, 758)
              text(visionList[16]?.displayName, 690, 758)
            }
          </g>

          {/* index 12 */}

          <g onClick={() => onClick(12)}>
            <path
              fill={`url(#${visionList[12]?.code})`}
              stroke={visionList[12]?.code ? "grey" : ""}
              stroke-width="3"
              d="M1047.51 102.36L1004.85 186.77L891.36 186.77L849.55 102.36L891.36 17.94L1004.85 17.94L1047.51 102.36Z"
            />
            {text(visionList[12]?.displayName, 857, 119)}
          </g>

          {/* index 13 */}

          <g onClick={() => onClick(13)}>
            <path
              fill={`url(#${visionList[13]?.code})`}
              stroke={visionList[13]?.code ? "grey" : ""}
              stroke-width="3"
              d="M1047.75 286.01L1005.09 370.43L891.6 370.43L849.79 286.01L891.6 201.59L1005.09 201.59L1047.75 286.01Z"
            />
            {text(visionList[13]?.displayName, 857, 303)}
          </g>

          {/* index 14 */}
          <g onClick={() => onClick(14)}>
            <path
              fill={`url(#${visionList[14]?.code})`}
              stroke={visionList[14]?.code ? "grey" : ""}
              stroke-width="3"
              d="M1047.75 469.34L1005.09 553.76L891.6 553.76L849.79 469.34L891.6 384.93L1005.09 384.93L1047.75 469.34Z"
            />
            {text(visionList[14]?.displayName, 857, 486)}
          </g>

          {/* index 15 */}
          <g onClick={() => onClick(15)}>
            <path
              fill={`url(#${visionList[15]?.code})`}
              stroke={visionList[15]?.code ? "grey" : ""}
              stroke-width="3"
              d="M1047.75 650.12L1005.09 734.54L891.6 734.54L849.79 650.12L891.6 565.7L1005.09 565.7L1047.75 650.12Z"
            />

            {text(visionList[15]?.displayName, 857, 667)}
          </g>
          <g>
            <path
              className="shp8"
              d="M214.96 377.25L172.3 461.67L58.81 461.67L17 377.25L58.81 292.83L172.3 292.83L214.96 377.25Z"
            />
            <path
              className="shp9"
              d="M115.55 302.21C157.26 302.21 191.07 336 191.07 377.68C191.07 419.36 157.26 453.14 115.55 453.14C73.85 453.14 40.04 419.36 40.04 377.68C40.04 336 73.85 302.21 115.55 302.21Z"
            />
            <text
              style={{ transform: "matrix(0.853,0,0,0.853,116.299,386.88)" }}
            >
              <tspan x={0} y={0} className="txt10" dx="0 1 1 1 1 1 1 1 1">
                VISIONING
              </tspan>
            </text>
            <path
              className="shp11"
              d="M169.22 456.53L62.82 456.55L25.53 377.7L62.68 298.83L169.08 298.8L207.28 377.66L169.22 456.53Z"
            />
          </g>
        </g>
      </svg>
    </>
  );
}

function text(displayName, x, y) {
  return (
    <>
      {displayName && (
        <>
          {" "}
          <use style={{ opacity: 1 }} href="#textbg" x={x} y={y} />
          <foreignObject x={x + 12} y={y} width="160" height="68">
            <div className="visioning-text" style={{ color: "#fff" }}>
              {displayName}{" "}
            </div>
          </foreignObject>
        </>
      )}
    </>
  );
}
