import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  root: {
    display: "flex",
    maxWidth: "100vw",
    overflowX: "hidden",
  },
  content: {
    flexGrow: 1,
    paddingTop: theme.spacing(3),
    width: `calc(100vw - 240px)`,
    minHeight: "100vh",
    marginTop: "10px"
  },
  contentShift: {
    width: `calc(100vw - ${240 + theme.spacing(6)}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hexagonWrapper: {
    textAlign: "center",
    width: "75px",
    height: "75px",
    position: "relative",
    display: "inline-block",
  },
  textAlignCenter:{
    textAlign: "center",
  },
  hexagonWrapperTitle:{
    fontSize:"14px !important" ,
    color: theme.palette.primary.main,
  },
  hexagon: {
    height: "100%",
    width: "calc(100% * 0.57735)",
    display: "inline-block",
    backgroundColor:"#C3C3C3",
    "&:before": {
      position: "absolute",
      top: 0,
      right: "calc((100% / 2) - ((100% * 0.57735) / 2))",
      backgroundColor: "inherit",
      height: "inherit",
      width: "inherit",
      content: '""',
      transform: "rotateZ(60deg)"
    },
    "&:after": {
      position: "absolute",
      top: "0",
      right: "calc((100% / 2) - ((100% * 0.57735) / 2))",
      backgroundColor: "inherit",
      height: "inherit",
      width: "inherit",
      content: '""',
      transform: "rotateZ(-60deg)"
    }
  },
  hexagonActive:{
    background:"#4F8DD6",
  },
  hexagonImg:{
    position: "absolute",
    margin: "auto",
    top:0, 
    left: 0,
     bottom: 0,
      right: 0,
      zIndex: "300",
      textAlign: "center"
  },
  hexagonHr:{
    position: "relative",
    top: "calc(50% - 14.5px )",
    margin:"0px 10%",
   // backgroundColor: "#C3C3C3",
    height: "5px",
  },
  fakeToolbar: {
    // ...theme.mixins.toolbar,
    minHeight: "100px" 
  },
  link: {
    '&:not(:first-child)': {
      paddingLeft: 15
    }
  },

}));
