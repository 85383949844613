import React from "react";
import constant from "./const";
import { Link } from "react-scroll";

function text(displayName, x, y) {
  return (
    <>
      {displayName && (
        <>
          <use style={{ opacity: 1 }} href="#textbg" x={x} y={y} />
          <foreignObject x={x + 12} y={y} width="260" height="80">
            <div
              className="visioning-text"
              style={{
                textAlign: "justify",
                color: "rgb(137, 137, 137)",
                fontFamily: "Myriad Pro",
                fontSize: "20px",
                fontWeight: 400,
                lineHeight: "20.8px",
                display: "-webkit-box",
                WebkitLineClamp: 3, 
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {displayName}
            </div>
          </foreignObject>
        </>
      )}
    </>
  );
}
const success = (data) => (
  <Link to="Success" smooth style={{ cursor: "pointer" }}>
    <g
      id="success"
      transform="matrix(0.988509, 0, 0, 0.988509, 61.122032, -18)"
    >
      <path
        id="Polygon 2"
        d="M738.86 161.86L259.75 161.27L213.55 80.34L260.78 0L739.88 0.59L786.09 81.52L738.86 161.86Z"
        style={{ fill: "none", stroke: constant.successColor, strokeWidth: 10 }}
      />
      <path
        id="Polygon 1 copy"
        d="M724.92 137.01L659.57 137.03L626.84 80.49L659.47 23.93L724.82 23.91L757.55 80.45L724.92 137.01Z"
        style={{ fill: constant.successColor }}
      />
      <image
        width={252}
        height={287}
        id="Logo"
        transform="matrix(0.257999986410141, 0, 0, 0.2540000081062317, 659, 44)"
        href={`data:image/png;base64,${data.image}`}
      />
      <text
        id={1}
        style={{
          transform: "matrix(0.92, 0, 0, 0.92, 275.68, 108.368)",
          whiteSpace: "pre",
        }}
      >
        <tspan
          x={0}
          y={0}
          dx={0}
          style={{
            fill: "rgb(75, 75, 75)",
            fontFamily: '"Myriad Pro"',
            fontSize: 85,
            fontWeight: 800,
            lineHeight: "115.2px",
          }}
        >
          1
        </tspan>
      </text>
      <g id="Text">
        <text
          id="SUCCESS"
          style={{
            transform: "matrix(0.922, 0, 0, 0.923, 468.668, 57.099)",
            whiteSpace: "pre",
          }}
        >
          <tspan
            x={0}
            y={0}
            dx="0 0 0 0 0 0 0"
            style={{
              fill: constant.successColor,
              fontFamily: '"Myriad Pro"',
              fontSize: 30,
              fontWeight: 600,
              lineHeight: 48,
              textAnchor: "middle",
            }}
          >
            SUCCESS
          </tspan>
        </text>

        {text(data.entry, 340, 70)}
      </g>
    </g>
  </Link>
);
const failure = (data) => (
  <Link to="Failure" smooth style={{ cursor: "pointer" }}>
    <g
      id="failure"
      transform="matrix(0.98857, 0, 0, 0.98857, 51.911411, -7.104789)"
    >
      <path
        id="Polygon 2"
        d="M849.32 332.91L370.21 332.33L324.01 251.4L371.23 171.06L850.34 171.64L896.54 252.57L849.32 332.91Z"
        style={{ fill: "none", stroke: constant.failureColor, strokeWidth: 10 }}
      />
      <path
        id="Polygon 1 copy"
        d="M835.38 308.07L770.03 308.08L737.3 251.54L769.93 194.98L835.28 194.97L868.01 251.51L835.38 308.07Z"
        style={{ fill: constant.failureColor }}
      />
      <image
        width={257}
        height={299}
        id="Layer 180"
        transform="matrix(0.24500000476837158, 0, 0, 0.24400000274181366, 770, 215)"
        href={`data:image/png;base64,${data.image}`}
      />
      <text
        id={2}
        style={{
          transform: "matrix(0.92, 0, 0, 0.92, 385.217, 279.883)",
          whiteSpace: "pre",
        }}
      >
        <tspan
          x={0}
          y={0}
          dx={0}
          style={{
            fill: "rgb(75, 75, 75)",
            fontFamily: '"Myriad Pro"',
            fontSize: 85,
            fontWeight: 800,
            lineHeight: "115.2px",
          }}
        >
          2
        </tspan>
      </text>
      <g id="Text">
        <text
          id="FAILURE"
          style={{
            transform: "matrix(0.922, 0, 0, 0.923, 579.125, 228.158)",
            whiteSpace: "pre",
          }}
        >
          <tspan
            x={0}
            y={0}
            dx="0 0 0 0 0 0 0"
            style={{
              fill: constant.failureColor,
              fontFamily: '"Myriad Pro"',
              fontSize: 30,
              fontWeight: 600,
              lineHeight: 48,
              textAnchor: "middle",
            }}
          >
            FAILURE
          </tspan>
        </text>

        {text(data.entry, 450, 240)}
      </g>
    </g>
  </Link>
);

const family = (data) => (
  <Link to="Family" smooth style={{ cursor: "pointer" }}>
    <g
      id="family"
      transform="matrix(0.988509, 0, 0, 0.988509, 22.750734, 2.911737)"
    >
      <path
        id="Polygon 2"
        d="M959.78 504.89L480.67 504.3L434.46 423.37L481.69 343.03L960.8 343.62L1007 424.55L959.78 504.89Z"
        style={{ fill: "none", stroke: constant.familyColor, strokeWidth: 10 }}
      />
      <path
        id="Polygon 1 copy"
        d="M945.84 480.04L880.49 480.06L847.76 423.52L880.38 366.96L945.74 366.94L978.47 423.48L945.84 480.04Z"
        style={{ fill: constant.familyColor }}
      />
      <image
        width={265}
        height={265}
        id="Layer 181"
        transform="matrix(0.2750000059604645, 0, 0, 0.2750000059604645, 878, 387)"
        href={`data:image/png;base64,${data.image}`}
      />
      <text
        id={3}
        style={{
          transform: "matrix(0.92, 0, 0, 0.92, 495.674, 450.939)",
          whiteSpace: "pre",
        }}
      >
        <tspan
          x={0}
          y={0}
          dx={0}
          style={{
            fill: "rgb(75, 75, 75)",
            fontFamily: '"Myriad Pro"',
            fontSize: 85,
            fontWeight: 800,
            lineHeight: "115.2px",
          }}
        >
          3
        </tspan>
      </text>
      <g id="Text">
        <text
          id="FAMILY"
          style={{
            transform: "matrix(0.922, 0, 0, 0.923, 683.138, 400.133)",
            whiteSpace: "pre",
          }}
        >
          <tspan
            x={0}
            y={0}
            dx="0 0 0 0 0 0"
            style={{
              fill: constant.familyColor,
              fontFamily: '"Myriad Pro"',
              fontSize: 30,
              fontWeight: 600,
              lineHeight: 48,
              textAnchor: "middle",
            }}
          >
            FAMILY
          </tspan>
        </text>

        {text(data.entry, 550, 410)}
      </g>
    </g>
  </Link>
);
const money = (data) => (
  <Link to="Money" smooth style={{ cursor: "pointer" }}>
    <g
      id="money"
      transform="matrix(0.98857, 0, 0, 0.98857, 51.911362, 13.786003)"
    >
      <path
        id="Polygon 2"
        d="M849.32 675.94L370.21 675.36L324.01 594.43L371.23 514.09L850.34 514.67L896.54 595.6L849.32 675.94Z"
        style={{ fill: "none", stroke: constant.moneyColor, strokeWidth: 10 }}
      />
      <path
        id="Polygon 1 copy"
        d="M835.38 651.1L770.03 651.11L737.3 594.57L769.93 538.01L835.28 538L868.01 594.54L835.38 651.1Z"
        style={{ fill: constant.moneyColor }}
      />
      <image
        width={257}
        height={237}
        id="Layer 182"
        transform="matrix(0.3070000112056732, 0, 0, 0.30799999833106995, 765, 558)"
        href={`data:image/png;base64,${data.image}`}
      />
      <text
        id={4}
        style={{
          transform: "matrix(0.92, 0, 0, 0.92, 382.455, 621.994)",
          whiteSpace: "pre",
        }}
      >
        <tspan
          x={0}
          y={0}
          dx={0}
          style={{
            fill: "rgb(75, 75, 75)",
            fontFamily: '"Myriad Pro"',
            fontSize: 85,
            fontWeight: 800,
            lineHeight: "115.2px",
          }}
        >
          4
        </tspan>
      </text>
      <g id="Text">
        <text
          id="MONEY"
          style={{
            transform: "matrix(0.922, 0, 0, 0.923, 579.125, 571.184)",
            whiteSpace: "pre",
          }}
        >
          <tspan
            x={0}
            y={0}
            dx="0 0 0 0 0"
            style={{
              fill: constant.moneyColor,
              fontFamily: '"Myriad Pro"',
              fontSize: 30,
              fontWeight: 600,
              lineHeight: 48,
              textAnchor: "middle",
            }}
          >
            MONEY
          </tspan>
        </text>

        {text(data.entry, 450, 580)}
      </g>
    </g>
  </Link>
);
const career = (data) => (
  <Link to="Career" smooth style={{ cursor: "pointer" }}>
    <g
      id="career"
      transform="matrix(0.988509, 0, 0, 0.988509, 61.121967, 24.732887)"
    >
      <path
        id="Polygon 2"
        d="M738.86 847L259.75 846.41L213.55 765.48L260.78 685.14L739.88 685.73L786.09 766.66L738.86 847Z"
        style={{ fill: "none", stroke: constant.careerColor, strokeWidth: 10 }}
      />
      <path
        id="Polygon 1 copy"
        d="M724.92 822.15L659.57 822.17L626.84 765.63L659.47 709.07L724.82 709.05L757.55 765.59L724.92 822.15Z"
        style={{ fill: constant.careerColor }}
      />
      <image
        width={277}
        height={277}
        id="Layer 183"
        transform="matrix(0.2709999978542328, 0, 0, 0.2709999978542328, 652, 734)"
        href={`data:image/png;base64,${data.image}`}
      />
      <text
        id={5}
        style={{
          transform: "matrix(0.92, 0, 0, 0.92, 273.839, 793.51)",
          whiteSpace: "pre",
        }}
      >
        <tspan
          x={0}
          y={0}
          dx={0}
          style={{
            fill: "rgb(75, 75, 75)",
            fontFamily: '"Myriad Pro"',
            fontSize: 85,
            fontWeight: 800,
            lineHeight: "115.2px",
          }}
        >
          5
        </tspan>
      </text>
      <g id="Text">
        <text
          id="CAREER"
          style={{
            transform: "matrix(0.922, 0, 0, 0.923, 473.27, 740.404)",
            whiteSpace: "pre",
          }}
        >
          <tspan
            x={0}
            y={0}
            dx="0 0 0 0 0 0"
            style={{
              fill: constant.careerColor,
              fontFamily: '"Myriad Pro"',
              fontSize: 30,
              fontWeight: 600,
              lineHeight: 48,
              textAnchor: "middle",
            }}
          >
            CAREER
          </tspan>
        </text>

        {text(data.entry, 340, 750)}
      </g>
    </g>
  </Link>
);
export default { success, failure, family, money, career };
