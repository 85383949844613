import React, { useState, useContext } from "react";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Snackbar,
} from "@material-ui/core";
import InfoDialog from "./../Dialog/info";
import DeleteModal from "./../Modal/deleteModal";
import { getRequest, postRequest } from "./../../../../utils/API";
import ApiUri from "./../../../../constants/apiUri";
import { BackdropDispatchContext } from "../../../../context/BackdropContext";
import { useSnackbar } from 'notistack';
 import {Chat as ChatIcon} from '@material-ui/icons';
 import ClassNames from 'classnames'


// styles
import useStyles from "./styles";
import { set, cloneDeep } from "lodash";
// components

export default function TransitionsPlans(props) {
  const classes = useStyles();
  const [displayEdit, setDisplayEdit] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [newComment, setNewComment] = useState(props.comment);
  const setBackdrop = useContext(BackdropDispatchContext);
  const [disabledButton, setDisabledButton] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  let { transitionCode, transitionYear, code, transitionName,comment } = props;

  let commentBox = async () => {
    // if (!displayEdit) {
    //   setBackdrop(true);
    //   setDisabledButton(true);
    //   let transitionCodes = { tc: transitionCode, ty: transitionYear };

    //   let resData = await getRequest(ApiUri.lifeline.comment, {
    //     params: transitionCodes,
    //   });
    //   if (resData.status === 200) {
    //     const commmitData = resData.data.data?.[0]?.comment || "";
    //     setcomment(commmitData);
    //   } else {
    //   }

    //   setBackdrop(false);
    //   setDisabledButton(false);
    // }

    // setDisplayEdit(!displayEdit);
  };

  const commentOnSubmit = async () => {
    let data = {
      transitionCode,
      comment:newComment,
      transitionYear,
    };
    setBackdrop(true);
    setDisabledButton(true);
    let reqData = await postRequest(ApiUri.lifeline.commentSubmit, data);
    if (reqData.status === 201) {
      enqueueSnackbar("Data Save Successfully");
    } else {
      enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });

    }
    setBackdrop(false);
    setDisabledButton(false);
  };

  return (
    <>
      <Box>
        {selectedInfo !== null && (
          <InfoDialog
            setSelectedInfo={setSelectedInfo}
            transitionCode={transitionCode}
            setBackdrop={setBackdrop}
            transitionName={transitionName}
          />
        )}
        <DeleteModal
          open={open}
          setOpen={setOpen}
          setDisabledButton={setDisabledButton}
          setDisplayEdit={setDisplayEdit}
          setNewComment={setNewComment}
          setBackdrop={setBackdrop}
          {...props}
        />
        <Box pl={18} pr={3}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item xs={10}>
              <Typography
                variant="p"
                component="p"
                className={classes.actionName}
              >
                {transitionName}
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.action}>
              <div className={classes.actionImages}>
                <span onClick={()=>{
                  setDisplayEdit(!displayEdit)
                }}>
                  <ChatIcon className={ClassNames(comment && classes.hasCommentIcon)} />
                  {/* <img src="static/images/lifeline/edit.png" alt="edit" /> */}
                </span>
                <span
                  onClick={() => {
                    setSelectedInfo();
                  }}
                >
                  {/* <img src="static/images/lifeline/info.png" alt="info" /> */}
                </span>
                {/* <span>
                  <img
                    src="static/images/lifeline/delete.png"
                    alt="delete"
                    onClick={handleOpen}
                  />
                </span> */}
              </div>
            </Grid>
          </Grid>
        </Box>
        {displayEdit && (
          <Box pl={10} pr={3} mt={2} mb={4}>
            <TextField
              id="outlined-multiline-static"
              multiline
              rows={15}
              fullWidth
              value={newComment}
              variant="outlined"
              onChange={(e) =>  setNewComment(e.target.value)}
            />

            <Box mt={2}>
              <Grid
                container
                direction="row"
                justify="flex-end"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.resultButton}
                    onClick={handleOpen}
                    disabled={disabledButton}
                  >
                    Delete Comment
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    diabled={false}
                    onClick={commentOnSubmit}
                    className={classes.submitButton}
                    disabled={disabledButton}
                  >
                    Submit
                  </Button>{" "}
                </Grid>
              </Grid>
            </Box>
          </Box>
        )}

        <div className={classes.horigentalLine}>
          <div className={classes.startDot}></div>
          <div className={classes.endDot}></div>
        </div>
        
      </Box>
    </>
  );
}
