import React, { useEffect, useState, useContext } from "react";
import {
  Grid,
  Box,
  Badge,
  Paper,
  Typography,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import PieChart from "./pieChart";
import Result from "../components/Result";
import useStyles from "./styles";
import ApiUri from "../../../constants/apiUri";
import {printSectionTypes} from "../../../constants/index"
import { getRequest, postRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import { isEmpty, find, flatMap, chain } from "lodash";
import {DataNotFoundDispatchContext}  from '../../../context/DataNotFoundContext'
// import PrintDataComponent from '../../../PrintData/moneyPurposeWheel'
import { PrintDataDispatchContext } from "../../../context/PrintDataContext";




let graphSection = ["low", "fail", "average", "good", "excellent"];

export default function Answers(props) {
  const setBackdrop = useContext(BackdropDispatchContext);
  const classes = useStyles();
  const [selectedResult, setSelectedResult] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [tawScores, setTawScores] = useState([]);
  const [categories, setCategories] = useState([]);
  // const [MoneyOutflows,setMoneyOutflows]=useState({})
  const [moneyOutflows, setMoneyOutflows] = useState({});
  const [saving, setSaving] = useState({});
  const [income, setIncome] = useState({});
  const [totalOutflowsBalance, setTotalOutflowsBalance] = useState({
    monthlyAmount: 0,
    yearlyAmount: 0,
  });
  const [totalSavingsBalance, setTotalSavingsBalance] = useState({
    monthlyAmount: 0,
    yearlyAmount: 0,
  });
  const setDataNotFound = useContext(DataNotFoundDispatchContext);
  const setPrintData = useContext(PrintDataDispatchContext)
  const defaultValue = {
    monthlyAmount: 0,
    yearlyAmount: 0,
  }


  useEffect(() => {
    if (!isEmpty(income) && !isEmpty(moneyOutflows)) {
      const monthlyAmount =
        income.totalMonthlyAmount - moneyOutflows?.totalMonthlyAmount;
      const yearlyAmount =
        income.totalYearlyAmount - moneyOutflows?.totalYearlyAmount;

      setTotalOutflowsBalance({ monthlyAmount, yearlyAmount });
    }
  }, [income, moneyOutflows]);

  useEffect(() => {
    if (!isEmpty(saving) && !isEmpty(moneyOutflows)) {
      const outflowSaving = flatMap(moneyOutflows.groups, (item) =>
        chain(item.questions)
          .filter({ code: "SAVINGS" })
          .map((v) => ({
            monthlyAmount: v.questionMpvAmount.monthlyAmount,
            yearlyAmount: v.questionMpvAmount.yearlyAmount,
          }))
          .value(),
      );

      if (outflowSaving.length) {
        const monthlyAmount =
          outflowSaving[0].monthlyAmount - saving?.totalMonthlyAmount;
        const yearlyAmount =
          outflowSaving[0].yearlyAmount - saving?.totalYearlyAmount;
        setTotalSavingsBalance({ monthlyAmount, yearlyAmount });
      }
    }
  }, [saving, moneyOutflows]);
  const getQusList = async () => {
    setBackdrop(true);
    try {
      let reqData = await getRequest(ApiUri.moneyPueposeWheel.qustionList);
      if (reqData.status == 200) {
        let data = reqData.data.data;
        // console.log("reqData", data);
        getUserResult();
        setCategories(data.categoryContainer.categories);
        const moneyOutflowsData = data.categoryContainer.categories.find(
          (v) => v.code === "MO-WHL",
        );
        const savingData = data.categoryContainer.categories.find(
          (v) => v.code === "SAV-WHL",
        );
        const incomeData = data.categoryContainer.categories.find(
          (v) => v.code === "INC-WHL",
        );
        setMoneyOutflows(moneyOutflowsData);
        setSaving(savingData);
        setIncome(incomeData);
        setBackdrop(false);
      } else {
        setBackdrop(false);
        setDataNotFound(true);
      }
    } catch (error) {
      setBackdrop(false);
        setDataNotFound(true);
      console.log(error);
    }
  };
  const getUserResult = async () => {
    setBackdrop(true);
    let reqData = await getRequest(ApiUri.nav.qustionList);
    if (reqData.status == 200) {
      let data = reqData.data.data;
      // console.log("req",data.tawResult.questionAnswerMappingByUser)
      let selectedData = data.tawResult?.questionAnswerMappingByUser.categories?.map(
        (v, i) => {
          return {
            categoriesCode: v.code,
            categoriesName: v.displayName,
            options: v.questions,
            imageIcon: v.imageIcon,
            totalCategoryScore: v.totalCategoryScore,
          };
        },
      );

      // console.log("reqData", reqData.data.data.questionAnswerMappingByUser.categories)
      setSelectedResult(selectedData);

      setTawScores(data.tawResult?.questionAnswerMappingByUser.tawScores);
      setTotalScore(data.tawResult?.questionAnswerMappingByUser.totalScore);
      setBackdrop(false);
    } else {
    }
  };

  useEffect(() => {
    // getUserResult();
    getQusList();
  }, []);


  useEffect(()=>{
   
     if(categories?.length )
     {
      setPrintData(printSectionTypes.mpw)

     }


  },[income,moneyOutflows,totalOutflowsBalance,saving,totalSavingsBalance])

  return (
    <>
      {categories?.length > 0 ? (
        <>
          <Box>
            <Result mpvResult={income || defaultValue} titleName="income details" />
          </Box>
          <Divider />
          <Box mt={7}>
            <PieChart
              moneyOutflows={moneyOutflows || defaultValue}
              titleName="OUTFLOW"
              graphSection={graphSection}
              totalScore={totalScore}
              selectedResult={selectedResult}
              tawScores={tawScores}
              className="print_svg"
            />
            <Result
              mpvResult={moneyOutflows || defaultValue}
              totalBalance={totalOutflowsBalance}
              titleName="details"
            />
          </Box>
          <Divider />
          <Box mt={7}>
            <PieChart
              moneyOutflows={saving || defaultValue}
              titleName="SAVING"
              graphSection={graphSection}
              totalScore={totalScore}
              selectedResult={selectedResult}
              tawScores={tawScores}
              className="print_svg"
            />
            <Result
              mpvResult={saving || defaultValue}
              titleName="details"
              totalBalance={totalSavingsBalance}
            />
          </Box>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
