import React from "react";
import SignUpForm from "../signUp/signUpForm";
import {signUpText} from '../../constants'

function AddClient(props) {
  const textData = {
    subHeading:signUpText.subHeadingForClientregister
  }
  return (
    <>
      <SignUpForm isClient={true} textData={textData}/>
    </>
  );
}

export default AddClient;
