import React, { useEffect, useState } from "react";
import {
  Grid,
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Popper,
  Paper,
  Fade,
} from "@material-ui/core";
import {
  Person,
  ArrowDropDown,
  GetApp as GetAppIcon,
  Publish as PublishIcon,
  // ArrowBack,
} from "@material-ui/icons";
import useStyles from "./styles";
import Date from "../Date";
import Step from "../Step";
import Tables from "../Table";
import { postRequest } from "../../../utils/API";
import ApiUri from "../../../constants/apiUri";
import { useUserState } from "../../../context/UserContext";
import { DropzoneDialogBase, DropzoneAreaBase } from "material-ui-dropzone";
import { useSnackbar } from "notistack";

export default function ImportClient({ open, handleClickOpen,getClientList }) {
  const classes = useStyles();
  const [fileObjects, setFileObjects] = React.useState([]);
  const { userData } = useUserState();
  const [disableCloseButton, setDisableCloseButton] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSave = async () => {
    try {
      setDisableCloseButton(true);
      const reqUrl = ApiUri.client.upload;
      var formData = new FormData();
      console.log("fileObjects", fileObjects[0].file);
      formData.append("file", fileObjects[0].file);
      const resData = await postRequest(reqUrl, formData, {
        "user-code": userData.code,
        "Content-Type": "multipart/form-data",
      });

      if (resData.status == 200) {
        console.log("save", resData);
        handleClickOpen();
        getClientList()
        enqueueSnackbar("Client Added Successfully");
        // setClientList(data);
        //setUserProfile(data);
      } else {
        enqueueSnackbar("Please try again", {
          variant: "error",
          preventDuplicate: true,
        });
      }
      setDisableCloseButton(false);
    } catch (error) {
      setDisableCloseButton(false);
      enqueueSnackbar("Please try again", {
        variant: "error",
        preventDuplicate: true,
      });
      console.log("error", error);
    }
  };

  return (
    <>
      <Dialog open={open} fullWidth={true} maxWidth="sm">
        <DialogContent>
          <DialogContentText>
            Please upload your end in .xlsx Format only
          </DialogContentText>
          <DropzoneAreaBase
            dropzoneText={"Drag and drop file here or click"}
            onChange={(files) => console.log("Files:", files)}
            onAlert={(message, variant) =>
              console.log(`${variant}: ${message}`)
            }
            acceptedFiles={[
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            ]}
            fileObjects={fileObjects}
            filesLimit={1}
            maxFileSize={5000000}
            onAdd={(newFileObjs) => {
              console.log("onAdd", newFileObjs);
              setFileObjects(newFileObjs);
            }}
            onDelete={(deleteFileObj) => {
              console.log("onDelete", deleteFileObj);
              setFileObjects([]);
            }}
            showPreviews={false}
            showFileNamesInPreview={false}
            showPreviewsInDropzone={true}
            useChipsForPreview
            previewGridProps={{
              container: { spacing: 1, direction: "column" },
            }}
            previewChipProps={{ classes: { root: classes.previewChip } }}
            dropzoneClass={classes.addClientDropzone}
          />
          <a href="/static/sample/add-client.xlsx">Download Sample</a>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.submitButton}
            onClick={onSave}
            disabled={fileObjects.length === 0}
          >
            Submit
          </Button>
          <Button disabled={disableCloseButton} onClick={handleClickOpen}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
