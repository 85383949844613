import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  questionHeading: {
    // border:"1px solid #aed6ff",
    color: "#767676",
    fontSize: 16,
    fontWeight: 600,
    padding: 5,
    textAlign: "center",
    margin:"20px auto",
    width:'80%',
  },
}));
