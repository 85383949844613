import React, { useEffect, useRef } from "react";

import GraphSquare from "./graphSquare";

// components
export default function Graph(props) {
  const halfSection = props.initialBoard.length / 2;

  const scrollRef = useRef();
  const useScroll = useRef(false);

  const { goalType, setGoalType } = props;

  const minWidth = !goalType ? "20%" : "6.66666666667%";
  const graphSection = () => {
    const positiveData = [];
    const negativeData = [];
    const yearData = [];
    props.initialBoard.forEach((x, rowIndex) => {
      if (rowIndex < halfSection) {
        positiveData.push(
          <div className="graph_flux">
            {x.row.map((y, innerIndex) => {
              return (
                <GraphSquare
                  minWidth={minWidth}
                  data={y}
                  key={`${rowIndex}_${innerIndex}`}
                  value={`${rowIndex}_${innerIndex}`}
                  onDelete={(key) => {
                    props.onDelete({
                      priorityIndex: rowIndex,
                      yearIndex: innerIndex,
                      rowIndex: key,
                    });
                  }}
                />
              );
            })}
          </div>,
        );
      } else {
        negativeData.push(
          <div className="graph_flux ">
            {x.row.map((y, innerIndex) => {
              return (
                <GraphSquare
                  minWidth={minWidth}
                  data={y}
                  key={`${rowIndex}_${innerIndex}`}
                  value={`${rowIndex}_${innerIndex}`}
                  onDelete={(key) => {
                    props.onDelete({
                      priorityIndex: rowIndex,
                      yearIndex: innerIndex,
                      rowIndex: key,
                    });
                  }}
                />
              );
            })}
          </div>,
        );
      }
      if (rowIndex === halfSection) {
        yearData.push(
          <div className="graph_flux">
            {props.yearData.map((year, i) => (
              <div className="year-line" style={{ minWidth: minWidth }}>
                <div className="year-value">{year}</div>
              </div>
            ))}
          </div>,
        );
      }
    });

    return (
      <>
        <div className="positiveSection">
          {positiveData}
          <div className="comfortsText">COMFORTS</div>
        </div>
        <div className="yearSection">
          {yearData}
          <div className="year_notation">
            Years/
            <br />
            <span style={{ color: "#00b7d7" }}>Age</span>
          </div>
        </div>
        <div className="negativeSection">
          {negativeData}
          <div className="ConcernsText">CONCERNS</div>
        </div>
      </>
    );
  };

  const onScroll = (e) => {
    const {currentYear , startYear} = props.userDetails;
      const scrollPosition = scrollRef.current.scrollLeft;
    if(useScroll.current == true)
    {
      
      const shortTermUpto =  ((currentYear -  startYear) + 5)*214;
      if (!goalType && scrollPosition >= shortTermUpto) {
        setGoalType(true);
      }
    }
    else if(scrollPosition > (currentYear -  startYear)*214)
    {
      useScroll.current = true
    }
  };

  useEffect(()=>{
    const {currentYear , startYear} = props.userDetails;
    
    
    scrollRef.current.scrollLeft = (currentYear -  startYear)*214;
  },[])

  return (
    <>
      <div className="graph_notation">
        <div className="priorityHeading">
          <div className="priorityValue">PRIORITY</div>
        </div>
        {props.initialBoard.map((v, i) => {
          return (
            <>
              {halfSection === i && (
                <div style={{ marginTop: "0px" }} className="notation_div_zero">
                  <div className="priorityValue">0</div>
                </div>
              )}
              <div
                style={{ marginTop: halfSection === i ? "20px" : "0px" }}
                className="notation_div"
              >
                <div className="priorityValue"> {v.priorityValue}</div>
              </div>
            </>
          );
        })}
      </div>
      <div className="graph_section" onScroll={onScroll} ref={scrollRef}>
        {graphSection()}
      </div>
    </>
  );
}
