import React from "react";
import ApiUri from "../constants/apiUri";
import { postRequest } from "../utils/API";

var defaultUserData = {
  id: "",
  code: "",
  firstName: "",
  middleName: "",
  lastName: "",
  userType: "",
  userName: "",
  status: "",
};
var UserStateContext = React.createContext(defaultUserData);
var UserDispatchContext = React.createContext(defaultUserData);

const setUSerData = (userDetails) => {
  localStorage.setItem("id_token", 1);
  localStorage.setItem("userData", JSON.stringify(userDetails));
  if (userDetails.userType !== "ADVISOR") {
    localStorage.setItem("clientData", JSON.stringify(userDetails));
  }
  window.location.reload();
};
const setClietData = (clientData, history) => {
  localStorage.setItem("clientData", JSON.stringify(clientData));
  history.push("/app/dashboard");
  window.location.reload();
};

const getUserData = () => {
  return {
    isAuthenticated: !!localStorage.getItem("id_token") || false,
    userData: JSON.parse(localStorage.getItem("userData")) || defaultUserData,
    clientData: JSON.parse(localStorage.getItem("clientData")),
  };
};

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      return { ...state, isAuthenticated: false };
      // throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, getUserData());

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  signOut,
  getUserData,
  setUSerData,
  setClietData,
  exit,
};

// ###########################################################

async function loginUser(
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError,
) {
  // console.log("userDispatch","status")
  setError(false);
  setIsLoading(true);
  if (!!login && !!password) {
    let data = { userName: login, password: password };
    let reqData = await postRequest(ApiUri.auth.login, data);
    // console.log("$$$$$$$$$$$$$$$$$$", reqData)
    if (reqData.status == 200) {
      console.log("reqData4555", reqData.headers);

      setUSerData(reqData.data.data.userDetails);

      setTimeout(() => {
        setError(false);
        setIsLoading(false);
        dispatch({ type: "LOGIN_SUCCESS" });
      }, 100);
    } else {
      setError(true);
      setIsLoading(false);
      // dispatch({ type: "LOGIN_FAILURE" });
    }
  } else {
    // console.log("ddd")
    setError(true);
    setIsLoading(false);
    // dispatch({ type: "LOGIN_FAILURE" });
  }
}

function signOut(dispatch, history) {
  localStorage.removeItem("id_token");
  localStorage.removeItem("userData");
  localStorage.removeItem("clientData");
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/login");
  window.location.reload();
}

function exit(dispatch, history) {
  localStorage.removeItem("clientData");
  history.push("/app/dashboard/clients");
  window.location.reload();
}
