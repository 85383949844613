export const backgroundPath = [
  "M0,376.435h376.667L188.332,49.768C188.332,49.768,4.999,151.768,0,376.435z",
  "M193.8,48.063l188.334,326.204L570.867,47.829C570.867,47.829,390.867-59.941,193.8,48.063z",
  "M764.667,376.435H388L576.335,49.768C576.335,49.768,759.668,151.768,764.667,376.435z",
  "M764.667,381.768H388l188.335,326.667C576.335,708.435,759.668,606.435,764.667,381.768z",
  "M193.799,711.537l188.334-326.203L570.868,711.77C570.868,711.77,390.866,819.541,193.799,711.537z",
  "M0,381.768h376.667L188.332,708.435C188.332,708.435,4.999,606.435,0,381.768z",
];
export const graphSection = [
  "lowFill",
  "fairFill",
  "averageFill",
  "goodFill",
  "excellentFill",
];
export const getScoreClass = (totalCategoryScore) => {
  const selectedItemScore = Math.ceil(totalCategoryScore / 10);
  console.log("selectedItemScore", selectedItemScore);
  return graphSection[selectedItemScore - 1] || "";
};

export const circleTransform = [
  {
    name: {
      x: 170,
      y: 285,
    },
    image: "translate(230 221)",
  },
  {
    name: {
      x: 415,
      y: 149,
    },
    image: "translate(485 80)",
  },
  {
    name: {
      x: 665,
      y: 290,
    },
    image: "translate(738 226)",
  },
  {
    name: {
      x: 666,
      y: 557,
    },
    image: "translate(728 503)",
  },
  {
    name: {
      x: 414,
      y: 711,
    },
    image: "translate(486 649)",
  },
  {
    name: {
      x: 180,
      y: 565,
    },
    image: "translate(234 505)",
  },
];
