import React from "react";

import { Slider, withStyles } from "@material-ui/core";

const MakeStepSlider = withStyles((theme) => ({
  root: {
    color: "#ffffff00",
    height: 18,
    padding: "15px 0",
  },
  thumb: {
    height: 32,
    width: 21,
    borderRadius: 0,
    backgroundColor: "#fff",
    boxShadow: "none !important",
    position: "relative",
    backgroundImage: "url('static/images/pentagon.png')",
    marginTop: -10,
  },
  active: {},
  valueLabel: {
    position: "absolute",
    top: 6,
    left: -6,
    fontSize: 15,
    fontWeight: 600,
    "& *": {
      background: "transparent",
      color: "#000",
    },
  },
  rail: {
    height: 10,
    opacity: 1,
    backgroundColor: "#bfbfbf",
    width: "calc(100% + 8px)",
    borderRadius: 20,
    backgroundImage: `linear-gradient(to right, ${theme.palette.red.main} ,${theme.palette.orange.main}, ${theme.palette.yellow.main} ,${theme.palette.green.main} , ${theme.palette.blue.main})`,
  },
  mark: {
    backgroundColor: "#000",
    height: 5,
    width: 5,
    borderRadius: "100%",
    marginTop: 25,
    marginLeft: 2,
  },
  markActive: {
    opacity: 1,
    backgroundColor: "#000",
  },
}))(Slider);

export default function StepSlider(props) {
  return (
    <div style={{ width: "calc(100% - 8px)" }}>
      <MakeStepSlider
        aria-label="ios slider"
        step={1}
        marks
        min={props.min}
        max={props.max}
        value={props.value}
        defaultValue={props.result ? props.result : props.min}
        valueLabelDisplay="on"
        onChange={(event, newValue) => {
          props.onChange(newValue, props.questionCode);
        }}
      />
    </div>
  );
}
