import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({

    tabBar:{
        padding: '20px 20px',
        // background: "#F1F8FF",
        marginBottom:0
      },
      buttonList:{
        '& a' :{
              position: 'relative',
              borderRadius: 0,
              padding: 0,
              borderBottom: `3px solid #F1F8FF`,
              color:`${theme.palette.primary.main}`,
              background: "transparent",
              marginRight:50,
              fontWeight:700,
             "&:hover": {
              background: "transparent"
              }
         },
         
         
      
      },
      

activeLink:{
    borderBottom: `3px solid ${theme.palette.primary.main} !important`,
    '&::before': {
      content: '""',
      display: 'block',
      width: 0,
    height: 0,
    borderLeft: "5px solid transparent",
    borderRight: "5px solid transparent",
    borderTop: `10px solid ${theme.palette.primary.main}`,
    position: "absolute",
    bottom:-13
    }
   },
      
 
 
 
 


}));
