import React, { useEffect, useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import FIR from "./FIR";
import Impression from "./Impression";
import Visioning from "./Visioning";
import WeeklyScheduler from "./WeeklyScheduler";
import classNames from "classnames";
import { useUserState } from "./../../context/UserContext";
import { PrintDataDispatchContext } from "./../../context/PrintDataContext";

import {printSectionTypes} from "./../../constants/index"


// styles
// import useStyles from "./styles";

// import PageTitle from "../../components/PageTitle";
// import TabBar from "../../components/TabBar";

export default function AttainmentWheel(props) {
  const userStateData = useUserState();
  const setPrintData = useContext(PrintDataDispatchContext);

  useEffect(()=>{
    setPrintData(printSectionTypes.retireMeant)

  },[])

  // const pages = [
  //   { name: "fir", path: "fir" },
  //   { name: "Impression", path: "impression" },
  //   { name: "vision", path: "vision" },
  //   { name: "Weekly Scheduler", path: "weekly-scheduler" },
  // ];

  return (
    <>
      {/* <PageTitle title={"RETIRE-MEANT"} />
      <TabBar basePath={props.path} pages={pages} /> */}
      <Switch>
        <Route
          exact
          path={`${props.path}`}
          render={() => <Redirect to={`${props.path}/fir`} />}
        />
        <Route
          exact
          path={`${props.path}/fir`}
          render={() => (
            <FIR userData={userStateData.clientData || {}} path={props.path} />
          )}
        />
        <Route
          exact
          path={`${props.path}/impression`}
          render={() => (
            <Impression
              userData={userStateData.clientData || {}}
              path={props.path}
            />
          )}
        />
        <Route
          exact
          path={`${props.path}/vision`}
          render={() => (
            <Visioning
              userData={userStateData.clientData || {}}
              path={props.path}
            />
          )}
        />
        <Route
          exact
          path={`${props.path}/weekly-scheduler`}
          render={() => (
            <WeeklyScheduler
              userData={userStateData.clientData || {}}
              path={props.path}
            />
          )}
        />
      </Switch>
    </>
  );
}
