import React, { useEffect, useState, useContext,useRef } from "react";
import { Route, Switch, Link } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import useStyles from "./styles";
import ApiUri from "../../../constants/apiUri";
import { getRequest, postRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Heading from "./../components/Heading";
import Table from "../components/Table";
import { cloneDeep } from "lodash";
import QuestionHeading from "../../../components/QuestionHeading";
import {  Prompt } from 'react-router-dom';
import { useSnackbar } from 'notistack';


function roundOf(number) {
  return Math.round(+parseFloat(number).toFixed(2) * 100) / 100;
}


const useBeforeUnload = ({ when, message }) => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault()
      event.returnValue = message
      return message
    }

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [when, message])
}

export default function Question(props) {
  const setBackdrop = useContext(BackdropDispatchContext);
  const classes = useStyles();
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState(null);
  const [qustionList, setQustionList] = useState([]);
  const [copyQustionList, setCopyQustionList] = useState([]);
  const [totalIncome, setTotalIncome] = useState(0);
  const [totalOutflows, setTotalOutflows] = useState(0);
  const [totalOutflowsBalance, setTotalOutflowsBalance] = useState(0);
  const [totalSavings, setTotalSavings] = useState(0);
  const [outflowSaving, setOutflowSaving] = useState(0);
  const [totalSavingsBalance, setTotalSavingsBalance] = useState(0);
  const [autoSaveStatus,setAutoSaveStatus ] = useState(false);
  const initialSaveStatus = useRef(false);
  const { enqueueSnackbar } = useSnackbar();


  const getQusList = async () => {
    setBackdrop(true);
    try {
      let resData = await getRequest(ApiUri.mpv.qustionList);
      if (resData.status == 200) {
        const data = resData.data;
        setBackdrop(false);
        setCopyQustionList(
          cloneDeep(data.data.categoryContainers[0].categories),
        );
        setQustionList(cloneDeep(data.data.categoryContainers[0].categories)); //use this to update
      } else {
      }
    } catch (error) {
      console.log(error);
    }
  };


  const onInputChanges =(qustionList)=>{
    if(!initialSaveStatus.current)
    {
      initialSaveStatus.current = true;
    }
    setQustionList(qustionList)
  }

  useEffect(() => {
    let outflowSaving = 0;
    qustionList.forEach((v) => {
      let monthlyAmount = 0;

      v.groups.forEach((groupList) => {
        if (groupList.code !== "TOT") {
          const filterData = groupList.questions.filter((data) => {
            return data.questionMpvAmount !== undefined;
          });

          if (filterData.length > 0) {
            const SAVINGS = filterData.find((v) => {
              return v.code == "SAVINGS";
            });

            if (SAVINGS) {
              outflowSaving = parseFloat(SAVINGS.questionMpvAmount.monthlyAmount || 0);
            }

            monthlyAmount = filterData.reduce((accumulator, currentValue) => {
              return (
                accumulator +
                parseFloat(currentValue.questionMpvAmount.monthlyAmount || 0)
              );
            }, monthlyAmount);
          }
        }
      });
      
      monthlyAmount = (+(monthlyAmount )?.toFixed(2)) 
       outflowSaving = (+(outflowSaving )?.toFixed(2)) 
      console.log("totalIncome", typeof outflowSaving, typeof monthlyAmount);
      if (v.code == "INC-WHL") {
        setTotalIncome(monthlyAmount);
      }
      if (v.code == "MO-WHL") {
        setTotalOutflows(monthlyAmount);
      
      } else if (v.code == "SAV-WHL") {
        setTotalSavings(monthlyAmount);
        setTotalSavingsBalance(outflowSaving - monthlyAmount);
        setOutflowSaving(outflowSaving);
      }
    });
    
    
    if(initialSaveStatus.current)
    {
      console.log("autoSaveStatus",initialSaveStatus)
      setAutoSaveStatus(true)
    }
  }, [qustionList]);

  useEffect(() => {
    setTotalOutflowsBalance(totalIncome - totalOutflows);
  }, [totalIncome, totalOutflows]);

  const onSubmit = async (showStatus = true) => {
    let data = [];
    setAutoSaveStatus(false);
    qustionList.forEach((v) => {
      v.groups.forEach((groupList) => {
        if (groupList.code !== "TOT") {
          groupList.questions.forEach((qus) => {
            if (qus.questionMpvAmount) {
              data.push({
                question: qus.question,
                code: qus.code,
                questionMpvAmount: qus.questionMpvAmount,
              });
            }
          });
        }
      });
    });
    let finalData = {
      questionMpvAmount: {
        totalMonthlyIncome: totalIncome,
        totalYearlyIncome: totalIncome * 12,
        totalMonthlyOutflows: totalOutflows,
        totalYearlyOutflows: totalOutflows * 12,
        totalMonthlySavings: totalSavings,
        totalYearlySavings: totalSavings * 12,
      },
      questions: data,
    };

    if(showStatus)
    {
      setBackdrop(true);
      setDisabledSubmit(true);
    }
    
    let reqData = await postRequest(ApiUri.mpv.submitQustion, finalData);
        setBackdrop(false);
        setDisabledSubmit(false);
    if (reqData.status == 200) {

      if(showStatus)
      {
        
        enqueueSnackbar("Data Save Successfully");
      }
      return true
    } else {
      enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
      return false
    }
  };

  useEffect(() => {
    getQusList();
  }, []);
  const infoSubmit = () => {
    onSubmit();
    setSelectedInfo(null);
  };

  useBeforeUnload({
    when: autoSaveStatus,
    message: 'Are you sure you want to leave?',
  })


  useEffect(()=>{

    let createIntervel = setInterval(()=> {
        
      if(autoSaveStatus)
      {
        onSubmit(false)
        
        clearInterval(createIntervel);
        
      }
    }, 10000)
   
  
  


        return ()=>{
          clearInterval(createIntervel);
       }
    
    

  },[autoSaveStatus])

  const checkSubmit = () => {
    // console.log("totalSavingsBalance", totalSavingsBalance);
    if (totalSavingsBalance >= 0 && totalOutflowsBalance >= 0) {
      onSubmit();
    } else {
      setSelectedInfo();
    }
  };

  return (
    <>
      <Box textAlign="center" pt={4} pb={4}>
        {qustionList.map((v, sectionId) => {
          let sectionTotal = 0,
            sectionBalance = 0;

          if (v.code == "INC-WHL") {
            sectionTotal = totalIncome;
          } else if (v.code == "MO-WHL") {
            sectionTotal = totalOutflows;
            sectionBalance = totalOutflowsBalance;
          } else if (v.code == "SAV-WHL") {
            sectionTotal = totalSavings;
            sectionBalance = totalSavingsBalance;
          }

          return (
            <>
              <Heading
                heading={v.name}
                code={v.code}
                setQustionList={onInputChanges}
                copyQustionList={copyQustionList}
                qustionList={qustionList}
                userData={props.userData}
                submitQustionList={onSubmit}
                getQusList={getQusList}
              />
              <Table
                qusGroup={v.groups}
                sectionId={sectionId}
                qustionList={qustionList}
                setQustionList={onInputChanges}
                totalIncome={totalIncome}
                sectionTotal={sectionTotal}
                sectionBalance={sectionBalance}
                outflowSaving={outflowSaving}
                roundOf={roundOf}
                getQusList={getQusList}
              />
            </>
          );
        })}
      </Box>
      <Box margin="auto" textAlign="center">
        <Grid container justify="space-evenly" alignItems="center">
          <Grid item>
            <Button
              variant="contained"
              component={Link}
              to={"/app/money-purpose-wheel/result"}
              className={classes.resultButton}
            >
              View Result
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              diabled={disabledSubmit}
              onClick={checkSubmit}
              className={classes.submitButton}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
      {selectedInfo !== null && (
        <Dialog
          fullWidth={true}
          maxWidth={"md"}
          open={true}
          // onClose={handleClose}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogContent className={classes.dialogContentSection}>
            <DialogContentText>
              <p>
                Your balance for Outflows and/or Savings is in negative. Do you
                still want to continue?
              </p>
            </DialogContentText>
            <DialogActions className={classes.dialogActionSection}>
              <Button onClick={infoSubmit} color="primary" autoFocus>
                Yes
              </Button>
              <Button
                onClick={() => {
                  setSelectedInfo(null);
                }}
                color="primary"
              >
                No
              </Button>
            </DialogActions>
          </DialogContent>{" "}
        </Dialog>
      )}
      <Prompt
        when={autoSaveStatus}
        message="Are you sure you want to leave?"
      />
    </>
  );
}
