import React, { useEffect, useState, useContext } from "react";
import { Grid, Box, Paper } from "@material-ui/core";
import PieChart from "./pieChart";
// import PieChart2 from "./pieChart/index2";
import Result from "../components/Result";
import useStyles from "./styles";
import ApiUri from "../../../constants/apiUri";
import { getRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import {DataNotFoundDispatchContext}  from '../../../context/DataNotFoundContext'
// import PrintDataComponent from '../../../PrintData/AttainmentWheel'
import { PrintDataDispatchContext } from "../../../context/PrintDataContext";
import {printSectionTypes} from "../../../constants/index"




let graphSection = ["low", "fair", "average", "good", "excellent"];

const Answers = React.forwardRef((props, ref) => {
  const setBackdrop = useContext(BackdropDispatchContext);
  const setDataNotFound = useContext(DataNotFoundDispatchContext);
  const setPrintData = useContext(PrintDataDispatchContext)


  const classes = useStyles();
  const [selectedResult, setSelectedResult] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [tawScores, setTawScores] = useState([]);

  const getUserResult = async () => {
    setBackdrop(true);
    let reqData = await getRequest(
      `${ApiUri.attainmentWheel.userResults}/${props.userData.code}`,
    );
    if (reqData.status == 200) {

      let selectedData = (reqData.data?.data?.questionAnswerMappingByUser?.categories || []).map(
        (v, i) => {
          return {
            categoriesCode: v.code,
            categoriesName: v.displayName,
            options: v.questions,
            imageIcon: v.imageIcon,
            totalCategoryScore: v.totalCategoryScore,
          };
        },
      );

      let newTawScores = reqData?.data?.data?.questionAnswerMappingByUser?.tawScores;
      // console.log(
      //   "reqData",
      //   reqData.data.data.questionAnswerMappingByUser.categories,
      // );
      setPrintData(printSectionTypes.attainmentWheel)

      setSelectedResult(selectedData);

      setTawScores(newTawScores);
      setTotalScore(reqData.data?.data?.questionAnswerMappingByUser?.totalScore);
      setBackdrop(false);
    } else {
      setBackdrop(false);
      setDataNotFound(true);
    }
  };

  useEffect(() => {
    getUserResult();
  }, []);

  return (
    <>
      <Box component="div" ref={ref} textAlign="center">
        <PieChart
          selectedResult={selectedResult}
          graphSection={graphSection}
          totalScore={totalScore}
        />
        {/* <PieChart2
          selectedResult={selectedResult}
          graphSection={graphSection}
          totalScore={totalScore}
        /> */}
        <div className="print_svg">
        <Box p={2}>
          <Grid
            container
            direction="row"
            justify="center"
            aliginItems="flex-start"
            spacing={4}
            className={classes.statusSection}
          >
            

            <Grid item className={classes.low}>
              <Paper>Low</Paper>
            </Grid>
            <Grid item className={classes.fair}>
              <Paper>FAIR</Paper>
            </Grid>
            <Grid item className={classes.average}>
              <Paper>AVERAGE</Paper>
            </Grid>
            <Grid item className={classes.good}>
              <Paper>GOOD</Paper>
            </Grid>
            <Grid item className={classes.excellent}>
              <Paper>EXCELLENT</Paper>
            </Grid>
          </Grid>
        </Box>
        </div>
       
        <Box p={3}>
          {selectedResult.map((v) => {
            return (
              <Result
                graph="low"
                categoriesCode={v.categoriesCode}
                defaultValue={0}
                type={v.categoriesName}
                options={v.options}
                icon={v.imageIcon}
                // subHeading={v.categoriesName}
                totalCategoryScore={v.totalCategoryScore}
                tawScores={tawScores}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
});

export default Answers;
