import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  statusSection: {
    "& *": {
      fontSize: 15,
      fontWeight: 700,
    },
    "& span": {
      marginRight: 5,
    },
  },
  low: {
    "& *": {
      backgroundColor: `${theme.palette.red.main}`,
    },
  },
  fair: {
    "& *": {
      backgroundColor: `${theme.palette.orange.main}`,
    },
  },
  average: {
    "& *": {
      backgroundColor: `${theme.palette.yellow.main}`,
    },
  },
  good: {
    "& *": {
      backgroundColor: `${theme.palette.green.main}`,
    },
  },
  excellent: {
    "& *": {
      backgroundColor: `${theme.palette.blue.main}`,
    },
  },
  dot: {
    height: 8,
    width: 8,
    borderRadius: "50%",
    display: "inline-block",
  },
  submitButton: {
    background: `${theme.palette.orange.main}`,
    borderRadius: 50,
    padding: "10px 20px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 200,
    "&:hover": {
      background: `${theme.palette.orange.dark}`,
    },
  },
  additionalStaticData: {
    fontSize: 10,
    fontStyle: "italic",
    padding: "0px 50px",
    textAlign: "justify",
    color: "#b2b2b2",
  },
}));
