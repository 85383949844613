import { makeStyles } from "@material-ui/styles"

export default makeStyles(theme => ({

    statusSection: {
        "& div div": {
            fontSize: 12,
            fontWeight: 700,
            color: "#fff",
            width: 150,
            textAlign: "center",
            padding: "5px 0px",

        },

    },
    fill_success: {
        fill: `${theme.palette.red.main}`,



    },
    fill_failure: {

        fill: `${theme.palette.orange.main}`,

    },
    fill_career: {

        fill: `${theme.palette.yellow.light}`,

    },
    fill_money: {

        fill: `${theme.palette.green.main}`,

    },
    fill_family: {

        fill: `${theme.palette.blue.main}`,

    },
    stroke_success: {
        stroke: `${theme.palette.red.main}`,
        fill: "#fff",
        strokeWidth: 10 


    },
    stroke_failure: {

        stroke: `${theme.palette.orange.main}`,
        fill: "#fff",
        strokeWidth: 10 

    },
    stroke_career: {

        stroke: `${theme.palette.yellow.light}`,
        fill: "#fff",
        strokeWidth: 10 

    },
    stroke_money: {

        stroke: `${theme.palette.green.main}`,
        fill: "#fff",
        strokeWidth: 10 

    },
    stroke_family: {

        stroke: `${theme.palette.blue.main}`,
        fill: "#fff",
        strokeWidth: 10 

    },
    resultButton: {
        background: `${theme.palette.warning.main}`,
        borderRadius: 50,
        padding: "10px 20px",
        fontSize: 16,
        fontWeight: 600,
        minWidth: 200,
        '&:hover': {
            background: `${theme.palette.warning.dark}`,

        }

    },
    submitButton: {
        background: `${theme.palette.orange.main}`,
        borderRadius: 50,
        padding: "10px 20px",
        fontSize: 16,
        fontWeight: 600,
        minWidth: 200,
        '&:hover': {
            background: `${theme.palette.orange.dark}`,

        }

    },




}));
