import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({

  
low_bg:{backgroundColor:`${theme.palette.red.main}`},
fair_bg:{ backgroundColor:`${theme.palette.orange.main}`},
average_bg:{  backgroundColor:`${theme.palette.yellow.main}`},
good_bg:{backgroundColor:`${theme.palette.green.main}`
},
excellent_bg:{backgroundColor:`${theme.palette.blue.main}`},


  curvedSection:{
    width: 150,
    background: "#757575",
    border: "2px solid #757575",
    padding: 10,
    borderRadius: "20px 0px",
    color: "#fff",
    textAlign: "center",
    fontWeight: 600,
    fontSize: 17.5
  },
  virticalLineSection:{
    position:"relative"
  },
  virticalLine:{
   
    display: "inline-block",
    borderLeft:"4px solid #757575",
    height: "calc(100% - 24px)",
    position: "absolute",
    zIndex: -1,
    left: 70,

  },
  dot: {
    height: 8,
    width: 8,
    backgroundColor: "#757575",
    borderRadius: "50%",
    display: "inlineBlock",
    position: "absolute",
    bottom: -1,
    left: -6
  },
  paper: {
    padding: theme.spacing(0.5),
    paddingLeft:theme.spacing(10),
    color: "#000",
    backgroundColor:"#d7d7d7",
    boxShadow:"none",
    borderRadius:0,    
    fontSize: 20,
    fontWeight: 500

  },
  avatar:{
    backgroundColor:"#d7d7d7",
  },
  year:{
    textAlign:"right",
    marginRight:30
  },
  horigentalLine:{
   
    borderTop: "2px solid #757575",
    margin: "10px 30px 10px 50px",
    position: "relative"
  },
  startDot:{
    height: 8,
    width: 8,
    backgroundColor: "#757575",
    borderRadius: "50%",
    position: "absolute",
    bottom: -3,
    left: -6
  },
  endDot:{
    height: 8,
    width: 8,
    backgroundColor: "#757575",
    borderRadius: "50%",
    position: "absolute",
    bottom: -3,
    right: -6
  },
  action:{
    "& *":{
      marginRight:10
     } 
  },
  actionImages:{
    float:"right",
    "& span":{
      cursor: "pointer"
     } 
  },
  actionName:
  {
    fontSize: 20,
    fontWeight: 500,
  },
  resultButton:{
    background:`${theme.palette.warning.main}`,
    borderRadius: 50,
    padding: "10px 20px",
    fontSize:16,
    fontWeight:600,
    minWidth:150,
    '&:hover':{
      background:`${theme.palette.warning.dark}`,
    
    }
  
  },
  submitButton:{
    background:`${theme.palette.orange.main}`,
    borderRadius: 50,
    padding: "10px 20px",
    fontSize:16,
    fontWeight:600,
    minWidth:150,
    '&:hover':{
      background:`${theme.palette.orange.dark}`,
    
    }
  
  },
  hasCommentIcon:{
    color:`${theme.palette.orange.main}`,
  }



}));
