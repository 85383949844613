import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  card: {
    minHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  visitsNumberContainer: {
    display: "flex",
    alignItems: "center",
    flexGrow: 1,
    paddingBottom: theme.spacing(1),
  },
  progressSection: {
    marginBottom: theme.spacing(1),
  },

  resultHeading: {
    position: "absolute",
    marginTop: "10px",
    color: "#fff",
    fontWeight: "bold !important",
    fontSize: "14px !important",
  },
  resultValues: {
    border: "40px solid",
    borderImageSlice: 1,
    borderWidth: "40px",
    borderImageSource: "linear-gradient(180deg, #507096, #f5f6f8)",
    padding: "20px",
  },
  resultText: {
    color: "#575757",
    fontWeight: "bold !important",
    fontSize: "14px !important",
    marginBottom: "0px",
    padding: "10px",
  },
  resultTextActive: {
    background: "#f6f6f6",
  },
  checkedRadio: {
    background: "#d9d9d9",
  },
  radioCSS: {
    margin: "0px",
  },
  table: {
    minWidth: 700,
  },
  resultButton: {
    background: `${theme.palette.warning.main}`,
    borderRadius: 50,
    padding: "10px 20px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 200,
    "&:hover": {
      background: `${theme.palette.warning.dark}`,
    },
  },
  submitButton: {
    background: `${theme.palette.orange.main}`,
    borderRadius: 50,
    padding: "10px 20px",
    fontSize: 16,
    fontWeight: 600,
    minWidth: 200,
    "&:hover": {
      background: `${theme.palette.orange.dark}`,
    },
  },
  low_bg: { backgroundColor: `${theme.palette.red.main}` },
  fair_bg: { backgroundColor: `${theme.palette.orange.main}` },
  average_bg: { backgroundColor: `${theme.palette.yellow.main}` },
  good_bg: { backgroundColor: `${theme.palette.green.main}` },
  excellent_bg: { backgroundColor: `${theme.palette.blue.main}` },
  statusSection: {
    "& div div": {
      fontSize: 10,
      fontWeight: 600,
      color: "#fff",
      width: 75,
      textAlign: "center",
      padding: "5px 0px",
    },
    "& span": {
      fontSize: 10,
      fontWeight: 600,
    },
  },
  suggestiondialogSectionHeading: {
    padding: 0,
    "& p": {
      margin: 0,
    },
  },
}));
