import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";

// styles
import useStyles from "../../styles";
import CardHeader from "../../../../components/CardHeader";
import StepSlider from "../../../../components/StepSlider";

// components
export default function Dashboard(props) {
  var classes = useStyles();

  return (
    <>
      <CardHeader img={`data:image/png;base64,${props.icon}`} {...props} />
      <Box pt={2} pb={2}>
        <Grid container spacing={2} alignItems="center">
          {props.options.map((v) => {
            return (
              <>
                <Grid item sm={9}>
                  <Typography
                    variant="h5"
                    className={classes.resultText}
                    questionId={v.questionCode}
                  >
                    {v.question}
                  </Typography>
                </Grid>
                <Grid item sm={3}>
                  <StepSlider
                    onChange={(newValue, code) => {
                      props.onChange(newValue, v.questionCode);
                    }}
                    min={v.lowScore}
                    max={v.highScore}
                    result={v.resultScore}
                  />
                </Grid>
              </>
            );
          })}
        </Grid>
      </Box>
    </>
  );
}
