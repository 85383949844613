import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import PositiveImpression from "./icon/positiveImpression";
import NegativeImpression from "./icon/negativeImpression";
import ApiUri from "../../../constants/apiUri";
import useStyles from "./../styles";
import { getRequest, postRequest } from "../../../utils/API";
import { BackdropDispatchContext } from "../../../context/BackdropContext";
import {DataNotFoundDispatchContext}  from '../../../context/DataNotFoundContext'
// import PrintDataComponent from '../../../PrintData/Impression'
// import { PrintDataDispatchContext } from "../../../context/PrintDataContext";

import { useSnackbar } from 'notistack';


import {
  Grid,
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  withStyles,
  makeStyles,
} from "@material-ui/core";

export default function Fir(props) {
  const setBackdrop = useContext(BackdropDispatchContext);
  const setDataNotFound = useContext(DataNotFoundDispatchContext);
  // const setPrintData = useContext(PrintDataDispatchContext);
  const { enqueueSnackbar } = useSnackbar();
  


  const classes = useStyles();
  const [disabledSubmit, setDisabledSubmit] = useState(false);

  const [negative, setNegative] = useState([]);
  const [positive, setPositive] = useState([]);

  const getQusList = async () => {
    setBackdrop(true);
    try {
      let resData = await getRequest(ApiUri.rm.qustionList);
      if (resData.status == 200) {
        const data = resData.data.data.impressionList;
        // console.log("reqData",data)
        setBackdrop(false);
        let negativeValue = data.filter((val) => val.typeCode === "NI");
        let positiveValue = data.filter((val) => val.typeCode === "PI");
        // setPrintData({data,component: PrintDataComponent , fileName:"impression" })

        setNegative(negativeValue);
        setPositive(positiveValue);
      } else {
        setBackdrop(false);
      setDataNotFound(true);
      }
    } catch (error) {
      setBackdrop(false);
      setDataNotFound(true);
      console.log(error);
    }
  };
  const onSubmit = async () => {
    // console.log("negative",negative,"positive",positive);
    let data = [];
    positive.forEach((v) => {
      data.push({
        typeCode: v.typeCode,
        code: v.code,
        selected: v.selected,
      });
    });
    negative.forEach((v) => {
      data.push({
        typeCode: v.typeCode,
        code: v.code,
        selected: v.selected,
      });
    });

    setBackdrop(true);
    setDisabledSubmit(true);
    let reqData = await postRequest(ApiUri.rm.submitImpression, data);
    if (reqData.status == 200) {
      
      enqueueSnackbar("Data Save Successfully");
    } else {
      enqueueSnackbar("please try again", { variant: 'error',preventDuplicate: true, });
    }
    setBackdrop(false);
    setDisabledSubmit(false);
  };
  useEffect(() => {
    // console.log("DemoData",DemoData)
    getQusList();
  }, []);

  return (
    <>
      {negative.length > 0 && positive.length > 0 ? (
        <div style={{ textAlign: "center" }}>
          <Box p={3}>
            <PositiveImpression positive={positive} setPositive={setPositive} />
            <Box pt={3} pb={3}>
              <img
                src="static/images/retireMeant/impression_break.svg"
                width="90%"
                className="print_svg"
              />
            </Box>

            <NegativeImpression negative={negative} setNegative={setNegative} />
          </Box>

          <Box margin="auto" textAlign="center" marginTop="30px">
            <Grid container justify="space-evenly" alignItems="center">
              <Grid item>
                <Link
                  to={`${props.path}/fir`}
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="contained" className={classes.submitButton}>
                    Previous
                  </Button>
                </Link>{" "}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  diabled={disabledSubmit}
                  onClick={onSubmit}
                  className={classes.submitButton}
                >
                  Submit
                </Button>{" "}
              </Grid>
              <Grid item>
                <Link
                  to={`${props.path}/vision`}
                  style={{ textDecoration: "none" }}
                >
                  <Button variant="contained" className={classes.submitButton}>
                    Next
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Box>
          
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
