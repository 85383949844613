import React from "react";
import useStyles from "./styles";
export default function SvgIcon(props) {
  const classes = useStyles();
  return (
    <>
      <svg
        version="1.2"
        baseProfile="tiny-ps"
        viewBox="0 0 171 194"
        width={100}
        height={120}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Group 2">
          <g id={1}>
            <path
              id="Shape 1 copy"
              className={classes[`stroke_${props.type}`]}
              d="M 86.22 186.8 L 163.51 141.98 L 163.29 52.69 L 85.78 8.2 L 8.49 53.02 L 8.71 142.31 L 86.22 186.8 Z"
            />
            <path
              style={{
                stroke: "rgb(0, 0, 0)",
                vectorEffect: "non-scaling-stroke",
                strokeWidth: 0,
              }}
              className={classes[`fill_${props.type}`]}
              d="M 31.632 44 L 12 56.2 L 12.193 138.8 L 30.285 150 L 58 101.801 C 58.234 102.97 61.378 95.027 58.001 89.585 L 31.632 44 Z"
              origin="0.478261 0.5"
            />
          </g>
          <image
            width={252}
            height={287}
            id="Layer 183"
            transform="matrix(0.21400000154972076, 0, 0, 0.21300001442432404, 82, 67)"
            href={`data:image/png;base64,${props.icon}`}
          />
          <text
            id={1}
            style={{
              transform: "matrix(1.002, 0, 0, 1, 19.233, 114.207)",
              whiteSpace: "pre",
            }}
          >
            <tspan x={0} y={0} className="txt1">
              {props.textValue}
            </tspan>
          </text>
        </g>
      </svg>
    </>
  );
}
