import React, { useEffect } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  useHistory,
} from "react-router-dom";

// components
import Layout from "./Layout";

// pages
import Error from "../pages/error";
import Login from "../pages/login";
import SignUp from "../pages/signUp";
import ForgetPassword from '../pages/forgetPassword';
import PasswordReset from '../pages/passwordReset';
import Paymentresponse from "../pages/paymentResponse";

// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global
  var { isAuthenticated } = useUserState();

  return (
    <HashRouter  getUserConfirmation={(message, callback) => {
      console.log('open modal here!')
      const allowTransition = window.confirm(message)
      window.setTimeout(() => {
        callback(allowTransition)
      }, 1000)
    }}>
      <>
        <ScrollToTop />
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Redirect to="/app/dashboard" />}
          />
          <Route
            exact
            path="/app"
            render={() => <Redirect to="/app/dashboard" />}
          />
          <PrivateRoute path="/app" component={Layout} />
          <ProtectedRoute path="/login" component={Login} />
          <ProtectedRoute path="/forget-password" component={ForgetPassword} />
          <ProtectedRoute path="/password-reset" component={PasswordReset} />
          <PublicRoute path="/signUp" component={SignUp} />
          <PublicRoute path="/paymentresponse" component={Paymentresponse} />
          <Route component={Error} />
        </Switch>
      </>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ Component, ...rest }) {
    if (isAuthenticated) {
      return <Route {...rest} render={(props) => <Component {...props} />} />;
    } else {
      return <Redirect to="/login" />;
    }
  }

  function ProtectedRoute({ component, ...rest }) {
    if (!isAuthenticated) {
      return (
        <Route
          {...rest}
          render={(props) => React.createElement(component, props)}
        />
      );
    } 
    return <Redirect to="/" />;
  }
}

function PublicRoute({ component, ...rest }) {
 return <Route
        {...rest}
        render={(props) => React.createElement(component, props)}
      />
}

function ScrollToTop() {
  const history = useHistory();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}
