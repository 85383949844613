import React, { useState, useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  Table,
  Grid,
  Button,
  TableHead,
  TableRow,
  TableCell,
  withStyles,
  TableBody,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import useStyles from "./../../styles";

const StyledTableCell = withStyles((theme) => ({
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function FirScore(props) {
  let { dayMaster, timeMaster, weeklyRetirement, setWeeklyRetirement } = props;
  const classes = useStyles();
  const maxLength = 3000;

  const findIndex = (weeklyRetirement, timeCode, dayCode) =>
    (weeklyRetirement || []).findIndex((v) => {
      return v.day.dayCode === dayCode && v.time.code === timeCode;
    });
  let onchange = (value, timeCode, dayCode) => {
    if (value.length <= maxLength) {
      console.log("value", value.length);
      const newWeeklyRetirement = [...weeklyRetirement];

      const index = findIndex(newWeeklyRetirement, timeCode, dayCode);

      if (index !== -1) {
        newWeeklyRetirement[index].data = value;
      } else {
        newWeeklyRetirement.push({
          day: { dayCode: dayCode },
          time: { code: timeCode },
          data: value,
        });
      }
      setWeeklyRetirement(newWeeklyRetirement);
    }
  };

  const getDefaultValue = (timeCode, dayCode) => {
    const index = findIndex(weeklyRetirement, timeCode, dayCode);
    if (index !== -1) {
      return weeklyRetirement[index].data;
    }
    return "";
  };

  const getTextAreaSection = (timeCode, dayCode) => {
    const value = getDefaultValue(timeCode, dayCode);
    const error = value.length >= maxLength || false;
    return (
      <StyledTableCell align="center">
        <TextareaAutosize
          aria-label="minimum height"
          id={`${dayCode}_${timeCode}`}
          maxRows={5}
          rowsMax={5}
          rowsMin={5}
          style={{
            maxWidth: "100%",
            width: "100%",
            height: 60,
            resize: "vertical",
          }}
          onChange={(e) => onchange(e.target.value, timeCode, dayCode)}
          value={value}
        />
        {error && (
          <div>
            <span className={classes.weeklySchedulerInputFiledErrorMSg}>
              Max character limit reached
            </span>
          </div>
        )}
      </StyledTableCell>
    );
  };
  return (
    <>
      {}

      {/* <p style={{ margin: "35px 0px 35px 0px", fontWeight: "bold" }}>
        Think about your Ideal Week in Retirement and use this calender to
        outline a schedule
      </p> */}

      <Table className={classes.table} aria-label="customized table">
        <TableHead className={classes.head}>
          <TableRow>
            <StyledTableCell className={classes.title}>
              Days/Time
            </StyledTableCell>
            {timeMaster.map((col) => (
              <StyledTableCell align="center" className={classes.title}>
                {col.displayName}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {dayMaster.map((row, i) => {
            return (
              <StyledTableRow key={row.displayName}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: "bold" }}
                >
                  {row.displayName}
                </StyledTableCell>
                {getTextAreaSection("AM", row.dayCode)}
                {getTextAreaSection("NOON", row.dayCode)}
                {getTextAreaSection("PM", row.dayCode)}
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
}
