export const backgroundPath = [
  "M46.012,102.209l194.5,140.5H0.012C0.012,242.709-1.988,165.709,46.012,102.209z",
  "M168.063,12.551l75.553,227.732L48.563,99.59C48.563,99.59,91.987,35.972,168.063,12.551z",
  "M172.329,11.402l74.533,228.39L322.168,12.06C322.168,12.06,247.552-14.631,172.329,11.402z",
  "M325.783,12.551L250.23,240.284L445.283,99.59C445.283,99.59,401.859,35.972,325.783,12.551z",
  "M448.242,102.209l-194.5,140.5h240.5C494.242,242.709,496.242,165.709,448.242,102.209z",
  "M448.242,386.694l-194.5-140.5h240.5C494.242,246.194,496.242,323.194,448.242,386.694z",
  "M325.783,476.352L250.23,248.62l195.053,141.693C445.283,390.313,401.86,452.931,325.783,476.352z",
  "M173.33,477.501l73.533-228.39l74.305,228.731C321.168,477.843,248.553,503.535,173.33,477.501z",

  "M168.063,476.352l75.553-227.732L48.563,390.313C48.563,390.313,91.986,452.931,168.063,476.352z",

  "M46.012,386.694l194.5-140.5H0.012C0.012,246.194-1.988,323.194,46.012,386.694z",
];
export const graphSection = [
  "lowFill",
  "fairFill",
  "averageFill",
  "goodFill",
  "excellentFill",
];
export const getScoreClass = (selectedItemScore) => {
  console.log("selectedItemScore", selectedItemScore);
  console.log("graphSection", graphSection[selectedItemScore - 1]);
  return graphSection[selectedItemScore - 1] || "";
};

export const circleTransform = [
  {
    name: {
      x: 145,
      y: 345,
    },
    image: "matrix(0.247,0,0,0.245,191,280)",
  },
  {
    name: {
      x: 260,
      y: 198,
    },
    image: "matrix(0.275,0,0,0.272,300,125)",
  },
  {
    name: {
      x: 422,
      y: 145,
    },
    image: "matrix(0.268,0,0,0.268,464,75)",
  },
  {
    name: {
      x: 590,
      y: 190,
    },
    image: "matrix(0.278,0,0,0.279,631,115)",
  },
  {
    name: {
      x: 708,
      y: 340,
    },
    image: "matrix(0.291,0,0,0.287,742,264)",
  },
  {
    name: {
      x: 700,
      y: 525,
    },
    image: "matrix(0.291,0,0,0.287,740,453)",
  },

  {
    name: {
      x: 590,
      y: 660,
    },
    image: "matrix(0.268,0,0,0.272,627,591)",
  },
  {
    name: {
      x: 415,
      y: 720,
    },
    image: "matrix(0.341,0,0,0.345,450,630)",
  },
  {
    name: {
      x: 250,
      y: 666,
    },
    width: 200,
    height: 200,
    image: "matrix(0.268,0,0,0.267,299,608)",
  },
  {
    name: {
      x: 156,
      y: 515,
    },
    image: "matrix(0.268,0,0,0.267,194,445)",
  },
];
