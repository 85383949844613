import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { Country, State } from "country-state-city";
import {
  Grid,
  Button,
  TextField,
  MuiThemeProvider,
  createMuiTheme,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormHelperText,
  RadioGroup,
  Radio,
  Backdrop,
  CircularProgress,
  Link,
  IconButton,
  InputAdornment,
  Box,
  Input,
} from "@material-ui/core";

import { blue } from "@material-ui/core/colors";
import Footer from "../../components/Footer";
import PublicHeader from "../../components/PublicHeader";
import PageTitle from "../../components/PageTitle";
import useStyles from "./styles";
import PasswordRuleHelperIcon from "../../components/PasswordRuleHelperIcon";

import ApiUri from "../../constants/apiUri";
import { getRequest, postRequest, putRequest } from "../../utils/API";
import queryParams from "../../utils/queryParams";
import DateFnsUtils from "@date-io/dayjs";
import dayjs from "dayjs";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import {
  Event as EventIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  LinkedIn as LinkedInIcon,
} from "@material-ui/icons";

import formValidators, { emailValidator } from "./validators";
import { useSnackbar } from "notistack";
import { useUserState } from "../../context/UserContext";

function SignUpForm({
  pageTitle,
  isClient = false,
  textData = {},
  isEdit = false,
  userProfileData,
  closeEditDilog
}) {
  const classes = useStyles();
  let [radioVal, setRadioVal] = useState("");
  const location = useLocation();
  const params = queryParams(location.search);
  const { pid } = params;
  const eid = atob(params?.eid || "");
  const [validParams, setValidParams] = useState(eid);

  console.log("isEdit45", userProfileData);
  const userDefaultData = isEdit
    ? userProfileData
    : {
        country: "IN",
        billingCountry: "IN",
        emailId: eid,
      };

  const [fieldValues, setFieldValues] = useState(userDefaultData);
  const [backdrop, setBackdrop] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [showPassword, setShowPassword] = useState(isEdit ? false : true);

  const [fieldErrors, setFieldErrors] = useState({});
  const [fieldHelperTexts, setFieldHelperTexts] = useState({});
  const { enqueueSnackbar } = useSnackbar();
  const { userData, clientData } = useUserState();
  console.log("useUserState", userData);

  const validateUser = async () => {
    let resData = await getRequest(ApiUri.payment.validate, {
      headers: {
        username: fieldValues.emailId,
        paymentId: pid,
      },
    });
    const lumErrors = resData.data?.lumErrors;
    const errorsData = { ...fieldErrors };
    const helperTexts = { ...fieldHelperTexts };

    if (resData.status == 500) {
      errorsData.emailId = true;
      helperTexts.emailId = lumErrors[0]?.errorMessage;
      setFieldErrors(errorsData);
      setFieldHelperTexts(helperTexts);
      setValidParams(false);
    } else if (resData.status == 200) {
      errorsData.emailId = false;
      helperTexts.emailId = "";
      setFieldErrors(errorsData);
      setFieldHelperTexts(helperTexts);
      setValidParams(true);
    }
  };

  useEffect(() => {
    if (!isClient && eid) {
      validateUser();
      return;
    }
    setDisabledSubmit(false);
  }, []);

  useEffect(() => {
    console.log("fieldValues3445", fieldValues);
    if (
      !isClient &&
      !isEdit &&
      !emailValidator(fieldValues.emailId) &&
      !validParams
    ) {
      validateUser();
    }
  }, [fieldValues?.emailId]);

  const setFieldValue = (key, val) => {
    console.log("val123", val);
    if (key in formValidators) {
      setFieldValues((prevState) => {
        return { ...prevState, [key]: val };
      });
    } else {
      console.warn("Invalid field key passed in setValue");
    }
  };

  const getFieldValue = (key) => {
    return fieldValues?.[key] || "";
  };

  const getFieldError = (key) => {
    return fieldErrors?.[key] || false;
  };

  const getFieldHelperText = (key) => {
    return fieldHelperTexts?.[key] || "";
  };

  const validateForm = () => {
    const errors = {};
    const helperTexts = {};
    let isValid = true;
    for (const [fieldKey, fieldValidators] of Object.entries(formValidators)) {
      for (const validator of fieldValidators) {
        const args = validator.fnArgs
          ? validator.fnArgs.map((argKey) => getFieldValue(argKey))
          : [getFieldValue(fieldKey)];
        if (validator.onlyforAdvisor && isClient || !showPassword) {
          continue;
        }
        if (!validator.fn(...args)) {
          errors[fieldKey] = true;
          helperTexts[fieldKey] = validator.helperText || "";
          isValid = false;
          break;
        }
      }
    }
    setFieldErrors(errors);
    setFieldHelperTexts(helperTexts);
    return isValid;
  };

  const formLabelsTheme = createMuiTheme({
    palette: {
      primary: blue,
    },
    overrides: {
      MuiFormLabel: {
        asterisk: {
          color: "#db3131",
          "&$error": {
            color: "#db3131",
          },
        },
      },
    },
  });

  // minMax Length field
  // dataField dataTYPEs
  // clean junk db
  const handleRadio = (event) => {
    event.preventDefault();
    if (event.target.value !== radioVal) {
      setRadioVal(event.target.value);

      setFieldValue("billingAddress", getFieldValue("address"));
      setFieldValue("billingCountry", getFieldValue("country"));
      setFieldValue("billingState", getFieldValue("state"));
      setFieldValue("billingCity", getFieldValue("city"));
      setFieldValue("billingZip", getFieldValue("zip"));
    } else {
      setRadioVal("");
      setFieldValue("billingAddress", "");
      setFieldValue("billingCountry", "");
      setFieldValue("billingState", "");
      setFieldValue("billingCity", "");
      setFieldValue("billingZip", "");
    }
  };

  const onSubmit = async () => {
    if (!validateForm()) {
      document
        .getElementById("credentialManagement")
        .scrollIntoView({ behavior: "smooth" });
    } else {
      console.log("data34", fieldValues);

      const submitData = {
        userCredentials: {
          emailId: fieldValues.emailId,
          password: fieldValues.password,
        },
        userPersonalDetails: {
          firstName: fieldValues.firstName,
          lastName: fieldValues.lastName,
          contactEmailAddress:
            fieldValues.contactEmailAddress || fieldValues.emailId,
          dateOfBirth: fieldValues.dateOfBirth,
          shippingAddress: {
            zipCode: fieldValues.zip,
            address1: fieldValues.address,
            cityName: fieldValues.city,
            stateName: fieldValues.state,
            countryName: fieldValues.country,
          },
          billingAddress: {
            zipCode: fieldValues.billingZip,
            address1: fieldValues.billingAddress,
            cityName: fieldValues.billingCity,
            stateName: fieldValues.billingState,
            countryName: fieldValues.billingCountry,
          },
        },
        userCompanyDetails: {
          companyName: fieldValues.companyName,
          arnNumber: fieldValues.arnNumber,
          gstn: fieldValues.gstn,
        },
        advisorPaymentDetails: {
          paymentId: pid,
        },
      };

      setBackdrop(true);
      setDisabledSubmit(true);
      let reqData;
      let successRes;
      if (isEdit) {
        const userCode = isClient ? clientData?.code : userData.code;
        successRes = "Profile Update Successfully"
        reqData = await putRequest(ApiUri.profile.updateProfile, submitData, {
          "user-code": userCode,
        });
      } else {
        successRes = "Account Registration Successfully"
        reqData = !isClient
          ? await postRequest(ApiUri.auth.signUp, submitData)
          : await postRequest(ApiUri.client.register, submitData, {
              "user-code": userData.code,
            });
      }

      if (reqData.status == 200) {
        console.log("reqData123", reqData.data);
        

        enqueueSnackbar(successRes);
        setFieldValues({
          country: "IN",
          billingCountry: "IN",
        });
      } else {
        enqueueSnackbar(
          reqData?.data?.lumErrors[0]?.errorMessage || "please try again",
          {
            variant: "error",
            preventDuplicate: true,
          },
        );
      }
      setBackdrop(false);
      setDisabledSubmit(false);
      if(isEdit)
      {
        closeEditDilog(true)
      }
    }
  };
  console.log(
    "resData4444",
    fieldErrors,
    fieldHelperTexts,
    disabledSubmit,
    validParams,
    isClient,
  );

  return (
    <>
      <Backdrop className={classes.backdropStyle} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {pageTitle && (
        <PageTitle title={pageTitle} width="15%" minWidth="260px" />
      )}

      <MuiThemeProvider theme={formLabelsTheme}>
        {textData?.heading && (
          <p className={classes.formHeader}>{textData.heading}</p>
        )}

        <form className={classes.signUpForm}>
          <p className={classes.sectionHeader}>Credential Management</p>
          {textData?.subHeading && <p>{textData.subHeading}</p>}
          <div
            className={classes.formSectionSingleField}
            id="credentialManagement"
          >
            <TextField
              name="emailId"
              className={classes.formField}
              value={getFieldValue("emailId")}
              onChange={(e) => setFieldValue("emailId", e.target.value)}
              label="E-Mail"
              variant="standard"
              error={getFieldError("emailId")}
              helperText={getFieldHelperText("emailId")}
              disabled={validParams || isEdit}
              required
            />
            {isEdit && (
                <FormControlLabel
                    control={
                      <Radio
                        checked={showPassword}
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                      />
                    }
                    label="Edit Password"
                    className={classes.formField}
                    style={{ fontWeight: "bold" }}
                  />
            )}
            {showPassword && (
              <>
                <TextField
                  name="password"
                  type="password"
                  className={classes.formField}
                  value={getFieldValue("password")}
                  onChange={(e) => setFieldValue("password", e.target.value)}
                  error={getFieldError("password")}
                  helperText={getFieldHelperText("password")}
                  label="Password"
                  variant="standard"
                  InputProps={{ endAdornment: <PasswordRuleHelperIcon /> }}
                  required={!isEdit}
                />
                <TextField
                  name="confirmPassword"
                  type="password"
                  className={classes.formField}
                  value={getFieldValue("confirmPassword")}
                  onChange={(e) =>
                    setFieldValue("confirmPassword", e.target.value)
                  }
                  error={getFieldError("confirmPassword")}
                  helperText={getFieldHelperText("confirmPassword")}
                  label="Confirm Password"
                  variant="standard"
                  required={!isEdit}
                />
              </>
            )}
          </div>
          <p className={classes.sectionHeader}>Personal Details</p>
          <div className={classes.formSectionDoubleFields}>
            <TextField
              name="firstName"
              className={classes.formField}
              label="First Name"
              variant="standard"
              value={getFieldValue("firstName")}
              onChange={(e) => setFieldValue("firstName", e.target.value)}
              error={getFieldError("firstName")}
              helperText={getFieldHelperText("firstName")}
              required
            />
            <TextField
              name="lastName"
              className={classes.formField}
              label="Last Name"
              variant="standard"
              value={getFieldValue("lastName")}
              onChange={(e) => setFieldValue("lastName", e.target.value)}
              error={getFieldError("lastName")}
              helperText={getFieldHelperText("lastName")}
              required
            />
            {!isClient && (
              <TextField
                name="address"
                className={classes.formField}
                label="Address"
                variant="standard"
                value={getFieldValue("address")}
                onChange={(e) => setFieldValue("address", e.target.value)}
                error={getFieldError("address")}
                helperText={getFieldHelperText("address")}
                required
              />
            )}
            {!isClient && (
              <FormControl
                className={classes.formField}
                variant="standard"
                error={getFieldError("country")}
                required
              >
                <InputLabel id="country-select-label">Country</InputLabel>
                <Select
                  name="country"
                  labelId="country-select-label"
                  value={getFieldValue("country")}
                  onChange={(e) => setFieldValue("country", e.target.value)}
                >
                  <MenuItem disabled value="">
                    <em>Select Country</em>
                  </MenuItem>
                  {Country.getAllCountries().map((c) => (
                    <MenuItem
                      name={c.isoCode}
                      value={c.isoCode}
                      key={c.isoCode}
                    >
                      {c.name}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{getFieldHelperText("country")}</FormHelperText>
              </FormControl>
            )}
            {!isClient && (
              <FormControl
                className={classes.formField}
                variant="standard"
                error={getFieldError("state")}
                required
              >
                <InputLabel id="state-select-label">State/Province</InputLabel>
                <Select
                  name="state"
                  labelId="state-select-label"
                  value={getFieldValue("state")}
                  onChange={(e) => setFieldValue("state", e.target.value)}
                  label="state"
                >
                  <MenuItem disabled value="">
                    <em>Select State/Province</em>
                  </MenuItem>
                  {State.getStatesOfCountry(getFieldValue("country")).map(
                    (c) => (
                      <MenuItem key={c.isoCode} value={c.name}>
                        {c.name}
                      </MenuItem>
                    ),
                  )}
                </Select>
                <FormHelperText>{getFieldHelperText("state")}</FormHelperText>
              </FormControl>
            )}
            {!isClient && (
              <TextField
                name="city"
                className={classes.formField}
                label="City"
                variant="standard"
                value={getFieldValue("city")}
                onChange={(e) => setFieldValue("city", e.target.value)}
                error={getFieldError("city")}
                helperText={getFieldHelperText("city")}
                required
              />
            )}
            {!isClient && (
              <TextField
                name="zip"
                className={classes.formField}
                label="Pin/Zip Code"
                variant="standard"
                value={getFieldValue("zip")}
                onChange={(e) => setFieldValue("zip", e.target.value)}
                error={getFieldError("zip")}
                helperText={getFieldHelperText("zip")}
                required
              />
            )}
            <TextField
              name="mobile"
              className={classes.formField}
              label="Mobile Phone"
              variant="standard"
              value={getFieldValue("mobile")}
              onChange={(e) => setFieldValue("mobile", e.target.value)}
              error={getFieldError("mobile")}
              helperText={getFieldHelperText("mobile")}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                className={classes.formField}
                margin="normal"
                id="date-picker-dialog"
                label="Date of Birth"
                format="DD/MM/YYYY"
                value={getFieldValue("dateOfBirth") || null}
                minDate={new Date("1940-01-01")}
                maxDate={new Date()}
                onChange={(e) => {
                  setFieldValue("dateOfBirth", e);
                }}
                error={getFieldError("dateOfBirth")}
                helperText={getFieldHelperText("dateOfBirth")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton>
                        <EventIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                required
              />
            </MuiPickersUtilsProvider>
            <TextField
              name="contactEmailAddress"
              className={classes.formField}
              value={
                getFieldValue("contactEmailAddress") || getFieldValue("emailId")
              }
              onChange={(e) =>
                setFieldValue("contactEmailAddress", e.target.value)
              }
              label="Contact E-mail"
              variant="standard"
              error={getFieldError("contactEmailAddress")}
              helperText={getFieldHelperText("contactEmailAddress")}
            />
          </div>
          {/* {isClient && (
            <>
              <div>
                <TextField
                  className={classes.formField}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <FacebookIcon
                          style={{ color: "rgb(17,82,147)", fontSize: 30 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <TextField
                  className={classes.formField}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <TwitterIcon
                          style={{ color: "rgb(27,183,234)", fontSize: 30 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <TextField
                  className={classes.formField}
                  variant="standard"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LinkedInIcon
                          style={{ color: "rgb(20,133,175)", fontSize: 30 }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </>
          )} */}
          {!isClient && (
            <>
              <p className={classes.sectionHeader}>Company Details</p>
              <div className={classes.formSectionDoubleFields}>
                <TextField
                  name="companyName"
                  className={classes.formField}
                  value={getFieldValue("companyName")}
                  onChange={(e) => setFieldValue("companyName", e.target.value)}
                  label="Company Name"
                  variant="standard"
                />
                <TextField
                  name="arnNumber"
                  className={classes.formField}
                  value={getFieldValue("arnNumber")}
                  onChange={(e) => setFieldValue("arnNumber", e.target.value)}
                  label="ARN/SEBI RIA Code"
                  variant="standard"
                />
                <TextField
                  name="gstn"
                  value={getFieldValue("gstn")}
                  onChange={(e) => setFieldValue("gstn", e.target.value)}
                  className={classes.formField}
                  label="GST Number"
                  variant="standard"
                />
              </div>
              <p className={classes.sectionHeader}>Billing Address</p>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="sameAddress"
                  name="controlled-radio-buttons-group"
                  value={radioVal}
                  
                >
                  <FormControlLabel
                    value="sameAddress"
                    control={<Radio onClick={handleRadio} />}
                    label="Same Address as above"
                    style={{ fontWeight: "bold" }}
                    className={classes.formField}

                  />
                </RadioGroup>
              </FormControl>
              <div className={classes.formSectionDoubleFields}>
                <TextField
                  name="billingAddress"
                  className={classes.formField}
                  label="Address"
                  variant="standard"
                  value={getFieldValue("billingAddress")}
                  onChange={(e) =>
                    setFieldValue("billingAddress", e.target.value)
                  }
                  error={getFieldError("billingAddress")}
                  helperText={getFieldHelperText("billingAddress")}
                  required
                />
                <FormControl
                  className={classes.formField}
                  variant="standard"
                  error={getFieldError("billingCountry")}
                  required
                >
                  <InputLabel id="billing-country-select-label">
                    Country
                  </InputLabel>
                  <Select
                    name="billingCountry"
                    labelId="billing-country-select-label"
                    value={getFieldValue("billingCountry")}
                    onChange={(e) =>
                      setFieldValue("billingCountry", e.target.value)
                    }
                  >
                    <MenuItem disabled value="">
                      <em>Select Country</em>
                    </MenuItem>
                    {Country.getAllCountries().map((c) => (
                      <MenuItem
                        name={c.isoCode}
                        value={c.isoCode}
                        key={c.isoCode}
                      >
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {getFieldHelperText("billingCountry")}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  className={classes.formField}
                  variant="standard"
                  error={getFieldError("billingState")}
                  required
                >
                  <InputLabel id="billing-state-select-label">
                    State/Province
                  </InputLabel>
                  <Select
                    name="billingState"
                    labelId="billing-state-select-label"
                    value={getFieldValue("billingState")}
                    onChange={(e) =>
                      setFieldValue("billingState", e.target.value)
                    }
                    label="country"
                  >
                    <MenuItem disabled value="">
                      <em>Select State/Province</em>
                    </MenuItem>
                    {State.getStatesOfCountry(
                      getFieldValue("billingCountry"),
                    ).map((c) => (
                      <MenuItem key={c.isoCode} value={c.name}>
                        {c.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    {getFieldHelperText("billingState")}
                  </FormHelperText>
                </FormControl>
                <TextField
                  name="billingCity"
                  className={classes.formField}
                  label="City"
                  variant="standard"
                  value={getFieldValue("billingCity")}
                  onChange={(e) => setFieldValue("billingCity", e.target.value)}
                  error={getFieldError("billingCity")}
                  helperText={getFieldHelperText("billingCity")}
                  required
                />
                <TextField
                  name="billingZip"
                  className={classes.formField}
                  label="Pin/Zip Code"
                  variant="standard"
                  value={getFieldValue("billingZip")}
                  onChange={(e) => setFieldValue("billingZip", e.target.value)}
                  error={getFieldError("billingZip")}
                  helperText={getFieldHelperText("billingZip")}
                  required
                />
              </div>
            </>
          )}
        </form>
      </MuiThemeProvider>
      <div className={classes.formButtonSection}>
        <Button
          className={classes.formButton}
          onClick={onSubmit}
          variant="contained"
          size="large"
          fullWidth
          disabled={disabledSubmit || (!validParams && !isClient && !isEdit)}
        >
          submit
        </Button>
      </div>
    </>
  );
}

export default withRouter(SignUpForm);
