import React, { Component } from "react";

export default class Donut extends Component {
  loadTimeout = 0;
  rotateAngle = 0;

  constructor(props) {
    super(props);

    this.state = {
      total: this.total(props.segments),
      transforms: this.transforms(),
      segments: this.setSegments(),
      isLoaded: true,
    };
  }

  total = (values) => values.reduce((acc, { value }) => acc + value, 0);

  percent = (value, total) => value / total;

  transforms = () => {
    const rotations = [];
    const textCoords = [];
    const { startAngle, segments } = this.props;
    const total = this.total(segments);

    this.rotateAngle = startAngle;

    this.sortValues(segments).forEach(({ value }) => {
      const data = this.rotateAngle;
      const percent = this.percent(value, total);
      const { x, y } = this.textCoordinates(value, this.rotateAngle);

      rotations.push(data);
      textCoords.push({ x, y });

      const result = rotations[rotations.length - 1] || startAngle;

      this.rotateAngle = percent * 360 + result;
    });

    return { rotations, textCoords };
  };

  sortValues = (values) => values.sort((a, b) => b.value - a.value);

  circumference = (radius) => 2 * Math.PI * radius;

  degreesToRadians = (angle) => angle * (Math.PI / 180);

  strokeDashOffset = (value, circumference) => {
    const diff = this.percent(value, this.state.total) * circumference;
    return circumference - diff;
  };

  textCoordinates = (value, angleOffset) => {
    const { size, radius, segments } = this.props;
    const total = this.total(segments);
    const angle = (this.percent(value, total) * 360) / 2 + angleOffset;
    const radians = this.degreesToRadians(angle);

    return {
      x: radius * Math.cos(radians) + size / 2,
      y: radius * Math.sin(radians) + size / 2,
    };
  };

  setSegments = () => {
    const { segments, size } = this.props;
    const { rotations, textCoords } = this.transforms();
    const total = this.total(segments)
    // const {
    //   total,
    //   transforms: { rotations, textCoords },
    // } = this.state;

   return  this.sortValues(segments).map(({ value, color }, i) => ({
        value,
        color,
        percent: this.percent(value, total),
        rotate: `rotate(${rotations[i]}, ${size / 2}, ${size / 2})`,
        textCoords: textCoords[i],
      }))

    // this.loadTimeout = setTimeout(() => {
    //   this.setState({
    //     isLoaded: true,
    //   });
    // }, 100);
  };

  componentWillUnmount() {
    clearTimeout(this.loadTimeout);
  }

  render() {
    const { size, radius, thickness, className, showPercentage } = this.props;
    const halfSize = size / 2;
    const circumference = this.circumference(radius);
    //  console.log("ttttt",this.state.segments)
    return (
      <>
        <svg
          className={`donut-complex${
            this.state.isLoaded ? " donut-complex--loaded " : " "
          }${className}`}
          height={size}
          width={size}
          viewBox={`0 0 ${size} ${size}`}
        >
          {this.state.segments.map((segment, i) => (
            <g key={i}>
              <circle
                r={radius}
                cx={halfSize}
                cy={halfSize}
                transform={segment.rotate}
                stroke={segment.color}
                strokeWidth={thickness}
                strokeDasharray={circumference}
                strokeDashoffset={this.strokeDashOffset(
                  segment.value,
                  circumference,
                )}
              />
              {showPercentage && (
                <text
                  x={segment.textCoords.x}
                  y={segment.textCoords.y}
                  dy="3px"
                  textAnchor="middle"
                >
                  {segment.value}%
                  {/* {`${Math.round(segment.percent * 100)}%`} */}
                </text>
              )}
            </g>
          ))}
        </svg>
      </>
    );
  }
}
