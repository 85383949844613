import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({

  hexagonWrapper: {
    textAlign: "center",
    width: "75px",
    height: "75px",
    position: "relative",
    display: "inline-block",
  },
  textAlignCenter:{
    textAlign: "center",
  },
  hexagonWrapperTitle:{
    fontSize:"14px !important" ,
    color: theme.palette.primary.main,
  },
  hexagon: {
    width:"100%",
    height:"100%",
    borderRadius: "50%",
    display: "inline-block",
    backgroundColor:"#C3C3C3",
    
  },
  hexagonActive:{
    background:"#4E8DD6",
  },
  hexagonImg:{
    position: "absolute",
    margin: "auto",
    top:0, 
    left: 0,
     bottom: 0,
      right: 0,
      zIndex: "300",
      textAlign: "center"
  },
  hexagonHr:{
    position: "relative",
    top: "calc(50% - 22.5px )",
    margin:"0px 10%",
    backgroundColor: "#C3C3C3",
    height: "5px",
  },
  fakeToolbar: {
    // ...theme.mixins.toolbar,
    minHeight: "100px" 
  },
  link: {
    '&:not(:first-child)': {
      paddingLeft: 15
    }
  },
  supRTM:{
    fontSize: 15,
    lineHeight: 1,
    letterSpacing: -0.25,
    verticalAlign: 'baseline',
    fontWeight:'bold'
  }
}));
