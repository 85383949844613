import React from "react";
import { Box } from "@material-ui/core";
// styles
import useStyles from "./styles";
// components

export default function CurvedSection(props) {
  const classes = useStyles();
  const { name } = props;
  return (
    <>
      <Box pb={4}>
        <div className={classes.curvedSection}>{name}</div>
      </Box>
    </>
  );
}
